import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";
import ScrollToBottom from 'react-scroll-to-bottom';


 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";
 import io from 'socket.io-client';



import {SiteLogo ,SiteLogoDark,Url,userProfileurl ,postDetailurl,Akusername,UrlSplitter,AkuserId,SetNotificationAfterName,Notifyalert } from '../Frontapi.js';

   import { angleLeft} from '../ImagesIcons.js';


 

 import {Socketcall,RecieveSocketcall,AllowSocketcall,socketUrl} from '../../socketcall/Socketcall.js';


 
const Messageuser = () => {
   const [BottomNavbarstatus,setBottomNavbarstatus] = useState(0)

   const [updateSidecount,setupdateSidecount] = useState(0)


 const username = UrlSplitter(2);

const [Userprofilepicture,setUserprofilepicture] = useState(SiteLogoDark );

const [message,setmessage]=useState()
const [messagedata,setmessagedata]=useState([])

const [Token,setToken]=useState('')

const socket = io.connect(socketUrl);


const [usernotblocked,setusernotblocked]=useState(0)


const [showmessagesection,setshowmessagesection]=useState(0)


const [sendrequestsection,setsendrequestsection]=useState(0)
const [sendacceptrequestsection,setsendacceptrequestsection]=useState(0)
const [sendrequestpendingsection,setsendrequestpendingsection]=useState(0)

const [requestmessage,setrequestmessage]=useState()




const Getuserdetails =async(e) =>{

   var postdata = {
        user_name: username , checkuserid:AkuserId
    };

     const response = await fetch(Url + "get-show-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userinfo = data.data;
 
      
setUserprofilepicture(userinfo.profilepicture)

 
 

  

                }  
            })




}


const SetMessageRequestSubmit =  async (e) => {
e.preventDefault()

  if(e.keyCode === 13){
             sendMessageRequest(e);
         }



    }


    const SetMessageSubmit =  async (e) => {
 e.preventDefault()

  if(e.keyCode === 13 && message != ''){
             sendMessage(e);
         }

    }




  const sendMessage =  async (e) => {
    e.preventDefault()

    updateMessageview();
  
  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
             /***Check Required***/

               if(error == 1){
              return false
            }




  var postdata={username:username,'userid':AkuserId, message:message, token:Token , tousertoken:username};

setmessage('')
     var socketdata= {  token:username, from:Akusername  , messagetoken :Token }
 
     Socketcall('emit','sendmessage', socketdata)

 
   


     


        const response = await fetch(Url+"send-message", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


            setmessage('')
 
                getMessages();

 

              } else{
                 
          }

           
       })



  }



const updateMessageview=  async () => {
  var postdata={username:username,'userid':AkuserId };


        const response = await fetch(Url+"update-message-view", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                 
                setupdateSidecount(1)

              } else{
                 
          }

           
       })


}





  const getMessages =  async () => {
  var postdata={username:username,'userid':AkuserId };


        const response = await fetch(Url+"get-messages", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

      
 
 (async function() { 
       setmessagedata(data.data)
         setToken(data.token)

var callSocket = await AllowSocketcall();


if(callSocket == 1){ 
    socket.emit("join",{token:data.token});
}

 })()
 

  if(document.querySelector('.user-prof-messages')){
      
var element = document.querySelector('.user-prof-messages');
element.scrollTop = element.scrollHeight;
  }


              } else{
                 
          }

           
       })


}




  const checkUserblocked =  async (value='') => {

  var postdata={username:username,'userid':AkuserId };

     const response = await fetch(Url+"check-blockby", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 

            if(data.success){

            }else{
                setusernotblocked(1)
            }


               })


  }


  const checkMessagerequestaccepted=  async (value='') => {

      var postdata={username:username,'userid':AkuserId };

        const response = await fetch(Url+"check-message-request", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 

            if(data.success){


                if(data.type == 'sr'){
                    setsendrequestsection(1)
                }

                if(data.type == 'sra'){
                    setsendacceptrequestsection(1)
                }

                  if(data.type == 'srp'){
                    setsendrequestpendingsection(1)
                }


                if(data.type == 'sm'){
                    setshowmessagesection(1)
                }


            }else{
                
            }


               })



  }


const  sendMessageRequest =  async () => {



  /***Check Required***/
            var required_fields= document.getElementsByClassName('RequestMustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
             /***Check Required***/

               if(error == 1){
              return false
            }




      var postdata={username:username,'userid':AkuserId , requestmessage: requestmessage };
 (async function() { 

var callSocket = await AllowSocketcall();


if(callSocket == 1){ 
    socket.emit("newmessage",{token:username, from:Akusername})

}

 })()

         const response = await fetch(Url+"send-message-request", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 

            if(data.success){


                setrequestmessage('')
                    setsendrequestsection(0)
                    setsendrequestpendingsection(1)
               
            }else{
                
            }


               })



  }


const  acceptMessageRequest =  async () => {


      var postdata={username:username,'userid':AkuserId };

         const response = await fetch(Url+"accept-message-request", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 

            if(data.success){



              setsendacceptrequestsection(0)
                     setshowmessagesection(1)
               
            }else{
                
            }


               })



  }

 
 const getFirstFromMessage=  (message) => {

var messagearr= message.split(" ");

var urlset=0;

var returnmessage=[];
for(let i=0;i<messagearr.length;i++){


    if(messagearr[i].includes('http') ){
      urlset=1; 

     }
    if(urlset == 0){
    returnmessage.push(messagearr[i]);

}

}

return returnmessage.join(' ')


 }
  const getLastFromMessage=  (message) => {

var messagearr= message.split(" ");

var urlset=0;

var returnmessage=[];
for(let i=0;i<messagearr.length;i++){
 if(urlset == 1){
    returnmessage.push(messagearr[i]);

}

    if(messagearr[i].includes('http') ){
      urlset=1; 
    }
   

}

return returnmessage.join(' ')

 }


const  getUrlFromMessage =    (message) => {

var messagearr= message.split(" ");

for(let i=0;i<messagearr.length;i++){

    if(messagearr[i].includes('http') ){
    return   (
         <Link to={messagearr[i]}>{messagearr[i]}</Link>  
      )      
    }

}


}


 


  useEffect(() => {
    Getuserdetails();
    checkUserblocked();
    checkMessagerequestaccepted();

    getMessages();
    
    updateMessageview();

 

 (async function() { 

var callSocket = await RecieveSocketcall();


if(callSocket == 1){ 
    socket.on("receivemessage", (data) => {
      getMessages();
      
         setupdateSidecount(1)

    });


}

 })()


  }, [Token]);


  return (

      <div>
       
   <Header pageInfo={"Message - "+username}  />

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} updateSidecount={updateSidecount} />
           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section"> 


              <div className="page-content">

                <div className="profile-container"> 

                 <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi show-nav"    > <span className="goto-section" > <Link  to={-1}>  <img src={angleLeft} /> </Link> </span>   
                      <img src={Userprofilepicture} className="message-head-pic" /> {username}  </h1>
                </div>

 

                               {/**messagesection**/}

 
               <div>
                


               <div className="user-prof-messages"> 

 
                {messagedata.map((messagesingle) => (


                <p className={Akusername == messagesingle.username ? 'message-p-r' : 'message-p'   } >


                <span className="mesage-content"> 
                {messagesingle.message.split(' ').map((singlemessage) => (
                <span>
                    <span>   
                    { ( singlemessage.includes('http'))    ?  <Link to={singlemessage}>{singlemessage}</Link>      :     singlemessage   } 
                    </span>
                    <span>  </span>
                    </span>

                 ))}


                </span>


                


   <br/> <span className="mesage-s-timestamp"> {messagesingle.timediff} </span></p> 


                  ))}



 
               

                              

               </div>



               </div>





             


             <div className=" r">

{(usernotblocked == 1) ? 


<div> 
<div> 
       { (sendrequestsection) == 1 ? <div>



 

   <div className="s-message-layout">
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12     add-message-section" >
                     
                     <input  value={requestmessage} onChange={(e) => setrequestmessage(e.target.value)} onKeyUp={(e) => SetMessageRequestSubmit(e)}   name="message" className="add-message-input RequestMustEnterField" placeholder="Message" />

       <span className="add-message-submit" onClick={sendMessageRequest}   >Send request</span>
                   
                      </div>
                           </div>
 






       </div> : '' }

</div>



<div> 

        { (sendrequestpendingsection) == 1 ? <div> <span className="message-request"    > Request Pending</span> </div> : '' }
</div>
<div> 
         { (sendacceptrequestsection) == 1 ? <div> <span className="message-request" onClick={acceptMessageRequest}   > Accept Request</span>  </div> : '' }

</div>




 
            <div> 

       { (showmessagesection == 1) ? 






 
 

   <div className="s-message-layout">
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12     add-message-section" >
                     
                     <input value={message} onChange={(e) => setmessage(e.target.value)}    onKeyUp={(e) => SetMessageSubmit(e)}  name="message" className="add-message-input MustEnterField" placeholder="Message" />

                     <span className="add-message-submit" onClick={sendMessage}   >Send</span>
                   
                      </div>
                           </div>
 




                        : ''}

</div>
</div>

 : ''}



          
      </div> 

                {/**messagesection**/}




               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Messageuser;