import React, { useState , useEffect} from "react";
 import {
  Link 
} from "react-router-dom";
import {CopyToClipboard} from 'react-copy-to-clipboard';


import {
 EmailShareButton,
 FacebookShareButton,
  
 LinkedinShareButton,
  
 
  
 WhatsappShareButton
  
} from "react-share";
 

 
import { SetNotificationAfterName,GetThumbnailImage,postDetailurl,userProfileurl,votePosturl,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName } from '../Frontapi.js';

 import {addReelIcon,userIcon ,challengeIcon,angleLeft ,challengewinnerIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

import Challengesubmissions from "./Challengesubmissions"
  import Singlepost from "../pages/Singlepost"


 
import { DynamicImagesUrl } from '../GetDynamicImages.js';
 

 

 const Challenges = ( ) => {


const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
/**Main page*/


const [challengesList, setchallengesList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);
const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')


const [upcomingchallengesList, setupcomingchallengesList] = useState([]);
const [winnerschallengesList, setwinnerschallengesList] = useState([]);



const [activeLivechallenges, setactiveLivechallenges] = useState(1);
const [activeUpcomingchallenges, setactiveUpcomingchallenges] = useState(0);
const [activeShowwinners, setactiveShowwinners] = useState(0);


/**Main page*/


const [showChallengemainsection,setshowChallengemainsection]= useState(1);
const [showChallengeupcomingsection,setshowChallengeupcomingsection]= useState(0);
const [showChallengewinnerssection,setshowChallengewinnerssection]= useState(0);

const [showSubmitpostsection,setshowSubmitpostsection]= useState(0);

const [showSubmitvotesection,setshowSubmitvotesection]= useState(0);



const [showAddreelbutton, setshowAddreelbutton]= useState(0);
/**Submission List page*/

/*
const GetChallengesubmissions=async()=>{


 
  // let formData = new FormData()
  // formData.append('currentpage', currentPage)
  //  formData.append('perpage', recordsPerPage)

 
   var postdata={'currentpage':currentPage,'perpage':recordsPerPage, 'current':1, 'active' : 1,challengeid:challengeid};

    const response = await fetch(Url+"getchallengesubmissions", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    setchallengeSubmissions(data.data );
   
     
     } else{
         
     }

      
    })


 




}
 */

/**Submission List page*/

const HideAllChallengeSections=async()=>{

setshowSubmitpostsection(0)
setshowChallengemainsection(0)
setshowSubmitvotesection(0)
 setshowChallengewinnerssection(0)
setShowpostaskvotepopup(0)
}


const GetCurrentChallenges=async()=>{


 
  // let formData = new FormData()
  // formData.append('currentpage', currentPage)
  //  formData.append('perpage', recordsPerPage)
 
 
   var postdata={ 'url' : btoa(DynamicImagesUrl+'posts/') , 'currentpage':currentPage,'perpage':recordsPerPage, 'current':1, 'active' : 1 , getchallengesubmissions : 1};

    const response = await fetch(Url+"getchallenges", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
      if(data.data.length == 0){
  setcheckrecordsLength('No Challenges found')

  setchallengesList([]);
  return false
}

    setchallengesList(data.data );

    if(data.data[0].winner == 0){
      setshowAddreelbutton(1)
    }

    
     
     } else{
         
     }

      
    })


 




}
 

const GetUpcomingChallenges=async()=>{
      setshowAddreelbutton(0)

 var postdata={  'active' : 1 };

    const response = await fetch(Url+"getupcomingchallenges", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
    setupcomingchallengesList(data.data );
    
          if(data.data.length == 0){

      setcheckrecordsLength('No Challenges found')
  setupcomingchallengesList([]);

    }
     
     } else{
         
     }

      
    })

}


const GetWinners=async()=>{

      setshowAddreelbutton(0)

 var postdata={  'active' : 1 };

    const response = await fetch(Url+"getWinners", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
        var postdatavalues=data.data

         

          setwinnerschallengesList(postdatavalues)


              if(data.data.length == 0){

      setcheckrecordsLength('No winners yet')
  setwinnerschallengesList([]);

    }



     
     } else{
         
     }

      
    })


}
const showChallengemain =async()=>{
GetCurrentChallenges()

HideAllChallengeSections()
 setshowChallengemainsection(1)
  setactiveLivechallenges(1)
setactiveUpcomingchallenges(0)
setactiveShowwinners(0)

}

const showChallengeupcoming =async()=>{
GetUpcomingChallenges()
HideAllChallengeSections()
 setshowChallengemainsection(1)

 setactiveLivechallenges(0)
setactiveShowwinners(0)
setactiveUpcomingchallenges(1)


}
const showChallengewinners =async()=>{
GetWinners()
 setactiveLivechallenges(0)
setactiveUpcomingchallenges(0)
setactiveShowwinners(1)
 
}


const showSubmitYourPost = async(challengeid,challengetitle)=>{

setChallengeid(challengeid)
setPrepopulatedPosttitle(challengetitle)
setThumbnail({preview:''})

HideAllChallengeSections()

setshowSubmitpostsection(1)


}



/*********Vote*********/

const [votePostid,setVotepostid]=useState('')

const [showpostvotesubmitoption,setShowpostvotesubmitoption]=useState(0)
const [showpostaskvoteoption,setShowpostaskvoteoption]=useState(0)
const [shareurl,setShareurl]=useState('')

const [askVotesharemessage,setAskVotesharemessage]=useState('')



const showSubmitvote = async(challengeid,postid)=>{

setVotepostid(postid)
setChallengeid(challengeid)
await ShowVoteoptionnotexists(challengeid,postid) 
 
HideAllChallengeSections(0)

setshowSubmitvotesection(1)
setShareurl(votePosturl+postid+'/'+challengeid)

setAskVotesharemessage('Hi '+ votePosturl+postid+'/'+challengeid)

}



const ShowVoteoptionnotexists =async(challengeid,postid) =>{

setShowpostvotesubmitoption(0)
setShowpostaskvoteoption(0)

   var postdata={'userid':AkuserId,'postid':postid,'challengeid':challengeid };


 const response = await fetch(Url+"check-post-vote", {
    method: "POST",
    body: JSON.stringify({postdata }),
      headers: {
     "Content-Type": "application/json",
    } ,
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 


       if (data.showsubmitvote) { 
        setShowpostvotesubmitoption(1)

       }

       if (data.showaskvote) { 
        setShowpostaskvoteoption(1)

       }
 
       
     
       //navigate("/my-profile")

      //  const get= document.getElementById('my-profile'); 
      // get.click(); 

        

     } 

      
    })

  // 


}


const postYourvote =async() =>{

       setShowpostvotesubmitoption(0)

   var postdata={'userid':AkuserId,'postid':votePostid,'challengeid':challengeid };

   

     const response = await fetch(Url+"post-submit-vote", {
    method: "POST",
    body: JSON.stringify({postdata }),
      headers: {
     "Content-Type": "application/json",
    } ,
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 
 
       Notifyalert('success', data.message)

       
      
     
       //navigate("/my-profile")

      //  const get= document.getElementById('my-profile'); 
      // get.click(); 

        

     } else{
        
     }

      
    })

}
const [showpostaskvotepopup,setShowpostaskvotepopup]=useState(0)


const askForvote =async() =>{
HideAllChallengeSections()

  setShowpostaskvotepopup(1)


  setTimeout(function(){
 

//document.getElementById('askvotelistmodal').style.display='block'

},200)


}

const backtoSubmitvote=async()=>{
HideAllChallengeSections()
 setshowSubmitvotesection(1)
  

}




const stoppropagation=async(e)=>{
    e.stopPropagation();

}





const [focuselement,setfocuselement]=useState(0)
  const [searchList,setsearchList] = useState([])

 const [ShowSearchLoader,setShowSearchLoader] = useState(0)

const [showsendsharemessagesection,setShowsendsharemessagesection] = useState(0)

 const [receiverUsername,setreceiverUsername] = useState('')

 

const focusNext= async (e) => {

 
if(e.keyCode == 40){


     setfocuselement(parseInt(1)+parseInt(focuselement))

  if(document.getElementsByClassName('search-single-link')[parseInt(1)+parseInt(focuselement)]){
 document.getElementsByClassName('search-single-link')[parseInt(1)+parseInt(focuselement)].focus();

 
       } 

       return false
 }

 if(e.keyCode == 38){

 
        setfocuselement(parseInt(focuselement)-parseInt(1))
 
   if(document.getElementsByClassName('search-single-link')[parseInt(focuselement)-parseInt(1)]){
 document.getElementsByClassName('search-single-link')[parseInt(focuselement)-parseInt(1)].focus();

       } 
       return false
}


}

const selectUsertomessage= async (username) => {

setsearchList([])
setShowsendsharemessagesection(1)
setreceiverUsername(username)
}



const sendMessage=async()=>{

setShowsendsharemessagesection(0)



   var postdata={username:receiverUsername,'userid':AkuserId , message: askVotesharemessage };

    const response = await fetch(Url+"ask-vote-request-message", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 

      if(data.success){


         Notifyalert('success', data.message)
        
      }else{
        
      }


        })



  }



const GotoSearch= async (e) => {
setShowsendsharemessagesection(0)
 setfocuselement(0)
if(e.keyCode == 40){


 
  if(document.getElementsByClassName('search-single-link')[focuselement]){
 document.getElementsByClassName('search-single-link')[focuselement].focus();
       } 

       
       return false

}

setfocuselement(0)
setsearchList([])
 
  var value = e.target.value;

if(value != ''){
   setShowSearchLoader(1)

}

 
 if(value != ''){
  var postdata={searchterm:value , userid : AkuserId, onlyuser:1 };

    const response = await fetch(Url+"search", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 

       var searchresultdata=data.data;
 setShowSearchLoader(0)
 
 
        setsearchList(searchresultdata);

 
 
 
 //});



       } else{
         
     }

      
    })

}

};


const [showConfirmBroadcast, setshowConfirmBroadcast] = useState(0)

const [showBroadcastProcessing, setshowBroadcastProcessing] = useState(0)

const [showBroadcastDone, setshowBroadcastDone] = useState(0)

const HideBroadcastProcessDone = ()=>{

    setshowBroadcastDone(0)
setshowBroadcastProcessing(0)

}

const OpenshowConfirmBroadcast =async() =>{
HideBroadcastProcessDone()
setshowConfirmBroadcast(1)

}

const CloseshowConfirmBroadcast =async() =>{
HideBroadcastProcessDone()

setshowConfirmBroadcast(0)

}

const ProceedBroadcast =async() =>{
 setshowConfirmBroadcast(0)


setshowBroadcastProcessing(1)


   var postdata={'userid':AkuserId , message: askVotesharemessage };

    const response = await fetch(Url+"broadcast-vote-followers", {
    method: "POST",
     headers: {
     "Content-Type": "application/json",
    } ,
    body: JSON.stringify({postdata }),
     
   }).then((response) => response.json())
     .then((data) => { 

      if(data.success){

setshowBroadcastProcessing(0)

setshowBroadcastDone(1)


         
      }else{
        
      }


        })



}
 




/*********Vote*********/

/****Post****/

const [challengeid, setChallengeid] = useState("");

const [topic, setTopic] = useState("");
const [caption, setCaption] = useState("");
const [youtubeurllink, setYoutubeurllink] = useState("");
const [thumbnail, setThumbnail] = useState({ preview: '', data: '' })
 
 const [PrepopulatedPosttitle,setPrepopulatedPosttitle]=useState('')
const [submitButton, showSubmitbutton] = useState(1);


const [challengefees, setchallengefees] = useState("");



const TriggerClickSubmitPost =async(e) =>{
   document.getElementsByClassName('challenge-submit-post')[0].click()
}

const Submitchallengepost =async(e) =>{

 e.preventDefault();

    

 /***Check Required***/
      var required_fields= document.getElementsByClassName('MustEnterField')
       if(document.getElementsByClassName('notify-input-text')[0]){
        document.getElementsByClassName('notify-input-text')[0].remove();
       } 
      var error=0;
      Array.prototype.forEach.call(required_fields, function(el) {
            el.classList.remove('notify-input-missing');
             el.classList.add('mb-10');
            if(el.value === '' && error == 0){
            var inputname = el.name.charAt(0).toUpperCase() + el.name.slice(1);
            el.classList.add('notify-input-missing');
             el.classList.remove('mb-10');
            error=1;

            

            //SetNotificationAfterName(el.name, inputname + ' required','noneed')
       }
       });
       if(error === 1){
          return false
      }
       /***Check Required***/

         

     if(thumbnail.data == ''){

         // document.getElementsByClassName('button-upload')[0].classList.add('notify-input-missing');

        // return false
       
     }


showSubmitbutton(0)

  let formData = new FormData()
   formData.append('thumbnail', thumbnail.data)
   formData.append('userid', AkuserId)
   formData.append('topic', PrepopulatedPosttitle)
   formData.append('caption', caption)
   formData.append('youtubeurllink', youtubeurllink)
   formData.append('challenge', 1)
   formData.append('challengeid', challengeid)


     const response = await fetch(Url+"submit-challenge-post", {
    method: "POST",
    body:formData 
     
   }).then((response) => response.json())
     .then((data) => { 
     if (data.success) { 

  window.location.href=data.redirect 

  /*     Notifyalert('success', data.message)

       
       document.getElementById("NewPostForm").reset()
       setThumbnail({preview:''}) */



       //navigate("/my-profile")

      //  const get= document.getElementById('my-profile'); 
      // get.click(); 

        

     } else{
      showSubmitbutton(1)

        Notifyalert('error', data.message)
     }

      
    })
 
 

}

 const checkvalidYoutubeUrl=(e)=>{

   RemoveNotificationAfterName();

   if( youtubeurllink != '' &&  youtubeurllink.includes('https://youtu.be') === false  ){
    showSubmitbutton(0)
    SetNotificationAfterName('youtubeurllink',   'Invalid Url' )
    } 


   if( youtubeurllink != '' &&     youtubeurllink.includes('https://www.youtube.com/shorts/') === true  ){
       showSubmitbutton(0)
      SetNotificationAfterName('youtubeurllink',   'Video should be save under channel content in youtube' )
     }


 }

  const handleUserInput = (e) => {
 

                     showSubmitbutton(1)
 
checkvalidYoutubeUrl()
 
  var name = e.target.name;
  var value = e.target.value;
  switch (name) {
    case 'topic':
      setPrepopulatedPosttitle(value);

       break;
    case 'youtubeurllink':
      setYoutubeurllink(value);
      setAutothumbnail(value)
       
    checkvalidYoutubeUrl()

       break;
    case 'caption':
      setCaption(value);
       break; 

   

     
   }
  }



 const handleThumbnailChange = (e) => {
   RemoveNotificationAfterName();

   const img = {
   preview: URL.createObjectURL(e.target.files[0]),
   data: e.target.files[0],
  }
   setThumbnail(img)
 }
 
 const setAutothumbnail = (value) => {



     (async function() { 

   var imgresult= await GetThumbnailImage(value)



 const img = {
   preview:imgresult  ,
   data: '',
  }
   setThumbnail(img)


  })()
 
 

 }



 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
           
    setchallengefees(settingsdata.challengefees );


            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {
      
  }

 } 


 /****Post****/

 useEffect(() => {
  Getsettings()
GetCurrentChallenges()
 },[]);
 


return (<div>
  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
   <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
     <Sidebar showBottomNavbar={BottomNavbarstatus} />
   </div>
   <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
     <div className="page-content">
      <div className="container-mobile">
        {(showChallengemainsection == 1) ? 
        <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi"  > 
            <span className="goto-section mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>  </span> Challenges


            {(showAddreelbutton == 1) ? 
            <span className="challenge-title" style={{float:'right'}}>  <i className="amigofa-icon-add-reel fo-30-px" onClick={TriggerClickSubmitPost}></i>   </span> : ''}



           </h1>
         </div>
         <div className="challenge-info-tabs" >
           <ul>
            <li onClick={showChallengemain} > <a className={activeLivechallenges == 1 ? 'active':''}> Leaderboard</a> </li>
            <li > <a className="pipeline"> &nbsp; </a> </li>
            <li onClick={showChallengeupcoming} > <a className={activeUpcomingchallenges == 1 ? 'active':''}> Upcoming </a></li>
            <li > <a className="pipeline"> &nbsp; </a> </li>
            <li onClick={showChallengewinners} > <a className={activeShowwinners == 1 ? 'active':''}> Winners </a></li>
           </ul>
         </div>

{(activeUpcomingchallenges == 1)  ? <div>

<div className="challenge-wrapper">


 {(upcomingchallengesList.length) > 0 ?

 <div>
           {upcomingchallengesList.map((upcomingchallenge) => (
           <div className="upcomingchallenge-single">
            <div className=" col-12 col-md-12 col-sm-12 col-lg-12 upcomingchallenge-single-title row" >
              <div className=" col-12  col-md-12 col-sm-12 col-lg-12">   <span>{upcomingchallenge.title}</span> -  <span>Prize Rs {upcomingchallenge.prize}/-</span>  </div>
              <div className=" col-12  col-md-12 col-sm-12 col-lg-12 upcomingchallenge-single-timestamp"> Starts at {upcomingchallenge.startdatetime}              </div>
              <div className=" col-12  col-md-12 col-sm-12 col-lg-12 upcomingchallenge-single-timestamp"> Ends on {upcomingchallenge.enddatetime}              </div>

            </div>
            
           </div>
           ))}
              </div>
         :
<div className="no-records"> {checkrecordsLength} </div>
       }



         </div>
 




</div>: ''}

{(activeShowwinners == 1  )  ? <div> 


{(winnerschallengesList.length > 0) ? 
<div>

           {winnerschallengesList.map((winnerchallenge) => (

           <div>
           <div className=" col-12 col-md-12 col-sm-12 col-lg-12 row" >
            <div className=" col-6 col-md-6 col-sm-6 col-lg-6 winner-post-image" >

             <img src={winnerchallenge.thumbnail} />
           </div>

            <div className=" col-6 col-md-6 col-sm-6 col-lg-6" >

            <div className="winner-post-info"> 
            <span className="w-info-main"><Link className="text-white" to={userProfileurl+winnerchallenge.username}> {winnerchallenge.username}</Link> </span>

          <span className="w-info-sub"> {winnerchallenge.title}</span>

                 <span className="w-info-mini">   Vote :   {winnerchallenge.votes}</span>
                 </div>

            </div>

           </div>
          

           

                    


           </div>
  ))}
</div> :     <div className="no-records"> {checkrecordsLength} </div>
}


 </div>:''}


{(activeLivechallenges == 1)  ?
<div>

         {(challengesList.length) > 0 ?
         <div className="challenge-wrapper">
           {challengesList.map((challenge) => (

           <div>

           {(challenge.winner != 0  ) ? 

           <div className=" col-12 col-md-12 col-sm-12 col-lg-12">
           <p className="text-center winner-label">{challenge.winner}</p>
           <div>  <img src={challengewinnerIcon} />  </div>

           <p className="winner-total-votes">{'Total Votes : '+challenge.winnerpostvotes}</p>

           { (challenge.winner == Akusername) ?
           <p className="text-white ft-22">Our team will connect you</p> :''}


            </div>
           : '' }

            {/*(challenge.winner != 0 && challenge.winner != Akusername) ?  <p className="text-white text-center ft-22">Winner has been Announced for the {challenge.title} </p>: ''*/}

           {(challenge.winner == 0) ? 
             <div className="challenge-single">
            <div className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-single-title" >
              <span className=" col-12 col-md-12 col-sm-12 col-lg-12">   <span>{challenge.title}</span> -  <span>Prize Rs {challenge.prize}/-</span>  
             
              <a className="challenge-submit-post" onClick={ (value)=> showSubmitYourPost(challenge._id,challenge.title)} >   </a>
              </span>
               <p className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-title-date-info">    Ends on {challenge.enddatetime}  </p>

                <p className=" col-12 col-md-12 col-sm-12 col-lg-12 challenge-note">       On participation of these challenge you will be charged Rs. {challengefees}/-   </p>

            </div>
            {challenge.submissions.map((challengepost) => (
            <div onClick={(challengeid,postid) =>
              showSubmitvote(challenge._id,challengepost._id)} className=" challenge-submission-single col-md-12 col-lg-12 col-sm-12 col-xs-12 row" >
              <div className="col-md-5 col-lg-5 col-sm-5 col-xs-5">
               <div  className="challenge-submission-single-img">
                 <img src={challengepost.thumbnail} />
               </div>
              </div>
              <div className="col-md-7 col-lg-7 col-sm-7 col-xs-7">
               <div className="challenge-submission-single-stats">
               { (challengepost.rank > 0) ?                  <p className="challenge-rank-id">Rank {challengepost.rank} </p> : '' }
                 <p className="challenge-votes-count">
                 { (challengepost.votes > 0) ? 
                        <span>{challengepost.votes} votes</span> : '0 vote' }


                  </p>
               </div>
              </div>
            </div>
            ))}
           </div>


           : ''}

           </div> 





           ))}

         



         </div>
         : 
         <div className="no-records"> {checkrecordsLength} </div>
         }
        </div>
        : '' }

          </div> : ''
           }

        {(showSubmitpostsection == 1) ? 
        <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi" onClick={showChallengemain} > <span className="goto-section" ><img src={angleLeft} /></span> Submit Your Post</h1>
         </div>
         <form id="NewPostForm" >
           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 
            <input onChange={(e) => handleUserInput(e)} type="text" name="youtubeurllink" className="new-post-input MustEnterField mb-10" placeholder="Paste YouTube URL link of your content" />
           </div>
           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec post-input-file-sec">
            <div className="button-wrap">
              <label className="button-upload" for="upload">Attach Thumbnail</label>
              <input id="upload" type="file"  accept="image/png, image/gif, image/jpeg" name="thumbnail"  onChange={handleThumbnailChange} />
               
              {(thumbnail.preview != '') ? <img alt="thumbnail" className="thumb-img-prev img-responsive w-250px" src={thumbnail.preview} />:''}
            </div>
           </div>
           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 
            <input onChange={(e) => handleUserInput(e)} type="text" name="topic" className="new-post-input MustEnterField mb-10" placeholder="Topic" value={PrepopulatedPosttitle} />
           </div>
           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 
            <textarea onChange={(e) => handleUserInput(e)} name="caption" placeholder="Write Caption" rows="10" className="new-post-textarea MustEnterField mb-10"></textarea>
           </div>

            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  ad-condition   post-input-sec"> 
             <p className="ch-txt-note">* On participation of these challenge you will be charged Rs. {challengefees}/- </p>
            </div>

           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 

  {(submitButton == 1) ? 

            <button type="submit" className=" me-1 mb-1 new-post-main-btn" onClick={  Submitchallengepost}  >Confirm</button>
         :  <button type="submit" className=" me-1 mb-1 new-post-main-btn disabled-btn"   >Confirm</button>
        } </div> 


         </form>
        </div>
        : ''}
        {(showSubmitvotesection == 1) ? 
        <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi" onClick={showChallengemain} > <span className="goto-section" ><img src={angleLeft} /></span> Challenge</h1>
         </div>
         <Singlepost postid={votePostid} />
         {(  showpostvotesubmitoption == 1) ? 
         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 post-vote">
           <a  className=" me-1 mb-1 new-post-main-btn" onClick={  postYourvote}  >I vote</a>
           <a  className=" me-1 mb-1 new-post-main-btn" onClick={  showChallengemain}  >Skip</a>
         </div>
         : ''}
         {(  showpostaskvoteoption == 1) ? 
         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 post-vote">
           <a  className=" me-1 mb-1 new-post-main-btn" onClick={askForvote}  >Ask for vote</a>
           <p>How I can get to the top of the 
            Leaderboard?
           </p>
           <p>Ask your friends to vote for your video. 
            You can either share your video with your 
            friends by chat or share links through 
            other social media. 
           </p>
         </div>
         : ''}
        </div>
        : ''}
        {(  showpostaskvotepopup == 1) ? 
        <div>
         <div className="page-container-section" >
           <h1 className="page-container-title cur-poi" onClick={backtoSubmitvote} >  <span className="goto-section" ><img src={angleLeft} /></span> Ask for vote</h1>
         </div>
         <div className="search-sec" >
           <input type="text" className="search-challenge-user-list" onKeyUp={(e)=>GotoSearch(e)}  placeholder="Search"/>
           { (ShowSearchLoader == 1) ? <i className="fa fa-spinner search-spinner fa-spin"> </i> : '' }
         </div>
         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row search-wrapper ">
           {/*single search**/}
           {searchList.map((post) => (
            <div>
           { (post.result != Akusername)? <Link  onClick={(username)=>
           selectUsertomessage(post.result)} className="search-single-link" onKeyUp={(e)=>focusNext(e)} >
           <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 search-single padding-0 row  ">
            <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4" >
              <div className="search-single-section-img">
               <img src={post.image} />
              </div>
            </div>
            <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8 col-8" >
              <div className="search-single-section-title">
               <p> {post.result} </p>
              </div>
            </div>
           </div>
           </Link> : ""}
           </div>
           ))}
         </div>


         {(showsendsharemessagesection == 1)? 
         <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row ask-vote-message-wrapper ">
           <h2 > Send message to {receiverUsername} </h2>
           <textarea rows="5" onChange={(e)=> setAskVotesharemessage(e.target.value) } >{askVotesharemessage} 

          </textarea>
           <a  className="send_message" onClick={sendMessage} >Send message</a>
         </div>
         : '' }




         <div className="broadcast-followings-vote-section"> 

                  <p className="br-confirm-info challenge-copy-link"> <span>Link : {shareurl} </span>  <CopyToClipboard text={shareurl} >
            

            <i className="fa fa-copy" title="Click to Copy"> </i>
            </CopyToClipboard> </p>




            <a onClick={OpenshowConfirmBroadcast} className="broadcast-vote-button">Broadcast to my followers </a>


            {(showConfirmBroadcast == 1) ? 

            <div> 
            <p className="br-confirm-info"> Are you sure you want to broadcast link to your followers? </p>

            <div className="col-md-12 col-lg-12 col-12 row">

               <div className="col-md-6 col-lg-6 col-6">

                    <a className="br-confirm-proceed" onClick={ProceedBroadcast}> Proceed</a>
               </div>

               <div className="col-md-6 col-lg-6 col-6">

                      <a className="br-confirm-cancel" onClick={CloseshowConfirmBroadcast} > Cancel</a>  
               </div>


             </div>

            </div>

            : ''


          } 

           {(showBroadcastProcessing == 1) ? <p className="br-confirm-info" >Processing...Please wait...</p> : '' } 

           {(showBroadcastDone == 1) ? <p className="br-confirm-info" >Broadcasted Successfully</p> : '' } 

           
         </div>
         




         <div className="share-modal-options text-center ask-vote-social-section">
           <div className="social-share-single">
            <EmailShareButton url={shareurl}   ><span className="social-share-btn btn-mail" > <i className="fa fa-envelope"> </i>  </span> </EmailShareButton >
           </div>
           <div className="social-share-single">
            <WhatsappShareButton url={shareurl}  > <span className="social-share-btn btn-wt"  > <i className="fa fa-whatsapp" > </i>  </span> </WhatsappShareButton >
           </div>
           <div className="social-share-single">
            <FacebookShareButton url={shareurl}  > <span className="social-share-btn btn-fb" > <i className="fa fa-facebook"> </i>  </span> </FacebookShareButton >
           </div>
           <div className="social-share-single">
            <LinkedinShareButton url={shareurl}  > <span className="social-share-btn btn-li" > <i className="fa fa-linkedin"> </i>  </span> </LinkedinShareButton >
           </div>
         </div>


         

        </div>
        : ''}
      </div>
     </div>
   </div>
  </div>
</div>

	)













 }
export default Challenges;