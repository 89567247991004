import React, {useEffect, useState } from "react";
 import {        useNavigate} from "react-router-dom";

 import {Url,baseUrl, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,checkpermissionredirect} from './../../Api.js';

import {    
    Allowschools_view ,
   Allowschools_add ,
   Allowschools_edit ,
   Allowschools_delete
} from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 

 

const General = () => {

 
   const navigate = useNavigate();
 



const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

const[id] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 


const[address1, setAddress1] = useState("");
const[address2, setAddress2] = useState(""); 
const[city, setCity] = useState(""); 
const[country, setCountry] = useState(""); 
const[state, setState] = useState(""); 
const[email, setEmail] = useState(""); 
const[phone, setPhone] = useState(""); 
const[website, setWebsite] = useState(""); 
const[pincode, setPincode] = useState(""); 


 const[status, setStatus] = useState(1); 

               const [submitLoading, setsubmitLoading] = useState(false);

 
 const Getschool = async (e) => {
  
  try{
     var postdata={_id:id};

     const response = await fetch(Url+"getschool", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var schooldata=data.data;
               setName(schooldata.name);
               setStatus(schooldata.status);

               setAddress1(schooldata.address1);
               setAddress2(schooldata.address2);
               setCity(schooldata.city);
               setState(schooldata.state);
               setCountry(schooldata.country);
               setEmail(schooldata.email);
               setPhone(schooldata.phone);
               setWebsite(schooldata.website);
               setPincode(schooldata.pincode);
          
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


 
  const Updateschool = async (e) => {
    e.preventDefault();
     setsubmitLoading(true)
    if(checkEmptyUndefined(name)=== false    ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false

    }
  

    try {
        var  statusupdate = (typeof status == 'undefined'  || status == '' || status == '1')?'1':0;
      var postdata={_id:id ,name :name ,status:statusupdate,

 address1 :address1, address2 :address2, city :city , country :country, state :state, email :email, phone :phone, website :website, pincode : pincode };
 
      const response = await fetch(Url+"updateschool", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
            //redirectPage('schools')

              //  navigate(baseUrl+'schools')
          } else{
              setnotificationtype('error')
                    setnotification(data.message)
          }

           
       })
   setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleSchoolInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    console.log(value)
    switch (name) {
        case 'name':
            setName(value);
             
             
            break;


             case 'status':
            setStatus(value);
             
             
            break;
              case 'address1':
            setAddress1(value);
             
             
            break;

             case 'address2':
            setAddress2(value);
             
             
            break;

             case 'city':
            setCity(value);
             
             
            break;
         
            case 'state':
            setState(value);
             
             
            break;

        case 'country':
            setCountry(value);
             
             
            break;

             case 'email':
            setEmail(value);
             
             
            break;

        case 'phone':
            setPhone(value);
             
             
            break;
         case 'website':
            setWebsite(value);
             
             
            break;

              case 'pincode':
            setPincode(value);
             
             
            break;
         
        default:
            break;      
    }
 }

 
    
useEffect(() => {
    Getschool(id);


    if(typeof id != 'undefined' && id !=''){
    checkpermissionredirect(Allowschools_edit)
}else{
    checkpermissionredirect(Allowschools_add)

}


  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit School":"Add School"} />

         <Sidebar activePage="Schools" activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit School":"Add School"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit School":"Add School"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                        <label>Name <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                        <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                        onChange={(e) => handleSchoolInput(e)}
                        required/>
                        </div>

                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleSchoolInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>


                        <div className="col-md-4">
                        <label>Pincode <span className="field-required"></span></label>
                        </div>
                        <div className="col-md-8 form-group">
                        <input className="form-control"  value={pincode}  type="text" name="pincode" placeholder="Pincode"  
                        onChange={(e) => handleSchoolInput(e)}
                        required/>
                        </div>


                        <div className="col-md-4">
                            <label>Address 1</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={address1}  type="text" name="address1" placeholder="Address 1"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>


                        <div className="col-md-4">
                            <label>Address 2</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={address2}  type="text" name="address2" placeholder="Address 2"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>

      <div className="col-md-4">
                            <label>City</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={city}  type="text" name="city" placeholder="City"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>

     
      <div className="col-md-4">
                            <label>State</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={state}  type="text" name="state" placeholder="State"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>
          
      <div className="col-md-4">
                            <label>Country</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={country}  type="text" name="country" placeholder="Country"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>
          
      <div className="col-md-4">
                            <label>Email</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={email}  type="text" name="email" placeholder="Email"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>

                 <div className="col-md-4">
                            <label>Phone</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={phone}  type="text" name="phone" placeholder="Phone"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>
            <div className="col-md-4">
                            <label>Website</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={website}  type="text" name="website" placeholder="Website"  
                            onChange={(e) => handleSchoolInput(e)}
                            required/>
                        </div>
 



                        
                        
                         {( submitLoading === false) ?  (
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateschool}>Submit</button>
                         </div>
                          ) : '' }
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default General;