import React, { useState , useEffect} from "react";
 import {
    Link 
} from "react-router-dom";

  import ReactPlayer from 'react-player'
 

import {GetWatchVideoId,Socketiourl,SiteLogo,SiteLogoDark,AkuserId,Url,frontUrl,Akusername,userProfileurl,postDetailurl} from '../Frontapi.js';
 

  import Share from "../share/Share"
  import Video from "../video/Video"

  import CommentsPopup from "../commentspopup/Commentspopup"

 
 import {Socketcall} from '../../socketcall/Socketcall.js';

 const Singlepost = ({postid}) => {


const [post,setpost]=useState([])
const [Cusername,setCusername]=useState()

const [postlikes,setpostlikes]=useState('')


const [shareurl, setShareurl]= useState('')
const [Showsharepopup, setShowsharepopup] = useState(0);



const [Showcommentspopup, setShowcommentspopup] = useState(0);


const [Showvideopopup, setShowvideopopup] = useState(0);
  const [videopostid, setvideopostid] = useState('');

 
const [showLesscaption , setshowLesscaption] = useState(1)
const [ showFullcaption     , setshowFullcaption]  = useState(0)    
const [ captionlength     , setcaptionlength]   = useState(0)    

const [videoId, setvideoId]= useState(0) 

const [playerid]=useState(Math.floor(Math.random() * 52))

const Getpostdetails =async() =>{

 
 


 var postdata={_id:postid, checkuserid:AkuserId}

         const response = await fetch(Url+"get-post", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
   (async function() {

            var postdata= data.data;


 


                    postdata.image =    postdata.thumbnail 
                    postdata.userprofilepicture =   postdata.userimage 

                    postdata.topic =   postdata.topic


                    if(postdata.caption.length > 100){
                    postdata.lesscaption =   postdata.caption.substring(0, 100)+'...'

                    }else{
                    postdata.lesscaption =   postdata.caption

                    }

                    postdata.fullcaption =   postdata.caption
                    setcaptionlength(postdata.fullcaption.length) 

                    setpost(postdata)
                    setpostlikes(postdata.likescount)

  


 



                 setCusername(postdata.username)


    /***Youtube****/
           
setvideoId(await GetWatchVideoId(postdata.youtubeurllink)  )


                  window.YT.ready(function() {
                           (async function() {
       
    new window.YT.Player('player_'+playerid, {
      height: "400",
      width: "100%",
      videoId: await GetWatchVideoId(postdata.youtubeurllink)   ,
      events: {
       // onReady: onPlayerReady,
      onStateChange: onPlayerStateChange
      }
    });
     })()

  });


  function onPlayerStateChange(event) {
     var currentPlayingvideoid = event.target.playerInfo.videoData.video_id;


            var videoplayers= document.getElementsByClassName('video-m-player')

                Array.prototype.forEach.call(videoplayers, function(el) {

                 var videoid=    el.attributes.getNamedItem('data-attr-video').value;

                 var vplayerid=    el.attributes.getNamedItem('data-attr-vplayer-id').value;
                 
              
    var videoStatuses = Object.entries(window.YT.PlayerState)
     var video_status=videoStatuses.find(status => status[1] === event.data)[0]
 
 if(video_status == 'PLAYING'){ 
                if(currentPlayingvideoid  == videoid ){
                   
                }else{

                     //  var bkvideolink= el.innerHTML;

                    document.getElementById(vplayerid).remove();

                var g = document.createElement('div');
                g.setAttribute("id", vplayerid);

            el.append(g);


                    

                       // el.innerHTML='';
                        //el.innerHTML=bkvideolink; 


                         window.YT.ready(function() {
    new window.YT.Player(vplayerid, {
      height: "400",
      width: "100%",
      videoId: videoid,
      events: {
       // onReady: onPlayerReady,
      onStateChange: onPlayerStateChange
      }
    });
  });

                }
}
                
                       

                  
             });



  }  
    /***Youtube****/


                 /* 
                  setpostLiked(postdata.liked)
                  setpostSaved(postdata.saved)
                  setpostLikescount(postdata.likescount)
                  setppostTimediff(postdata.posttimediff)*/
 

             

 


                 

             //Notifyalert('success', data.message)
            // document.getElementById("NewPostForm").reset()
                             
            
 })()
   

          } else{
               
          }

           
       })
 
  

}



const UpdateLikes= async (e,value ) => {


  var liked = e.target.attributes.getNamedItem('data-update-like').value;

  if(parseInt(liked)  ===  parseInt(0) ){


   e.target.setAttribute('data-update-like',1);
      e.target.setAttribute('class','fa fa-heart');



 

Socketcall('on', 'joinuser',{token:Cusername})
Socketcall('emit', 'newlike',{token:Cusername,  from:Akusername})


}else{
    e.target.setAttribute('data-update-like',0);
      e.target.setAttribute('class','fa fa-heart-o');

}


    var postdata={postid:value,'userid':AkuserId};

        const response = await fetch(Url+"update-likes", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
    Getpostdetails();

              } else{
                 
          }

           
       })



};
 



 
 

const UpdateSaved= async (e, value ) => {


  var liked = e.target.attributes.getNamedItem('data-update-save').value;
if(parseInt(liked)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-save',1);
      e.target.setAttribute('class','fa fa-bookmark');


}else{
    e.target.setAttribute('data-update-save',0);
      e.target.setAttribute('class','fa fa-bookmark-o');

}


    var postdata={postid:value,userid:AkuserId};

        const response = await fetch(Url+"update-saves", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              } else{
                 
          }

           
       })



};

 


const goToPost = (id) => {

 //    window.location.href= frontUrl+'p/'+id;
        document.getElementsByClassName('post-mob-img-'+id)[0].style.display = "none";
     document.getElementsByClassName('post-video-'+id)[0].style.display = "block";
     localStorage.setItem('lastheight', document.documentElement.scrollTop)

}
const ClickcheckEvent= ( ) => {
 //   alert()

    }


const showShareProfile= async()=>{

setShareurl(postDetailurl + postid);
setShowsharepopup(1)



}

const closeShareProfile= async()=>{

setShowsharepopup(0)



}




const openCommentpopup= async(postid)=>{

  
 setShowcommentspopup(1)



}

const closeCommentpopup= async()=>{

setShowcommentspopup(0)



}





const openVideopopup= async(postid)=>{
   setvideopostid(postid)
 setShowvideopopup(1)



}

const closeVideopopup= async()=>{

setShowvideopopup(0)



}


            

const ToggleFullcaption = async()=>{
    
    setshowLesscaption(0)
    setshowFullcaption(1)
}
const ToggleLesscaption = async()=>{
      setshowFullcaption(0)
    setshowLesscaption(1)
}



  useEffect(() => {
    Getpostdetails();



 

  
    },[] );



return (

 <div >
    <div className="" >
     {(Showsharepopup == 1) ?  


            <div className="share-modal-box">

            <div className="share-modal-bg">

                            <div className="share-modal-close">

                                    <button  onClick={closeShareProfile} > X </button>
                              </div>

        <Share shareurl={shareurl} />



        

             </div>
            </div> : '' }
</div>


    <div className="" >

    {(Showcommentspopup == 1) ?  


            <div className="comment-modal-box">

            <div className="comment-modal-bg">

                            <div className="comment-modal-close">

                                    <button  onClick={closeCommentpopup} > X </button>
                              </div>

                


                  <CommentsPopup viewpostid={postid} />            


        

             </div>
            </div> : '' }


            {(Showvideopopup == 1) ?  


            <div className="video-modal-box">

            <div className="video-modal-bg">

                            <div className="video-modal-close">

                                    <button  onClick={closeVideopopup} > X </button>
                              </div>

        <Video videopostid={videopostid} />



        

             </div>
            </div> : '' }
</div>



 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   mb-50 ">



                     <div className="post-main-section" >
                     <div className="post-info-title" >
                     <div className="display-flex-v">
                      <div className="post-info-user-img-sec" >
                     <Link to={frontUrl+'my-profile'}>   <img src={post.userprofilepicture} className="post-info-user-img" /> </Link>

                     </div>
                     {(Akusername == post.username) ?  
                     <Link to={frontUrl+'my-profile'}> <span className="post-info-user" > {post.username}</span> </Link> 
                     :

                     <Link to={userProfileurl+post.username}> <span className="post-info-user" > {post.username}</span> </Link> 
                    }

</div>
                      


                        </div>


                       <div className={'post-img-section post-desk-i-view post-img-'+post._id}  onClick={(postId)=>openVideopopup(post._id)  }> 
                        <img src={post.image} className="post-sec-img" />
                     </div>
                    
                     <div className="post-mob-view ">

                       <div className="video-m-player" data-attr-video={videoId}   data-attr-vplayer-id={'player_'+playerid} >
                      <div id={'player_'+playerid}></div>
                      </div>

 {/* 
                     
                     <div style={{'display':'none'}} className={'post-img-section post-mob-img-section post-mob-img-'+post._id}  onClick={(postId)=>goToPost(post._id)  }> 
                        <img src={post.image} className="post-sec-img" />
                     </div>
                    


                        <div   id={'plmb'+post._id} className="post-img-section post-mob-loading-section"   > 
                         Loading....
                     </div>


                          <div className={'post-video-section post-video-'+post._id} data-post-id={post._id}  onClick={ClickcheckEvent} >    
                         <ReactPlayer className="reactplayer" width="100%" height="300px"  url={post.youtubeurllink} />
                         </div> */ }

                          </div>
 

                     <div className="post-action-section t-white">
                        <div className="post-action-sub"> 
 
                        <span className="post-action-main" > 
                        {( post.liked == 1) ? 

                         <span className="post-icon-option" >  
                          <i className="fa fa-heart" data-update-like="1" onClick={(e,postid) =>UpdateLikes(e,post._id)}></i> 

                              
                          </span> 
                          :
                           <span className="post-icon-option" >  
                           <i className="fa fa-heart-o" data-update-like="0" onClick={(e,postid) =>UpdateLikes(e,post._id)}></i>
                             
                          </span> 
                        }
                             

                        <span className="post-icon-option"  onClick={(postid)=> showShareProfile(post._id)} >
                        <i className="fa fa-paper-plane-o"  ></i>
                        
                          </span> 


                            

                           <span className="post-icon-option" onClick={(value)=> openCommentpopup(post._id)}>
                            <i   className="fa fa-comment-o"></i> 
                             
                          </span> 


                            </span> 

                             <span className="post-action-optional" >

                              {( post.saved == 1) ? 
                          <i className="fa fa-bookmark" data-update-save="1" onClick={(e,postid) =>UpdateSaved(e,post._id)}></i> :
                           <i className="fa fa-bookmark-o" data-update-save="0" onClick={(e,postid) =>UpdateSaved(e,post._id)}></i>

                        }


                             </span>  </div>
                     </div>
                    { (postlikes >  0) ?  <div className="post-data-stat t-white" > {postlikes+' likes'}  </div>: ''}


                    <div className="post-data-capt post-data-title t-white" > {( post.topic )} </div> 

                       { (showLesscaption == 1) ? 
                      <div className="post-data-capt t-white" > {( post.lesscaption )}  {(captionlength  > 100) ? <a onClick={ToggleFullcaption} >See more</a> : ''} </div>  : '' }


                        { (showFullcaption == 1) ? 
                      <div className="post-data-capt t-white" > {( post.fullcaption )}  <a onClick={ToggleLesscaption}  ><br/>See less</a> </div>  : '' }

                     <div className="post-time-info t-white">{post.posttimediff} </div>
                  </div>
                  </div>

 
               


 </div>
            



	)













 }
export default Singlepost;