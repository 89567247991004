import React, {    useState} from "react";
import { Link,   useLocation,    useNavigate} from "react-router-dom";
 import {SiteLogo,AkuserId, validPasswordRegex,Notifyalert,  SetNotificationAfterName, frontUrl,    Url,    Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,UrlSplitter} from '../Frontapi.js';
  import Header from '../includes/Header';


const Resetpassword = () => {
          Redirectdashboard();


   

        const token = UrlSplitter(2);

       
       

        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");

        const [password, setPassword] = useState("");
 
        const [confirm_password, setConfirmPassword] = useState("");
 
        const [formValid, setformValid] = useState(false);
        const [validationSubmit, setvalidationSubmit] = useState(false);

    const handleUserInput = (e) => {
       
            setnotification("");
         setTimeout(function(){       
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
            case 'password':
            setPassword(value);
            break;
            case 'confirm_password':
            setConfirmPassword(value);
            break;    

            }

            if(   password != confirm_password )  { setnotificationtype('error');  setnotification('Passwords not matched'); }
            (password != '' &&  password == confirm_password ) ? setformValid(true): setformValid(false)

        },200)
    
   }

        const Updatepassword = async (e) => {
            e.preventDefault();

             /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-25');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
             /***Check Required***/

               if(error === 1){
                    return false
            }
            if(validPasswordRegex.test(password) == false){
                

             //    SetNotificationAfterName('password','Password is too weak')
               // return false
            }


            if( password  !==  confirm_password  ){

                SetNotificationAfterName('password','Password and Confirm Password Dont Match')
                return false
  
            }








            var postdata = {
                password: password,token:token
            };
            try {
                const response = await fetch(Url + "user-update-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success',data.message)
                            setPassword('');
                             setConfirmPassword('');
                             document.getElementById("ResetpasswordForm").reset();

                         } else {
                           Notifyalert('error',data.message)
                         }
                    })
            } catch (error) {
               // setnotificationtype('error')
                //setnotification(error)
            }
        };


        
      


  return (
 <div>

  <Header pageInfo="Login" />

    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
    
    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 

        {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 

    <div className="page-content">

    <div className="login-container"> 
        
        <div className="login-section"> 
        <h1 className="login-title">Update Password</h1>
   
                  <form  id="ResetpasswordForm" >
                     <div className="form-group">
                         <input  className="form-control login-input mb-25 MustEnterField"   type="password" name="password"   placeholder="Password"  
                           onKeyUp={(e) => handleUserInput(e)}
                        required/>
                     </div>
                     <div className="form-group">
                         <input  className="form-control login-input mb-25 MustEnterField"    type="password" name="confirm_password"   placeholder="Confirm Password"  
                           onKeyUp={(e) => handleUserInput(e)}
                        required/>
                     </div>
                       <p className="forgot-password-inst"  > <Link to={frontUrl+'login'} >Go back to Login</Link> </p>             
                      <div className="clearfix">
                        <button className="login-button" onClick={Updatepassword}>Submit</button>
                     </div>
                     
                  </form>
               
     

  </div>

    </div>
    </div>


 : ''}
    </div>


    </div>



    </div>
  );
};
export default Resetpassword;