import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Advertisement = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [adfees, setAdfees] = useState("");
    const [adduration, setAdduration] = useState("");

  const [challengefees, setChallengefees] = useState("");

                const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {



  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setAdfees(settingsdata.adfees );
           setAdduration(settingsdata.adduration );
              
              setChallengefees(settingsdata.challengefees );


            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updateadsettings = async (e) => {
    e.preventDefault();
    setnotification("");

    setsubmitLoading(true)
      if(checkEmptyUndefined(adfees) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }
     if(checkEmptyUndefined(adduration) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }
        if(checkEmptyUndefined(challengefees) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={adfees:adfees,  adduration:adduration,challengefees:challengefees  };
 
       const response = await fetch(Url+"update-ad-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                     setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           setsubmitLoading(false)
       })
 
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'adfees':

              setAdfees(value);
             break;
     
           case 'adduration':

              setAdduration(value);
             break;
        
               case 'challengefees':

              setChallengefees(value);
             break;


             default :
              break;
    }


 }
   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Advertisement Price<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <input className="form-control" type="number" name="adfees" placeholder="Advertisement Price" value={adfees} onChange={(e) => handleUserInput(e)} required />
                                 </div>


                                <div className="col-md-4">
                                    <label htmlFor="address">Advertisement Duration (Days)<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <input className="form-control" type="number" name="adduration" placeholder="Advertisement Duration" value={adduration} onChange={(e) => handleUserInput(e)} required />
                                 </div>


                                  <div className="col-md-4">
                                    <label htmlFor="address">Challenge Participation Fee<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <input className="form-control" type="number" name="challengefees" placeholder="Challenge Participation Fee" value={challengefees} onChange={(e) => handleUserInput(e)} required />
                                 </div>

                                

                                   {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateadsettings}>Submit</button> : ''}
                               </div>
                                ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Advertisement;