import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

  import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo} from '../ImagesIcons.js';


 

// import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {frontUrl , Url,Banner,Vision,SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';



   import Signup from "../auth/Register"


   import Dashboard from "../pages/Dashboard"


 import Footer from "../includes/Footer";


 
const Loginregister = () => {
  const navigate = useNavigate();

 const urlParams = new URLSearchParams(window.location.search);

const myParam = urlParams.get('referral');

   localStorage.setItem('AKreferralcode', myParam)
   

const [showSplash,setshowSplash]=useState(0)

const [showSignup,setshowSignup]=useState(0)

const [showDashboard,setshowDashboard]=useState(0)


const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');




 const Getsettings = async (e) => {
  
  try{


     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            

            var settingsdata=data.data;
             setOfflemail(settingsdata.email );
      setOfflphone(settingsdata.phone );
      setOfflmobile(settingsdata.mobile );
 

            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {

    console.log(error)
      
  }

 } 





const Redirect = () => {

            

                 setTimeout(function() {
                    setshowSplash(0)

              setshowSignup(0)
             
              if((AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') ){
                setshowSignup(1)
              }

             
 
     }, 1000);
              
            
   }

 
   

  
       useEffect(() => {

   Getsettings();

 
 
   if((AkuserId !=  null && AKcountsupdated != null  )){
          setshowSplash(0)

                setshowDashboard(1)
              }else{

                if(window.location.search.substr(1)  == 'direct'){
  setshowSignup(1)
 }else{
    setshowSignup(1)

 }

 
                //    Redirect();
              }

 
  
  }, []);
   

  return (

      <div id="app">



      <div className=" full-section"> 

       

    {( (AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') && showSignup == 1) ?    <Signup /> :'' }
    {(AkuserId !=  null && AKcountsupdated != null && showDashboard == 1) ?   <Dashboard /> : '' }


      </div>
   </div>
 
  );
};
export default Loginregister;