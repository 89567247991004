import React, {useEffect, useState } from "react";
  import {UrlSplitter,convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,Socketiourl,checkpermissionredirect} from './../../Api.js';

 import { Link } from 'react-router-dom';
 import io from 'socket.io-client';

 import {Socketcall} from '../../../socketcall/Socketcall.js';



  import {    
   Allowposts_view ,
   Allowposts_edit
 } from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

         const [id] = useState(UrlSplitter(3));
 const [challengewinnerpostid, setChallengewinnerpostid] = useState('');

 
 

 const [postsList, setpostsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);


//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(postsList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetPosts()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetPosts()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetPosts(value)  
          
               
         
            
           

        }  

  const GetPosts = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage,admin:1 };

        const response = await fetch(Url+"getchallengesubmissionsauth", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setpostsList(data.data );
        setChallengewinnerpostid(data.winner)
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };

  const RejectPost = async (id,username) => {
 if(window.confirm("Are you sure?")){


    Socketcall('on', 'joinuser',{token:username})
        Socketcall('emit', 'newrejection',{token:username, from:''})

 

        var postdata={'id' : id , status : 2 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              UpdateNotify(data.message, 'success')
            GetPosts(1)
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
            
             
          }

           
        })

    }
}

  const ApprovePost = async (id,username) => {
     
    if(window.confirm("Are you sure?")){

       
    Socketcall('on', 'joinuser',{token:username})
        Socketcall('emit', 'newapproval',{token:username, from:''})


        var postdata={'id' : id , status : 1 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           UpdateNotify(data.message)
            GetPosts(1)
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
             
             
          }

           
        })

    }

     


  };

  

 
     const UpdateChallengeWinner = async (id ) => {

         if(window.confirm("Are you sure you want to set this user as winner?")){
        var postdata={'id' : id };
        const response = await fetch(Url+"update-challenge-winner", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            UpdateNotify(data.message)
          }  

           
        })

    }


    }


  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = postsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(postsList.length / recordsPerPage)



//pagination part
  const updateEntriescount = async() => {
  var postdata={ type:'challenges'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}



          useEffect(() => {
   GetPosts();


         checkpermissionredirect(Allowposts_view)
         updateEntriescount()



  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Challenges Posts List" />

         <Sidebar activePage="Challenges" activeChildPage="Listchild" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Challenges Pending Posts" />

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Posts</span>
             </div>
            <div className="card-body">

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                            <th>Topic</th>
                            <th width="20%">Caption</th>

                            <th>Username</th>

                            <th>URL</th>
 
                            <th>Date</th>
                              <th>Payment Status</th>
                             <th>Status</th>
                          <th>Action</th> 

                         </tr>
                    </thead>
                    <tbody>
                       {postsList.map((post) => (
                        <tr>
                            <td>{post.topic}</td>
                                                         <td width="20%" style={{"word-break":"break-all"}}>{post.caption}</td>

                              <td>{post.username}</td>


                            <td>{post.youtubeurllink}</td>


 
                            <td>  {  post.createddatetime   }   </td>

                             <td>
                                {(post.paid == 1) ?  <span className="badge bg-success">Paid</span>: <span className="badge bg-danger">Failed / Cancelled</span>}
                            </td>


                             <td>
                                {(post.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}
                            </td>
                             <td className="table-action-sections table-action-sections-post ">

                             {(Allowposts_edit == 1) ? 
                            <div>
                             <a     onClick={() => ApprovePost(post._id,post.username)}  className="fa-edit-btn"    ><i className="fa fa-check"></i> </a>
   
                            <a     onClick={() => RejectPost(post._id,post.username)}  className="fa-del-btn"    ><i className="fa fa-close"></i> </a>

                            </div>
                            : ''}

                             </td> 


                        </tr>
                        
                           ))}
                        
                    </tbody>
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;