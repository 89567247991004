import React, { useState ,useEffect} from "react";
 import {
    Link,
    useNavigate
} from "react-router-dom";
 import io from 'socket.io-client';

import {HeadBg,MidBg,FootBg,NewMidBg} from '../ImagesIcons.js';


   import Invitefriendscomponent from "../pages/Invitefriendscomponent"


 import {addReelIcon,homeIcon,challengeIcon,searchIcon,liveStreamIcon,preferenceIcon,streamingIcon,userIcon,logOutIcon,messageIcon,referralIcon} from '../ImagesIcons.js';

 
 import {RecieveSocketcall,socketUrl} from '../../socketcall/Socketcall.js';




// import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {UrlSplitter,SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , Akusername, containerEnd,AKcountsupdated,RedirectLogin_Terms,AkuserId, Url,frontUrl} from '../Frontapi.js';



 


 
const Sidebar = ({showBottomNavbar,showMobSidebar,updateSidecount,noauthneeded}) => {


  const navigate = useNavigate();

 const [ShowinviteFriendspopup, setShowinviteFriendspopup] = useState(0);



const [messagecounts,setmessagecounts]=useState(0)
const [notifycounts,setnotifycounts]=useState(0)

const socket = io.connect(socketUrl);



const [homeLinkactive] = useState(  (UrlSplitter(1).trim() == '') ? 'active':''  )
const [searchLinkactive] = useState(  (UrlSplitter(1).trim() == 'search') ? 'active':''  )
const [challengesLinkactive] = useState(  (UrlSplitter(1).trim() == 'challenges') ? 'active':''  )
const [newpostLinkactive] = useState(  (UrlSplitter(1).trim() == 'create-new-post') ? 'active':''  )
const [streamsLinkactive] = useState(  ( UrlSplitter(1).trim() == 'livestreams' || UrlSplitter(1).trim() == 'my-streams'||UrlSplitter(1).trim() == 'add-stream'   ) ? 'active':''  )
const [notificationsLinkactive] = useState(  (UrlSplitter(1).trim() == 'notifications') ? 'active':''  )
const [messagesLinkactive] = useState(  ( UrlSplitter(1).trim() == 'messages' || UrlSplitter(1).trim() == 'm' ) ? 'active':''  )
const [profileLinkactive] = useState(  (UrlSplitter(1).trim() == 'my-profile') ? 'active':''  )

const [ordersLinkactive] = useState(  (UrlSplitter(1).trim() == 'orders') ? 'active':''  )
const [invitefriendsLinkactive] = useState(  (UrlSplitter(1).trim() == 'invite-friends') ? 'active':''  )

 
 
  const stoppropagation = async (e) => {
            e.stopPropagation();
        }



 if(AKcountsupdated == null){
 //import  ('../common.css');


 }
if(typeof noauthneeded =='undefined'){
     RedirectLogin_Terms();

}




const Getcounts = async()=>{
 var postdata={'userid':AkuserId };

        const response = await fetch(Url+"get-m-counts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


              setmessagecounts(data.mcount)
              setnotifycounts(data.ncount)


 
 

              } else{
                 
          }

           
       })

}

 if(updateSidecount == 1){
    Getcounts();
}


  const allowNotification  =async() =>{

if(!window.Notification) {
 } else {
      if (Notification.permission === 'granted') {
    
    
    } else {
        // request permission from user
        Notification.requestPermission().then(function(p) {
         
        }) ;
    }


  }

}


const newNotification =async(value,data) =>{


if(data.from != data.token){
   if (Notification.permission === 'granted') {

         var notify = new Notification(value);

      } 
}
      


}
const hideModal =async() =>{
             if(document.getElementsByClassName('newmodal')[0]){
                             document.getElementsByClassName('newmodal')[0].style.display='none'

             }
              if(document.getElementsByClassName('invitemodal')[0]){
                       //      document.getElementsByClassName('invitemodal')[0].style.display='none'

             }
 
}




const closeLogoutModal=async() =>{
document.getElementById('logoutmodal').style.display='none'

}

const showLogoutModal=async() =>{


 setTimeout(function(){
        if(document.getElementById('logoutmodal')){
            document.getElementById('logoutmodal').style.display='none'
        }


        document.getElementById('logoutmodal').style.display='block'

        },200)

}
  const toggleShowinviteFriendspopup =async()=>{
          setShowinviteFriendspopup(1)   
      document.getElementsByClassName('invitemodal')[0].style.display='block'

       } 
        const toggleHideinviteFriendspopup =async()=>{
          setShowinviteFriendspopup(0)   
      document.getElementsByClassName('invitemodal')[0].style.display='none'

       }

document.onclick = hideModal;



   useEffect(() => {

 

   
    Getcounts();
    allowNotification();
    (async function() { 

var callSocket = await RecieveSocketcall();


if(callSocket == 1){ 
      socket.emit("joinuser",{token:Akusername});

      socket.on("newmessagereceived", (data) => {


      newNotification('New message received from '+ data.from , data)
      Getcounts();

      })  


      socket.on("newfollowreceived", (data) => {

          newNotification( data.from+ ' followed you', data)
          Getcounts();

      })  


         socket.on("newlikereceived", (data) => {

          newNotification( data.from+ ' liked your post', data)
          Getcounts();

      })

      socket.on("newcommentreceived", (data) => {

          newNotification( data.from+ ' commented on your post', data)
          Getcounts();

      })


      socket.on("newrejectionreceived", (data) => {

          newNotification(  "Your post has been rejected", data)
          Getcounts();

      })


      socket.on("newapprovalreceived", (data) => {

          newNotification(  "Your post has been approved", data)
          Getcounts();

      })

   }

 })()
  
    },[messagecounts,notifycounts] );

 
  return (
<div>
   <div id="notify-alert-notification">notify-alert-notification </div>
   {(AkuserId !=  null && AKcountsupdated != null   ) ? 
   <div className="s-layout">
      {(showMobSidebar ==1) ?  
      <div className="s-mob-sidebar display_none col-12">
      {/* <div className="col-3 fl-left">
         <ul >

          



            <li>
               <a>Alfa kids  <i className="fa fa-caret-down"> </i> </a>
               <ul>
                  <li>
                     <Link   to={frontUrl+'signout'}  >
                     <img src={signOutIcon} />
                     Sign out
                     </Link>
                  </li>
               </ul>
            </li>
         </ul>
         </div>*/}
         <div className="col-5  fl-left">
          <div className="sidebar-mob-image-section">
         <img src={MidBg} /> </div>
         </div>
                  <div className="col-2  fl-left">


                  </div>
         <div className="s-mob-sidebar-options col-5  fl-left">
            <div className="fl-right display_flex">



           




            <ul >

            <li> 
            <span className="mob-new-post">
               <Link  to={frontUrl+'create-new-post'} >
               <i className="amigofa-icon-add-reel "> </i>
             
               </Link>  
            </span>
             </li>
             <li>             <span className="mob-new-post">

                         <Link  to={frontUrl+'messages'} >
            <i className="fa fa-commenting-o"> </i>   </Link> 

            </span>
             </li>
            <li>
                                   <span className="mob-new-post">

               <Link> <i className="fa fa-user"> </i> <i className="fa fa-caret-down"> </i>   </Link>
               </span>
               <ul>



                  <li>
                     <Link   to={frontUrl+'notifications'} className="mob-side-link"  >
                     <span className="mob-side-link-icon"><i className="fa fa-bell-o"></i> </span>
                     <span className="mob-side-link-label">Notifications </span>
                     
                     
                     </Link>
                  </li>

                   <li>
                     <Link   to={frontUrl+'invite-friends'} className="mob-side-link" >
                      <span className="mob-side-link-icon"> <i className="fa fa-user"> </i></span>
                     <span className="mob-side-link-label"> Invite Friends</span>
                     
                     
                     </Link>
                  </li>

         
                  <li>
                     <Link   to={frontUrl+'orders'}  className="mob-side-link">
                      <span className="mob-side-link-icon">  <i className="fa fa-shopping-cart"> </i></span>
                     <span className="mob-side-link-label">My Orders </span>
                    
                     
                     </Link>
                  </li>
             



                 {/*} <li>
                     <Link   to={frontUrl+'signout'}  className="mob-side-link" >
                      <span className="mob-side-link-icon">  <i className="fa fa-sign-out"> </i>  </span>
                     <span className="mob-side-link-label  "> Sign out </span>
                    
                     
                     </Link>
                  </li>*/}

                 

                  <li onClick={showLogoutModal} >
                     <a    className="mob-side-link" >
                      <span className="mob-side-link-icon">  <i className="fa fa-sign-out"> </i>  </span>
                     <span className="mob-side-link-label  "> Sign out </span>
                    
                     
                     </a>
                  </li>


                  


               </ul>
 
            </li>
         </ul>



             </div>
         </div>
      </div>
      :''}
      {(showBottomNavbar ==1) ?  
      <div className="s-mob-layout">
         <ul>
            <li className=" ">
               <Link className="s-sidebar__nav-link" to={frontUrl+'search'}  >
               <img src={searchIcon} />
               </Link>
            </li>
            <li className=" ">
               <Link className="s-sidebar__nav-link" to={frontUrl+'challenges'}  >
               <img src={challengeIcon} />
               </Link>
            </li>
            <li  >
               <Link className="s-sidebar__nav-link" to={frontUrl}  >
               <img src={homeIcon} />
               </Link>
            </li>
            <li className=" ">
               <Link className="s-sidebar__nav-link" to={frontUrl+'livestreams'}  >
               <img src={liveStreamIcon} />
               </Link>
            </li>
            <li className=" ">
               <Link className="s-sidebar__nav-link" to={frontUrl+'my-profile'}  >
               <img src={preferenceIcon} />
               </Link>
            </li>
         </ul>
      </div>
      :''}
      <div className="s-layout__sidebar">
         <a className="s-sidebar__trigger" href="#0" style={{display:'none'}}>
         <i className="fa fa-bars"></i>
         </a>
         <nav className="s-sidebar__nav">
            <div className="sidebar-image-section">
               <Link to={frontUrl}>
               <img   src={MidBg} /> </Link>
            </div>
            <ul>
               <li className={homeLinkactive}  >
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl}  >
                  <div className="s-sidebar__nav-img-section">    <i className="amigofa-icon-home"></i>  </div>
                  <span className="nav-link-text"> Home </span>
                  </Link>
               </li>
               <li className={searchLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'search'}  >
                  <div className="s-sidebar__nav-img-section">   <i className="amigofa-icon-search"></i> </div>
                  <span className="nav-link-text">Search </span>
                  </Link>
               </li>
               
               <li className={challengesLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'challenges'}  >
                  <div className="s-sidebar__nav-img-section">  <i className="amigofa-icon-challenge"></i> </div>
                  <span className="nav-link-text"> Challenges</span>
                  </Link>
               </li>
               <li className={newpostLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex"  to={frontUrl+'create-new-post'}  >
                  <div className="s-sidebar__nav-img-section">  <i className="amigofa-icon-add-reel"></i>  </div>
                  <span className="nav-link-text"> Create New Post</span>
                  </Link>
               </li>
               <li className={streamsLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'livestreams'}  >
                  <div className="s-sidebar__nav-img-section">   <i className="amigofa-icon-live-stream"></i>  </div>
                  <span className="nav-link-text"> Live Streamings</span>
                  </Link>
               </li>
               <li className={notificationsLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'notifications'}  >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-bell-o"></i>  </div>
                  <span className="nav-link-text">Notifications {(notifycounts> 0) ? <span className="noti-count">{notifycounts}</span>  : '' } </span>
                  </Link>
               </li>
               <li className={messagesLinkactive} >
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'messages'}  >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-telegram"> </i>  </div>
                  <span className="nav-link-text"> Messages  {(messagecounts> 0) ? <span className="noti-count">{messagecounts}</span>  : '' }   </span>
                  </Link>
               </li>
               <li className={profileLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" id="my-profile" to={frontUrl+'my-profile'}  >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-user-o"> </i>  </div>
                  <span className="nav-link-text"> My Profile </span>
                  </Link>
               </li>
               <li className={ordersLinkactive}>
                  <Link className="s-sidebar__nav-link display_flex" id="orders" to={frontUrl+'orders'}  >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-shopping-cart"> </i>  </div>
                  <span className="nav-link-text"> My Orders </span>
                  </Link>
               </li>
               <li className={invitefriendsLinkactive}>
                  <Link to={frontUrl+'invite-friends'} className="s-sidebar__nav-link display_flex"    >
                  <div className="s-sidebar__nav-img-section"><i className="fa fa-user"> </i>  </div>
                  <span className="nav-link-text"> Invite Friends </span>
                  </Link>
               </li>
               {/*<li   >
                  <Link className="s-sidebar__nav-link display_flex" to={frontUrl+'signout'}  >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-sign-out"> </i>    </div>
                  <span className="nav-link-text"> Sign out</span>
                  </Link>
               </li>*/}

                 <li  onClick={showLogoutModal}  >
                     <a className="s-sidebar__nav-link display_flex"    >
                  <div className="s-sidebar__nav-img-section"> <i className="fa fa-sign-out"> </i>    </div>
                  <span className="nav-link-text"> Sign out</span>
                  </a>
                  </li>


            </ul>
         </nav>
      </div>
   </div>
   : '' }

   
   <div className="page-loader-dynamic display_none"  > <i className="fa fa-spinner fa-spin"></i></div>

   {(AkuserId !=  null && AKcountsupdated != null   ) ? 


    <div   className="invitemodal display_none"   >
   <div className="newmodal-dialog">
      <span id="modalClose" onClick={toggleHideinviteFriendspopup} className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>
      <div className="newmodal-content bg-black" onClick={(e)=>
         stoppropagation(e)}>
        
         <div className="update-profile-pic-section follower-list-section"  >

     
         
        <div className="modal-follow-wrapper">
         
   <Invitefriendscomponent />
            </div>
         </div>
      </div>
   </div>
</div> 
: '' }


   {(AkuserId !=  null && AKcountsupdated != null   ) ? 


                
 <div   className="logoutmodal display_none" id="logoutmodal">
   <div className="newmodal-dialog">
      {/*<span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>*/ }
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Logging out </h3>
         <div className="update-profile-pic-section following-list-section"  >

           
            <div className="modal-follow-wrapper">
                  
            <p className="logout-confirm-msg">Are you sure you want to logout? </p>

            <div className="logout-confirm-box">  

            <Link className="confirm-btn proceed-green"  to={frontUrl+'signout'} >Yes </Link>
             <button  className="close   confirm-btn cancel-red" onClick={(e)=>
         closeLogoutModal(e)} >No </button>

            </div>

            </div>
         </div>
      </div>
   </div>
</div>
: '' }



 
</div>
 
 
  );
};
export default Sidebar;