import React, { useState , useEffect} from "react";

import {frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername,GetProfilePicture} from '../Frontapi.js';
  import {
    Link 
} from "react-router-dom";

 import {logOutIcon } from '../ImagesIcons.js';


 const Miniprofile = ({videopostid}) => {

  const [Userprofilepicture, setuserprofilepicture] = useState({ preview:   GetProfilePicture, data: '' });

  


  useEffect(() => {
 
  (async function() { 

            setuserprofilepicture( {preview:  GetProfilePicture})
      
     })()

    },[] );

 
return (

<div>

<div className="mini-profile-section mb-25 bg-card-sidebar"> 

<div className="col-md-12 col-lg-12 col-sm-12 row">




<div className="col-md-4 col-lg-2 col-sm-2">
<div className="mini-profile-img-section"> <Link to={frontUrl+'my-profile '} > <img src={Userprofilepicture.preview} className="" /></Link>    </div>



</div>


<div className="col-md-6 col-lg-6 col-sm-2 ">
<div className="min-prof-pad">
 <Link to={frontUrl+'my-profile '} >
{Akusername}</Link>  

</div>

</div>


<div className="col-md-2 col-lg-4 col-sm-2 ">
  <div className="min-prof-pad">

  <Link to={frontUrl+'signout '} >
<img src={logOutIcon} className="w-30px" /></Link> 
</div>
</div>


 




</div>

</div>

</div>
	)













 }
export default Miniprofile;