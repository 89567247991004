import React, { useState ,useEffect,useRef} from "react";
//import  './style.css';
 

 import Header from './includes/Header';

 import Sidebar from "./includes/Sidebar";



import {AkuserId, SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin,Url  } from './Frontapi.js';






 
const Scrollpagination = () => {

  

  const [postsList, setpostsList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);

 
const [nPages, setnPages] = useState(1);
const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));



const GetPosts = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : recordsPerPage;

      var postdata={'currentpage':1,'perpage':value};

        const response = await fetch(Url+"getposts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setpostsList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };
/*
  const listInnerRef = useRef();

const onScroll = () => {
     alert();
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
       
        // This will be triggered after hitting the last element.
        // API call should be made here while implementing pagination.
      }
    }
  };*/

const handleScroll = () => {

        if (Math.round( window.innerHeight + document.documentElement.scrollTop)  === document.documentElement.offsetHeight  ) {

        var NewrecordsPerPage=parseInt(recordsPerPage)+parseInt(10)
        setrecordsPerPage(NewrecordsPerPage)
        GetPosts(NewrecordsPerPage);
        }

 
};



          useEffect(() => {
   GetPosts();

            window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);

  }, [recordsPerPage]);

  return ( 
<div  >

  <Header pageInfo="Scroll" />



    <div 
>
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
         <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
            <Sidebar />
         </div>
         <div className="col-md-7 col-lg-7 col-sm-9 col-xs-9 full-section">
            <div className="page-content">

               <div className="container-mb">

                 {postsList.map((post) => (

                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   mb-25 ">
                     <div className="post-info-title" > <span className="post-info-user" > Username</span>   <span className="post-info-action">  <span>  <i className="fa fa-ellipsis-v"></i>   </span>   </span> </div>
                     <div className="post-img-section"> 
                        <img src={SiteLogo} className="post-sec-img" />
                     </div>
                     <div className="post-action-section t-white">
                        <div className="post-action-sub"> <span className="post-action-main" >   <i className="fa fa-heart-o"></i>  <i className="fa fa-paper-plane-o"></i> <i className="fa fa-comment-o"></i> </span>  <span className="post-action-optional" > <i className="fa fa-bookmark-o"></i></span>  </div>
                     </div>
                     <div className="post-data-stat t-white" >467 likes </div>
                     <div className="post-data-capt t-white" >Caption </div>
                     <div className="post-time-info t-white">17 hrs ago </div>
                  </div>
                  ))}


               </div>
            </div>
         </div>
      </div>
   </div>
   
</div>
 
  );
};
export default Scrollpagination;