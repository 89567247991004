import React, { useState } from "react";
import {    useLocation,    useNavigate} from "react-router-dom";
  
 
import {baseUrl,Url,Socketiourl,userId , userEmail,userName} from "./Api";




 

const CheckPermissions = async() => {   

         if(typeof userId == 'undefined' ||  userId ==  null || userId == ''){ 

return {  
    dashboard_view: '1',
    settings_view: '1',
    settings_edit: '1',
    users_view: '1',
    users_add: '1',
    users_edit: '1',
    users_delete: '1',
    cms_view: '1',
    cms_edit: '1',
    emailtemplates_view: '1',
    emailtemplates_edit: '1',
    posts_view: '1',
    posts_edit: '1',
    ads_view: '1',
    ads_edit: '1',
    orders_view: '1',
    orders_edit: '1',
    enquiries_view: '1',
    streams_view: '1',
    schools_view: '1',
    schools_add: '1',
    schools_edit: '1',
    schools_delete: '1',
    subjects_view: '1',
    subjects_add: '1',
    subjects_edit: '1',
    subjects_delete: '1',
    clients_view:'1',
    roles_view:1,
    challenges_view: '1',
    challenges_add: '1',
    challenges_edit: '1',
    challenges_delete: '1'
     };

        }

if(userName == 'admin' && userEmail =='admin@amigoways.com'){ 
return {  
    dashboard_view: '1',
    settings_view: '1',
    settings_edit: '1',
    users_view: '1',
    users_add: '1',
    users_edit: '1',
    users_delete: '1',
    cms_view: '1',
    cms_edit: '1',
    emailtemplates_view: '1',
    emailtemplates_edit: '1',
    posts_view: '1',
    posts_edit: '1',
    ads_view: '1',
    ads_edit: '1',
    orders_view: '1',
    orders_edit: '1',
    enquiries_view: '1',
    streams_view: '1',
    schools_view: '1',
    schools_add: '1',
    schools_edit: '1',
    schools_delete: '1',
    subjects_view: '1',
    subjects_add: '1',
    subjects_edit: '1',
    subjects_delete: '1',
        clients_view:'1',
    roles_view:1,
    challenges_view: '1',
    challenges_add: '1',
    challenges_edit: '1',
    challenges_delete: '1'
     };
}
var postdata={  userid:userId   }
var result =  await  fetch(Url + "check-permissions", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
    if(data.success){

         return data.data

     }
         


         
         
    })

    return result



//
}

const  Permissionsv=await CheckPermissions()

 

 const Allowdashboard_view =  Permissionsv.dashboard_view
  const Allowsettings_view =  Permissionsv.settings_view
  const Allowsettings_edit =  Permissionsv.settings_edit
  const Allowusers_view =  Permissionsv.users_view
  const Allowusers_add =  Permissionsv.users_add
  const Allowusers_edit =  Permissionsv.users_edit
  const Allowusers_delete =  Permissionsv.users_delete
  const Allowcms_view =  Permissionsv.cms_view
  const Allowcms_edit =  Permissionsv.cms_edit
  const Allowemailtemplates_view =  Permissionsv.emailtemplates_view
  const Allowemailtemplates_edit =  Permissionsv.emailtemplates_edit
  const Allowposts_view =  Permissionsv.posts_view
  const Allowposts_edit =  Permissionsv.posts_edit
  const Allowads_view =  Permissionsv.ads_view
  const Allowads_edit =  Permissionsv.ads_edit
  const Alloworders_view =  Permissionsv.orders_view
  const Alloworders_edit =  Permissionsv.orders_edit
  const Allowenquiries_view =  Permissionsv.enquiries_view
  const Allowstreams_view =  Permissionsv.streams_view
  const Allowschools_view =  Permissionsv.schools_view
  const Allowschools_add =  Permissionsv.schools_add
  const Allowschools_edit =  Permissionsv.schools_edit
  const Allowschools_delete =  Permissionsv.schools_delete
  const Allowsubjects_view =  Permissionsv.subjects_view
  const Allowsubjects_add =  Permissionsv.subjects_add
  const Allowsubjects_edit =  Permissionsv.subjects_edit
  const Allowsubjects_delete =  Permissionsv.subjects_delete
    const Allowclients_view =  Permissionsv.clients_view

  const Allowrolesview =  Permissionsv.roles_view

 

  const Allowchallenges_view =  Permissionsv.challenges_view
  const Allowchallenges_add =  Permissionsv.challenges_add
  const Allowchallenges_edit =  Permissionsv.challenges_edit
  const Allowchallenges_delete =  Permissionsv.challenges_delete


 export{
    Allowrolesview,
      Allowdashboard_view ,
   Allowsettings_view ,
   Allowsettings_edit ,
   Allowusers_view ,
   Allowusers_add ,
   Allowusers_edit ,
   Allowusers_delete ,
   Allowcms_view ,
   Allowcms_edit ,
   Allowemailtemplates_view ,
   Allowemailtemplates_edit ,
   Allowposts_view ,
   Allowposts_edit ,
   Allowads_view ,
   Allowads_edit ,
   Alloworders_view ,
   Alloworders_edit ,
   Allowenquiries_view ,
   Allowstreams_view ,
   Allowschools_view ,
   Allowschools_add ,
   Allowschools_edit ,
   Allowschools_delete ,
   Allowsubjects_view ,
   Allowsubjects_add ,
   Allowsubjects_edit ,
   Allowsubjects_delete  ,
   Allowclients_view,
     Allowchallenges_view ,
   Allowchallenges_add ,
   Allowchallenges_edit ,
   Allowchallenges_delete 
 }
  
//export {    dahboardactive};