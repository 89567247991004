import React, {useEffect, useState } from "react";
  import {convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,checkpermissionredirect} from './../../Api.js';
  import {    
   Allowstreams_view
 } from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

 
 
 

 const [streamsList, setstreamsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(streamsList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetStreams()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetStreams()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetStreams(value)  
          
               
            checkpermissionredirect(Allowstreams_view)

            
           

        }  

  const GetStreams = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage, admin:1};

        const response = await fetch(Url+"getstreams", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setstreamsList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };
  

  const convertTimeStampval =   (timestampVal) => {

 
var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
var date = new Date(timestampVal*1000);
var year = date.getFullYear();
var month = months_arr[date.getMonth()];
var day = date.getDate();
var hours = date.getHours();
var minutes = "0" + date.getMinutes();
var seconds = "0" + date.getSeconds();
var fulldate = month+' '+day+', '+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
var convdataTime = month+' '+day;
 return fulldate;

  
}


  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = streamsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(streamsList.length / recordsPerPage)



//pagination part
  
const updateEntriescount = async() => {
  var postdata={ type:'streams'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}


          useEffect(() => {
   GetStreams();
       updateEntriescount()

  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Streams List" />

         <Sidebar activePage="Streams" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Streams"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Streams</span>
             </div>
            <div className="card-body">

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Topic</th>
                            <th>Caption</th>
                            <th>Meeting Link</th>

                            <th>Streaming Date & Time</th>
                    
                        </tr>
                    </thead>
                      {(streamsList.length > 0) ?
                    <tbody>
                       {streamsList.map((stream) => (
                        <tr>
                            <td>{stream.username}</td>

                                                        <td>{stream.topic}</td>


                            <td>{stream.caption}</td>

                           <td> <a target="_blank" href={stream.meetinglink} >{stream.meetinglink}</a> </td>

                            <td>  { 1 == 1 ?  ( (stream.startdatetime))  : ''  }   </td>
                          
                          
                        </tr>
                        
                           ))}
                        
                    </tbody> :<tbody><tr> <td colspan="5" align="center">No Streams Found</td></tr></tbody>}
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;