import React, { useState , useEffect} from "react";

 import {
    Link 
} from "react-router-dom";
import {SiteLogo,Socketiourl, SiteLogoDark,AkuserId,Url,SetNotificationAfterName,Notifyalert , userProfileurl, Akusername} from '../Frontapi.js';

 
 
 import {Socketcall} from '../../socketcall/Socketcall.js';


 const Commentspopup = ({viewpostid}) => {


const [topic, setTopic]=useState();
const [caption, setCaption]=useState();
const [comments, setcomments]=useState([]);
const [comment, setcomment]=useState();
const [commenterrormessage, setcommenterrormessage]=useState('');

const [Cusername, setCusername]=useState();
 const [Lcomid, setLcomid]=useState();

 const [Recentaddcomment, setRecentaddcomment]=useState(0);

 const [submitbutton, showSubmitbutton]=useState(1);


const SetCommentSubmit  =async(e) =>{

  if(e.keyCode === 13){

AddComment(e)

}
}


const AddComment =async(e) =>{


    
  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });


            setcommenterrormessage('')
               if(  typeof comment != 'undefined' && comment != '' && (  comment.includes('http') !== false ||   comment.includes('www') !== false ) ){

                    setcommenterrormessage('Invalid Comment')
                    error =1;
               }

            
             /***Check Required***/

            if(error == 1){
              return false
            }
            showSubmitbutton(0)

  var postdata={postid:viewpostid,'userid':AkuserId, comment:comment};

Socketcall('on', 'joinuser',{token:Cusername})
Socketcall('emit', 'newcomment',{token:Cusername,  from:Akusername})

 

        const response = await fetch(Url+"add-comments", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



 
                setcomment('')
 
                Getpostdetails();

                setRecentaddcomment(1)

                  showSubmitbutton(1)

              } else{
                 
          }

           
       })

}



const Getpostdetails =async() =>{

 
 


 var postdata={_id:viewpostid, checkuserid:AkuserId,getcomments:1}

         const response = await fetch(Url+"get-post", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var postdata= data.data;

          

                   setTopic(postdata.topic)
                  setCaption(postdata.caption)
                  setcomments(postdata.comments.data)

                  setCusername(postdata.username)

                  setLcomid(postdata.lcomide)

                  var commentsdata=postdata.comments.data;

 
                  if(commentsdata != null){

 
           


setTimeout(function(){
     setcomments(commentsdata);
},1000)
             


                  }

 


                 

             //Notifyalert('success', data.message)
            // document.getElementById("NewPostForm").reset()
                             
              

          } else{
               
          }

           
       })
 
  

}


const deleteComment = async (id) => {

    if(window.confirm("Are you sure?")){
        var postdata={'id' : id , userid:AkuserId };
        const response = await fetch(Url+"deletecomment", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
                   

          if (data.success) { 
               
            Notifyalert('success', data.message)
            Getpostdetails();

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
                 
          }

           
        })

    }

     


  };



   useEffect(() => {
    Getpostdetails();

  
      setTimeout(function(){
 //document.getElementById('commentlistmodal').style.display='block'
},200)

    },[] );



return (


 
               <div className="comment-modal-info"  >
      {/*

       <p className="comment-post-topic">{topic}</p>


       <p className="comment-post-caption">{caption}</p>
*/ }
        

            <div className="post-comments-title">Comment</div>


            {/**post comment section**/}

                     <div className="post-comments-section">
                       {comments.map((commentsingle) => (

                     <div className="post-comments-single">

                       
                      <div className="post-comment-single-user-img" > 
                        <Link to={userProfileurl+commentsingle.username}> <img src={commentsingle.userprofilepicture } className="post-comment-user-img" /> </Link>

                       <p className="post-comment-user-name" >  <Link to={userProfileurl+commentsingle.username}>  {commentsingle.username}</Link> </p>

                      </div>
                                            <div className="post-comment-single-user-info" >

                                              
                                              <p>{ (Lcomid == commentsingle._id && Recentaddcomment == 1) ?  <b>{commentsingle.comments}</b> : commentsingle.comments    }  

                                              {(Akusername == commentsingle.username ) ?
                                              <span onClick={(value)=> deleteComment(commentsingle._id)} className="delete-comment"><i className="fa fa-trash-o"></i></span>  : ''}


                                               </p>

                                             </div>


                          


                           

                     </div>
                      ))}


                     </div>

                       {/**post comment section**/}

                     { (submitbutton == 1) ? 
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12     add-comment-section" >
                     
                     <input onKeyUp={(e) => SetCommentSubmit(e)} value={comment}   onChange={(e) => setcomment(e.target.value)} name="comment" className="add-comment-input MustEnterField" placeholder="Add a comment" />

                     <span className="add-comment-submit" onClick={AddComment} >Post</span>
                     
                   
                      </div>

                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12">
                      <span class="error-msg">{commenterrormessage}</span>
                      </div>

                       </div>
                       : ' '}
 

            </div>



	)













 }
export default Commentspopup;