import React, { useState , useEffect} from "react";

import {SiteLogo,SiteLogoDark,Redirectdashboard} from '../Frontapi.js';

import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";
 


 const Share = ({shareurl,redirect}) => {


const redirectAfter  =async() =>{

  if(redirect == 1){

     Redirectdashboard();
  }

 }



return (


 
               <div className="share-modal-options">

               <h2 className="share-head-title">Share on  </h2>

            <div className="social-share-single">
            <EmailShareButton url={shareurl}     ><span className="social-share-btn btn-mail" >  <i className="fa fa-envelope"> </i>   </span> </EmailShareButton >
            </div>

      {/*       <div className="social-share-single">
       <a href={'https://wa.me/send?text='+ encodeURI(shareurl)} onClick={redirectAfter} target="_blank"><button>   <span className="social-share-btn btn-wt" >  <i className="fa fa-whatsapp"> </i>   </span>  </button></a>
            </div> */ }
            
 
              <div className="social-share-single">
              
            <WhatsappShareButton url={shareurl}  onClick={redirectAfter} >  <span className="social-share-btn btn-wt"   >  <i className="fa fa-whatsapp"  > </i>    </span> </WhatsappShareButton >
            </div>
           
            <div className="social-share-single">
          <FacebookShareButton url={shareurl} onClick={redirectAfter}   > <span className="social-share-btn btn-fb" >  <i className="fa fa-facebook"> </i>   </span> </FacebookShareButton >
            </div>
            <div className="social-share-single">
            <LinkedinShareButton url={shareurl} onClick={redirectAfter}   >  <span className="social-share-btn btn-li" >  <i className="fa fa-linkedin"> </i>   </span> </LinkedinShareButton >
            </div>
        
           
          
            </div>



	)













 }
export default Share;