import React from "react";

 
import io from 'socket.io-client';

import {Url,AkuserId} from '../frontend/Frontapi'


const GetUrl = async(param) => { 

if(window.location.hostname === 'localhost'){

    return   'http://localhost:5030/' ;

}else{
	return  'https://wso.alfakidz.com/' ;

}

}

const socketUrl=  await GetUrl()
 const socket = io.connect(socketUrl);


	const Socketcall  =async(type,method,data)=>{
 
			if(type == 'on'){
			socket.on(method,data);

			}

			if(type == 'emit'){
			socket.emit(method,data)
			}



	}

 	const RecieveSocketcall  =async()=>{

 		  
 		  return 1;


 	}

 	const AllowSocketcall  =async()=>{

 

  return 1;


 	}


export { Socketcall,RecieveSocketcall,AllowSocketcall,socketUrl}