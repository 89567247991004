import React, { useState, useEffect } from "react";
 import { Link, useNavigate } from "react-router-dom";
import { loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha} from 'react-simple-captcha';


import {AkuserId, Akusertype, checkRedirectAfterLogin,RemoveNotificationAfterName, SetNotificationAfterName,SiteLogo, frontUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Notifyalert} from '../Frontapi.js';
import Header from '../includes/Header';
 import Footer from '../includes/Footer';

const Login = () => {

 

const navigate = useNavigate();
 
const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [emailError, setEmailerror] = useState(false);
const [emailvalid, setEmailvalid] = useState("");
const [passwordvalid, setPasswordvalid] = useState("");
const [formValid, setformValid] = useState(true);
const [validationSubmit, setvalidationSubmit] = useState(false);
const [submitLoading, setsubmitLoading] = useState(false);




const [squarevalue, setSquarevalue] = useState(0);
const [option1, setOption1] = useState(0);
const [option2, setOption2] = useState(0);
const [option3, setOption3] = useState(0);
const [correctanswer, setCorrectanswer] = useState('');
const [userselectedanswer, setUserselectedanswer] = useState('');
const [captchaerror, setCaptchaerror] = useState('');



const [ showCaptchaSection , setshowCaptchaSection] = useState(0);

const [ showAgreementSection , setshowAgreementSection] = useState(0);

const [ showFormSection, setshowFormSection] = useState(1);





const setCaptcha=()=>{


var randomValue = Math.floor(Math.random() * ((50 - 20) + 1) + 20);

var wrongValue1= randomValue+10;
var wrongValue2= randomValue+20;

setCorrectanswer(randomValue)
setSquarevalue(randomValue * randomValue)

 
  var lastdigit=(randomValue * randomValue) % 10;

if(lastdigit == 1 || lastdigit == 3 || lastdigit == 7){
setOption1(randomValue)
setOption2(wrongValue1)
setOption3(wrongValue2)

}else if(lastdigit == 5 || lastdigit == 8 || lastdigit == 9){
setOption1(randomValue)
setOption2(wrongValue2)
setOption3(wrongValue1)

}else if(lastdigit == 2 || lastdigit == 4 || lastdigit == 6){

setOption1(wrongValue1)
setOption2(wrongValue2)
setOption3(randomValue)
}else{
setOption1(wrongValue2)
setOption2(randomValue)
setOption3(wrongValue1)

}



}



const [captchaOption1,setcaptchaOption1]= useState('')
const [captchaOption2,setcaptchaOption2]= useState('')

const [captchaOption3,setcaptchaOption3]= useState('')
const setUserAnswer = (useranswervalue,option) =>{

    setUserselectedanswer(useranswervalue)
    setcaptchaOption1('')
    setcaptchaOption2('')
    setcaptchaOption3('')

    if(option == 1){
         setcaptchaOption1('captchaOption')

    }
    if(option == 2){
         setcaptchaOption2('captchaOption')

    }
    if(option == 3){
         setcaptchaOption3('captchaOption')

    }

}



const [ userdataid, setUserdataid] = useState('');
const [ userdatausertype, setUserdatausertype] = useState('');
const [ userdatausername, setUserdatausername] = useState('');



const ValidateSignIn =()=>{
setcaptchaOption1('')
setcaptchaOption2('')
setcaptchaOption3('')
setCaptchaerror('')

if(correctanswer == userselectedanswer){


    localStorage.setItem('AKuserId', userdataid)
    localStorage.setItem('AKusertype', userdatausertype)
    localStorage.setItem('AKusername', userdatausername)
    //Notifyalert('success',data.message)
    Redirectdashboard_Terms(userdataid,userdatausertype)

}else{
setCaptchaerror('Invalid Answer')
setCaptcha()
}



}

const toggleAgreementsection =async()=>{

    setshowFormSection(0)
    setshowCaptchaSection(0)

    setshowAgreementSection(1)
}
const toggleCaptchsection =async()=>{

    setshowFormSection(0)
    setshowAgreementSection(0)

    setshowCaptchaSection(1)
}



const showhidepassword = (inputid) =>{
                var inputtype=  document.getElementById(inputid+"input").attributes.getNamedItem('type').value;
                var iconelemenent=  document.getElementById(inputid+"icon");
                if(inputtype == 'text'){
                document.getElementById(inputid+"input").setAttribute('type','password');
                iconelemenent.classList.remove('fa-eye');
                iconelemenent.classList.add('fa-eye-slash');
                }else{
                document.getElementById(inputid+"input").setAttribute('type','text');
                iconelemenent.classList.add('fa-eye');
                iconelemenent.classList.remove('fa-eye-slash');
                }
            }




const handleUserInput = (e) => {

  RemoveNotificationAfterName();

  if(e.keyCode === 13){
             signIn(e);
         }

     var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
           // setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
        case 'password':
            setPassword(value)
           // setPasswordvalid(true);
            break;
    }
  //  (email != '' && password != '') ? setformValid(true): setformValid(false)
}



const setUserPasteInput = (e) => {

  RemoveNotificationAfterName();

  if(e.keyCode === 13){
             signIn(e);
         }

     var name = e.target.name;
    var value = e.clipboardData.getData('text/plain');

 

 
    switch (name) {
        case 'email':
            setEmail(value);
           // setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
        case 'password':
            setPassword(value)
           // setPasswordvalid(true);
            break;
    }
  //  (email != '' && password != '') ? setformValid(true): setformValid(false)
}






const getUseragreementcounts = async (AkuserIdVal) => {

    var userid = AkuserId;
    if (typeof AkuserIdVal != 'undefined' && AkuserIdVal != '' && AkuserIdVal != null) {
        userid = AkuserIdVal;
    }




    if (typeof userid != 'undefined' && userid != '' && userid != null) {
        var postdata = {
            userid: userid
        };
        var getUseragreement = await fetch(Url + "getuseragreement", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then(async (response) => response.json())
            .then(async (data) => {

                var agreedCounts = 0;

                if (data.success && data.data !== null) {
                    var userdata = data.data;


                    if (typeof userdata.postcontent != 'undefined' && userdata.postcontent != '0' && userdata.postcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.youtubepostcontent != 'undefined' && userdata.youtubepostcontent != '0' && userdata.youtubepostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.adultpostcontent != 'undefined' && userdata.adultpostcontent != '0' && userdata.adultpostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.afterpostcontent != 'undefined' && userdata.afterpostcontent != '0' && userdata.afterpostcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.kidscontent != 'undefined' && userdata.kidscontent != '0' && userdata.kidscontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.advcontent != 'undefined' && userdata.advcontent != '0' && userdata.advcontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }
                    if (typeof userdata.invitecontent != 'undefined' && userdata.invitecontent != '0' && userdata.invitecontent != '') {

                        agreedCounts = agreedCounts + 1;

                    }



                    return agreedCounts;

                } else {
                    return agreedCounts;

                }
            })


        return getUseragreement;
    }
}


const Redirectdashboard_Terms = async (AkuserIdVal, Akusertypeval) => {

    var counts = await getUseragreementcounts(AkuserIdVal);

    if (AkuserIdVal !== '' && AkuserIdVal !== null) {




        if (parseInt(Akusertypeval) === parseInt(1)) {


            if (parseInt(counts) === parseInt(7)) {

                localStorage.setItem('AKcountsupdated', 'updated')

                navigate("/dashboard")

            } else {

                navigate("/terms-agreement")




            }
        }
        if (parseInt(Akusertypeval) === parseInt(2)) {

            if (parseInt(counts) === parseInt(6)) {
                localStorage.setItem('AKcountsupdated', 'updated')


                navigate("/dashboard")

            } else {

                navigate("/terms-agreement-student")

            }
        }



    }

}
const signIn = async (e) => {
    e.preventDefault();

   // setsubmitLoading(true)
      if(checkEmptyUndefined(email) === false  || checkEmptyUndefined(password) === false){
     
    }

    /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         //el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                      //   el.classList.remove('mb-25');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
            if(  error==1){

             return false;
            }
             if(validEmailRegex.test(email) == false){
                

                // SetNotificationAfterName('email','Invalid Email')
             //   return false
            }
            
            /***Check Required***/

    setvalidationSubmit(true)
    var postdata = {
        email: email,
        password: password
    };
    try {
        const response = await fetch(Url + "userlogin", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    var userdata = data.data;


                    

                      setUserdataid(userdata._id)
                      setUserdatausertype(userdata.usertype)
                    setUserdatausername(userdata.username)

                    toggleAgreementsection()

                    
                } else {
                    Notifyalert('error',data.message)
                  
                }
            })
              setsubmitLoading(false)
    } catch (error) {
        
    }
};
 
  useEffect(() => {
           
      
setCaptcha();
      Redirectdashboard_Terms(AkuserId, Akusertype);
  }, []);


  return (

<div>
   <Header pageInfo="Login" />
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section">
         {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 
         <div className="page-content">
            <div className="login-container">





             {(showFormSection == 1)?   

               <div className="login-section">
                  <h1 className="login-title">Login</h1>
                  <input autoComplete="off"  type="text" name="email" className="form-control login-input mb-25 MustEnterField"   placeholder="Email / Username*"  
                     onKeyUp={(e) => handleUserInput(e)} onPaste={(e) => setUserPasteInput(e)} 
                  required/>
                  <div className="password-input-box">               
                     <input id="togglepasswordinput"   autoComplete="off"   className="form-control login-input mb-10 MustEnterField"   type="password" name="password" placeholder="Enter password*"  
                        onKeyUp={(e) => handleUserInput(e)} onPaste={(e) => setUserPasteInput(e)} 
                     required/>
                     <i id="togglepasswordicon" onClick={(inputname) =>showhidepassword('togglepassword')}  className=" fa fa-eye-slash"></i>
                  </div>
                  <p className="forgot-password-inst"  >
                     <Link to={frontUrl+'forgot-password'} >
                     Forgot password?</Link> 
                  </p>
                   
                  <button className="login-button"  onClick={signIn}>Login</button>     
                  <p className="login-signup-inst"  >
                     <Link to={frontUrl+'?direct'} >
                     Don't have an account?  <span> Register </span></Link> 
                  </p>
               </div> :''}


               {(showAgreementSection == 1)? 

                <div className="login-section text-left text-white">
                <h3 className="text-white">
                Dear Parent,
                </h3>
                <p>We are excited to introduce the Alfa Kidz mobile app, where your child can showcase their skills and talents to a wider audience. To ensure your child's safety and well-being, we require your consent to use the app and share their content publicly.</p>
                <h3 className="text-white">
                Parent Consent Form
                </h3>
                <p>I as a parent, hereby grant permission for my child, to use the Alfa Kidz mobile app and publish their content.</p>
                <h3 className="text-white">
                I understand that
                </h3>
                <ul>
                <li>Content will be shared publicly and may be viewed, shared, and commented on by others. </li>
                <li>Limited personal data will be collected to create a safe environment. </li>
                <li>I am responsible for monitoring their activity and ensuring their safety while using the app. </li>
                </ul>
                <h3 className="text-white">
                I consent to:
                </h3>
                <ul>
                <li>Data collection and storage</li>
                <li> Content publication on the app and social media</li>
                <li>Use of my child's name, image, and likeness for promotion</li>
                </ul>

                 <button className="login-button"  onClick={toggleCaptchsection}>I give my Consent</button>    


                </div>     : ''}


                {(showCaptchaSection == 1)?

                 <div className="login-section text-white text-left">
                 <p className="mb-50">Once the parent answers the question correctly, child can proceed to use the application</p>

                 <h2 className="text-white text-center mb-50"> Square root of</h2>


                 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-12   ">
                 <h4 className="text-white text-center mb-50">{squarevalue}</h4>

                 </div>



                 <h3 className="text-center text-white  mb-50">Select your answer to proceed </h3>


                 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-12  mb-50 row">
                 <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-xs-4">

                   <h4 className="text-white text-center mb-50" onClick={ (useranswervalue,option)=> setUserAnswer(option1,'1') }><span className={captchaOption1+' cur-poi'}> {option1}</span></h4>
                 </div>
                 <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-xs-4">

                    <h4 className="text-white text-center mb-50" onClick={ (useranswervalue,option)=> setUserAnswer(option2,'2') }><span className={captchaOption2+' cur-poi'}> {option2}</span></h4>
                 </div>
                 <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-xs-4">

                   <h4 className="text-white text-center mb-50" onClick={ (useranswervalue,option)=> setUserAnswer(option3,'3') }> <span className={captchaOption3+' cur-poi'}> {option3}</span></h4>
                 </div>



                 </div>



                 <button className="login-button mb-10"  onClick={ValidateSignIn}>Submit</button>   

                 <p className="error-msg text-center">{captchaerror}</p>

                 </div>


                 : ''}





            </div>
         </div>
         : ''}
         <Footer />
      </div>
   </div>
</div>

 
 
  );
};
export default Login;