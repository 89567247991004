import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined} from './../Api.js';

 
 import {    
    Allowsettings_edit
} from './../Permissions.js';

 

const Social = () => {

  
const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [facebook, setFacebook] = useState("");
   const [instagram, setInstagram] = useState("");
 const [youtube, setYoutube] = useState("");
const [linkedin, setLinkedin] = useState("");
  const [pinterest, setPinterest] = useState("");
           const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setFacebook(settingsdata.facebook );
             setInstagram(settingsdata.instagram );
             setYoutube(settingsdata.youtube );
             setLinkedin(settingsdata.linkedin );
            setPinterest(settingsdata.pinterest );

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updatesocialsettings  = async (e) => {
    e.preventDefault();
   setnotification("");
     setsubmitLoading(true)
     if(checkEmptyUndefined(facebook) === false || checkEmptyUndefined(instagram) === false|| checkEmptyUndefined(youtube) === false|| checkEmptyUndefined(linkedin) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={facebook:facebook,instagram:instagram,youtube:youtube,linkedin:linkedin ,pinterest:pinterest  };
 
       const response = await fetch(Url+"update-social-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
           if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           
       })
 
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {


        case 'facebook':

              setFacebook(value);
             break;
         case 'instagram':

              setInstagram(value);
             break;
             case 'youtube':

              setYoutube(value);
             break;
             case 'linkedin':

              setLinkedin(value);
             break;
              case 'pinterest':

              setPinterest(value);
             break;
              default :
              break;
    }


 }
   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Facebook<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="facebook" placeholder="Facebook" value={facebook} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                  <div className="col-md-4">
                                    <label htmlFor="companyname">Instagram<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="instagram" placeholder="Instagram" value={instagram} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Youtube<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="youtube" placeholder="Youtube" value={youtube} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Linkedin<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="linkedin" placeholder="Linkedin" value={linkedin} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                 <div className="col-md-4">
                                    <label htmlFor="companyname">Pinterest<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="pinterest" placeholder="Pinterest" value={pinterest} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                  {(Allowsettings_edit == 1) ? 
                                 <div className="col-sm-12 d-flex justify-content-end">
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatesocialsettings}>Submit</button>
                               </div> : '' }

                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Social;