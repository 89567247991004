import React, { useState , useEffect} from "react";
 import {
    Link 
} from "react-router-dom";
 
import {userProfileurl,Akusername,frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Socketiourl} from '../Frontapi.js';
 
  import {Socketcall} from '../../socketcall/Socketcall.js';


 const Suggestion = ({nobg}) => {

 const [suggestionlist,setsuggestionlist]=useState([])

 


 const Getsuggestions =  async () => {
  var postdata={ 'userid':AkuserId  };


        const response = await fetch(Url+"get-suggestions", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
 

 var suggestionlist=data.data;

           


            setsuggestionlist(suggestionlist)

                } else{
                 
          }


           
       })


}
  

  



const updateFollower=async(e,username)=>{





  var followed = e.target.attributes.getNamedItem('data-update-followed').value;
if(parseInt(followed)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-followed',1);
      

      e.target.innerHTML ='Following'

 


Socketcall('on', 'joinuser',{token:username})
Socketcall('emit', 'newfollow',{token:username,  from:Akusername})




}else{
    e.target.setAttribute('data-update-followed',0);
      e.target.innerHTML ='Follow'

}



var postdata={following: username, follower:AkuserId}
        const response = await fetch(Url + "update-follower", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success  ) {
                
   //   Notifyalert('success', data.message)

                        

                }  
            })



}


  useEffect(() => {
 Getsuggestions();
  
    },[] );

 
return ( 
<div>
 { (suggestionlist.length>0) ? 
<div  className={(nobg == 1)? "suggestion-section mb-25"  : "suggestion-section mb-25 bg-card-sidebar"     }  >

                

   <h3 className="mb-25">Suggested for you</h3>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row suggestion-wrapper  ">
      <div>
         {suggestionlist.map((suggestionsingle) => (
         <div className="col-md-12 col-lg-12 col-sm-12 row suggestion-single">
            <div className="col-md-3 col-lg-2 col-sm-2 col-3">
               <div className="suggestion-img-section">  <Link to={userProfileurl+suggestionsingle.username } > <img src={suggestionsingle.profilepicture} className="" />  </Link> </div>
            </div>
            <div className="col-md-6 col-lg-7 col-sm-3 col-7">
               <p>
                  <Link to={userProfileurl+suggestionsingle.username } >
                  {suggestionsingle.username}</Link> 
               </p>
                </div>
            <div className="col-md-3 col-lg-2 col-sm-3 col-2">
               <p>
                  <span className="suggestion-follow-btn" data-update-followed="0" onClick={(e,username)=>updateFollower(e,suggestionsingle.username)}   >
                 Follow</span>
               </p>
            </div>
         </div>
         ))}
      </div>
   </div> 


</div>: ''
} 
</div>
	)













 }
export default Suggestion;