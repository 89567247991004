import React, { useState ,useEffect} from "react";
   import {
    Link,
    useNavigate
} from "react-router-dom";

 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

 

  import {userIcon ,streamingIcon,angleLeft} from '../ImagesIcons.js';



import {showLoader,frontUrl, SiteLogo,SiteLogoDark,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';


 



 
const Mystreams = () => {

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const username = UrlSplitter(2);

const [Userprofilepicture] = useState({'preview':SiteLogoDark});


  const [streamsList, setstreamsList] = useState([]);
const [currentPage, setCurrentPage] = useState(1);
const [recordsPerPage,setrecordsPerPage] = useState(10);

 
 const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')



const GetStreams = async (value ) => {
showLoader(true);

 
 
 

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

   value = (typeof value !== 'undefined') ? value : recordsPerPage;

      var postdata={'currentuser':1,'perpage':recordsPerPage ,'checkuserid':AkuserId};

        const response = await fetch(Url+"getstreams", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

if(data.data.length == 0){
   setcheckrecordsLength('No Streams found')

   setstreamsList([]);
   return false
}


var postdatavalues= data.data;
 
  
  
   
/*

var difference = (new Date().getTime() / 1000 ) - postdatavalues[key].createddatetime;
var minutesDifference = Math.floor(difference/60);
var hoursDifference = Math.floor(difference/3600 );
var daysDifference = Math.floor(difference /86400 );
var  MonthDifference = Math.floor(difference /2620800 );
var  YearDifference = Math.floor(difference /31449600  );

if(parseInt(YearDifference) > 0 ){
  postdatavalues[key].timediff=YearDifference +' years ago';

 }else if(parseInt(MonthDifference) > 0 ){
postdatavalues[key].timediff=MonthDifference +' months ago';
}else if(parseInt(daysDifference) > 0 ){
postdatavalues[key].timediff=daysDifference +' days ago';
}else if(parseInt(hoursDifference) > 0 ){
postdatavalues[key].timediff=hoursDifference +' hours ago';
}else if(parseInt(minutesDifference) > 0 ){
postdatavalues[key].timediff=minutesDifference +' minutes ago';
}
*/
    setstreamsList(postdatavalues);

 


 

 

   
 

    
          } else{
                 
          }

           
       })


 
  };



 const DeleteStream = async (id) => {

    if(window.confirm("Are you sure?")){
        var postdata={'id' : id , userid:AkuserId };
        const response = await fetch(Url+"deletestream", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
                   

          if (data.success) { 
               
            Notifyalert('success', data.message)
            GetStreams();

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
                 
          }

           
        })

    }

     


  };





    useEffect(() => {
    GetStreams();

  
    }, [recordsPerPage]);



 
 
  return (
<div>
   <Header pageInfo="My Streams" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar   />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > 
                     <span className="goto-section" ><Link to='/livestreams' > <img src={angleLeft} />  </Link>  </span> My Streams
                   </h1>
               </div>
               <div className="live-info-tabs" >
                  <ul className="bg-transparent">
                     <li    >  </li>
                      


                      <li className="add-stream-option"   > <Link to='/add-stream' className="add-stream-option-btn" >Add Stream</Link>  </li>

                  </ul>
               </div>

               { (streamsList.length==0) ? <div className="no-records"> {checkrecordsLength} </div>  :
               <div className="stream-posts">
             {streamsList.map((stream) => (
                  <div className="stream-post-single">
                     <div className="stream-post-user-info col-12"  >
                        <span className="stream-post-user-name"><div className="stream-post-user-img-sec"> 
                         <img src={stream.userprofilepicture} />   <span>{stream.username}</span> </div>  <span className="stream-info-datetime"> <small> {stream.startdatetime}  </small> </span> </span>
                        <span className="stream-post-user-type stream-option-btn-flex">

                         <Link  to={frontUrl+'edit-stream/'+ stream._id} className="stream-option-btn" >Edit</Link>

                         <a className="stream-option-btn"  onClick={() => DeleteStream(stream._id)}   >Delete</a>   </span>
                     </div>
                     <div className="stream-post-info col-12"  >
                         {stream.topic}  <br/>

                         {stream.caption} <br/>


                         <a className="stream-meeting-link" href={stream.meetinglink} target="_blank">{stream.meetinglink}</a>
                     </div>
                  </div>
                 ))}
              </div>
           }


            </div>
         </div>
      </div>
   </div>
</div>
  );
};
export default Mystreams;