import React, {
    useEffect,
    useState
} from "react";
import {
    convertTimeStamp,
    baseUrl,
    Url,
    ModalPopupDelete,
    UpdateNotify,
    UrlSplitter
} from './../../Api.js';

import { Getpostimage,Getuserimage } from '../../../frontend/GetDynamicImages.js';

import {
    Link
} from 'react-router-dom';
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";
//import Pagination from "../../includes/Pagination";
const Listcomments = () => {
        const [id] = useState(UrlSplitter(3));
        const [comments, setcomments] = useState([]);
        const [topic, setTopic] = useState();
        const [caption, setCaption] = useState();

        const Getpostdetails = async () => {
            var postdata = {
                _id: id, getcomments: 1
            }
            const response = await fetch(Url + "get-post", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        var postdata = data.data;
                        setTopic(postdata.topic)
                        setCaption(postdata.caption)
                       // setcomments(postdata.comments.data)
                        var commentsdata = postdata.comments.data;
                        if (commentsdata != null) {

                            Object.keys(commentsdata).forEach(function(key) {
                                var image = '';
            (async function() { 

                                    image = await Getuserimage( commentsdata[key].userprofilepicture)    

                                commentsdata[key].userprofilepicture = image;
                                  setcomments(commentsdata);
                            
                                 })()
                            });
                          
                              
                        }
                        //Notifyalert('success', data.message)
                        // document.getElementById("NewPostForm").reset()
                    } else {}
                })
        }


         const DeleteComment = async (id) => {


                if(window.confirm("Are you sure?")){
                var postdata={'id' : id ,  admin:1 };
                const response = await fetch(Url+"deletecomment", {
                method: "POST",
                headers: {
                "Content-Type": "application/json",
                } ,
                body: JSON.stringify({postdata }),

                }).then((response) => response.json())
                .then((data) => { 


                if (data.success) { 

                UpdateNotify(data.message, 'success')
                Getpostdetails();

                // setnotificationtype('success')
                //        setnotification(data.message)
                } else{

                }


                })

                }



         }


        useEffect(() => {
            Getpostdetails();
        }, []);

  return (
      <div id="app">
            <Header title="Comments" />

         <Sidebar activePage="Comments" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Comments"/>
   <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">


            <div className="card-header">
            <Link to={-1}> <i className="fa fa-angle-left"></i> Back to Listing</Link><br/><br/>

              <p className="comment-post-topic">{topic}</p>


       <p className="comment-post-caption">{caption}</p>

               <span>Comments</span>
             </div>
            <div className="card-body">
 

     
    <div className="ad-post-comments-section">
             {comments.map((commentsingle) => (

                     <div className="post-comments-single">

                       
                      <div className="post-comment-single-user-img" > 
                       <img src={commentsingle.userprofilepicture } className="post-comment-user-img" /> 

                       <p className="post-comment-user-name" >{commentsingle.username} </p>

                      </div>
                                            <div className="post-comment-single-user-info" >

                                              
                                              <p>{commentsingle.comments}  

                                       <span onClick={(value)=> DeleteComment(commentsingle._id)} className="delete-comment"><i className="fa fa-trash-o"></i></span>   


                                               </p>

                                             </div>


                          


                           

                     </div>
                      ))}
</div>






   </div>
        </div>
 

    </section>


   </div>
        </div>
 
                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};


export default Listcomments;