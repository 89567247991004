import React, { useState } from "react";
 
import {baseUrl,Url,CommonNotify,OwnerName,CurrentYear} from './../Api.js';
 
 
  
 
 
const Footer = () => {
 

   
  
  return (

    <div  >


          
            <footer>
                <div className="footer clearfix mb-0 text-muted">
                    <div className="float-start">
                        <p>{CurrentYear} &copy; {OwnerName} </p>
                    </div>
                     
                </div>
            </footer>

 

     </div>
 
  );
};
export default Footer;