import React, {useEffect, useState } from "react";
  import {baseUrl,Url,ModalPopupDelete,DeleteNotify,UpdateNotify,checkpermissionredirect} from './../../Api.js';


import {    
   Allowchallenges_view ,
   Allowchallenges_add ,
   Allowchallenges_edit ,
   Allowchallenges_delete
} from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Challengelist = () => {

 
 
 

 const [challengesList, setchallengesList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(challengesList.length / recordsPerPage)

 var [s_no]=useState(1)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetChallenges()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetChallenges()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetChallenges(value)  
          
               
         
            
           

        }  


     const SetCurrentChallenge = async (id ) => {

         if(window.confirm("Are you sure you want to set as current challenge?")){
        var postdata={'id' : id };
        const response = await fetch(Url+"setcurrentchallenge", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            UpdateNotify(data.message)
               GetChallenges();

          }  
           
        })

    }


    }     

  const GetChallenges = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage, admin:1};

        const response = await fetch(Url+"getchallenges", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setchallengesList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };


  const DeleteConfirm = async (id) => {
     
    if(window.confirm("Are you sure?")){
        var postdata={'id' : id };
        const response = await fetch(Url+"deletechallenge", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            DeleteNotify()
                          

          } else{
                DeleteNotify(data.message);
          }

           
        })

    }

     


  };



  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = challengesList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(challengesList.length / recordsPerPage)



//pagination part
  


          useEffect(() => {

                checkpermissionredirect(Allowchallenges_view)



   GetChallenges();
  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Challenges List" />

         <Sidebar activePage="Challenges"  activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Challenges"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
                <span>Challenges</span>
                <div className="pull-right"><a href={baseUrl+'challenges/add'}  className="btn btn-success me-1 mb-1">Add Challenge</a></div>
            </div>
            <div className="card-body">
            

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                        <th>S.No</th>
                            <th>Title</th>
                            <th>Prize</th>
                           
                            <th>Start Date and Time</th>
                            <th>End Date and Time</th>

                             <th>Status</th>
                             <th>Submissions</th>
                             <th>Winner</th>

                            <th>Actions</th>
                            <th>Set as Current</th>
                        </tr>
                    </thead>

                     {(challengesList.length > 0) ?
                    <tbody>
                       {challengesList.map((challenge) => (
                        <tr>
                         <td>{s_no++}</td>
                            <td>{challenge.title}</td>
                        
                            <td>{challenge.prize}</td>
                          <td>{challenge.startdatetime}</td>

                           <td>{challenge.enddatetime}</td>




                            <td>
                 {(challenge.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}

                            </td>
                            <td>  
                            { (challenge.submissionscount > 0  )  ?  <a className="cur-poi badge bg-success" href={baseUrl+'challengesubmissions/'+challenge._id} > <i className="fa fa-eye"> </i> View</a>  : '-' }

                             </td>
                            <td>{challenge.winner}</td>
                            <td  className="table-action-sections">
                              {(Allowchallenges_edit == 1) ?
                             <a href={baseUrl+'challenges/edit/'+challenge._id}  className="fa-edit-btn"  > <i className="fa fa-pencil"></i></a> : ''}

                             {(Allowchallenges_delete == 1) ?
 
                            <a    onClick={() => DeleteConfirm(challenge._id)}  className="fa-del-btn"    ><i className="fa fa-trash"></i> </a>  : ''}

                              </td>

                             <td>  
                             { (challenge.current == 1 )  ? ' Current ' : <a    onClick={() => SetCurrentChallenge(challenge._id)}  className="amigo-btn"    > Set </a>  }

                                 </td>
                        </tr>
                        
                           ))}
                        
                    </tbody> :<tbody><tr> <td colspan="10" align="center">No Challenges Found</td></tr></tbody>}
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>: ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Challengelist;