import React, { useState ,useEffect} from "react";
 
 import {
    Link,
    useNavigate
} from "react-router-dom";

  import {HeadBg,MidBg,FootBg,NewMidBg,FstLogo} from '../ImagesIcons.js';


 

// import Header from "../includes/Header";
// import Sidebar from "../includes/Sidebar";
// import Navbar from "../includes/Navbar";
// import Pagetitle from "../includes/Pagetitle";


// import Footer from "../includes/Footer";

import {frontUrl , Url,Banner,Vision,SiteLogo,redirectfrontPage,Redirectdashboard,Redirectlogin ,containerStart , containerEnd , AkuserId,AKcountsupdated} from '../Frontapi.js';



   import Signup from "../auth/Register"


   import Dashboard from "../pages/Dashboard"


 import Footer from "../includes/Footer";


 
const Home = () => {
  const navigate = useNavigate();

 const urlParams = new URLSearchParams(window.location.search);

const myParam = urlParams.get('referral');

   localStorage.setItem('AKreferralcode', myParam)
   

const [showSplash,setshowSplash]=useState(0)

const [showSignup,setshowSignup]=useState(0)

const [showDashboard,setshowDashboard]=useState(0)


const [offlemail,setOfflemail]=useState('');
const [offlphone,setOfflphone]=useState('');
const [offlmobile,setOfflmobile]=useState('');




 const Getsettings = async (e) => {
  
  try{


     var postdata={getdata:1};

     const response = await fetch(Url+"get-front-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            

            var settingsdata=data.data;
             setOfflemail(settingsdata.email );
      setOfflphone(settingsdata.phone );
      setOfflmobile(settingsdata.mobile );
 

            
         
          } else{
              //  ;
          }

           
       })



  } catch (error) {

    console.log(error)
      
  }

 } 





const Redirect = () => {

            

                 setTimeout(function() {
                    setshowSplash(0)

              setshowSignup(0)
             
              if((AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') ){
                setshowSignup(1)
              }

             
 
     }, 1000);
              
            
   }

const ProceedNext = () => {



  setshowSplash(0);

  if( (AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined')  )  {
setshowSignup(1)

  }   


  if(  (AkuserId !=  null && AKcountsupdated != null ) ) {   setshowDashboard(1) }  



}

   

  
       useEffect(() => {

   Getsettings();

 
 
   if((AkuserId !=  null && AKcountsupdated != null  )){
          setshowSplash(0)

                setshowDashboard(1)
              }else{

                if(window.location.search.substr(1)  == 'direct'){
  setshowSplash(0)
 setshowSignup(1)
 }else{
    setshowSplash(1)

 }

 
                //    Redirect();
              }

 
  
  }, []);
   

  return (

      <div id="app">



      <div className=" full-section"> 

      {((AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined')  && showSplash == 1) ?  


  <div className=" full-section  bg-white">


   <div className="top-header">

   <div className="container">
      <div className="col-md-12 row">
         <div className="col-md-9 col-xs-12 top-header-d-flex top-header-contact"> 


         <div className="col-xs-12 "> 
         <a href={"mailto:"+offlemail}     >  <i class="fa fa-envelope"> </i> {offlemail} </a>  
         </div>

           <div className="col-xs-12 "> 

           <a href={"tel:"+offlmobile}     ><i class="fa fa-mobile"> </i> {offlmobile} </a>   <a href={"tel:"+offlphone} ><i class="fa fa-phone"> </i> {offlphone} </a>
             </div>
 

           
         </div>
         <div className="col-md-3 col-xs-12"> 


          <Link className="signup-button"  to={frontUrl+'account'} >Login / Register</Link>


           </div>
        
                  

       </div>
   </div>

     </div>



   <div className="col-md-12"> 
   <img className="home-banner" src={Banner} />
   </div>


   <div className="container  home-sec">

   <div className="col-md-12 row"> 
   <div className="col-md-8"> 
<h1>Empowering Kids in the Digital Age with Alfa Kidz</h1>
<p>
In today's world, mobile phones are an integral part of our children's lives. Rather than restricting their usage, let’s guide them towards positive and enriching content! Alfa Kidz is a vibrant platform where kids can connect with like-minded peers, develop new skills, and unleash their creativity.</p>

<p>We invite teachers and coaches to share their expertise on Alfa Kidz, helping young minds achieve new goals and explore exciting skills. Join us in creating a safe, secure  and innovative <b>Kids Online Community in Mumbai</b>.</p>



   </div>
    <div className="col-md-4"> 
  <img className="home-banner" src={Vision} />
   </div>
 
   </div>

</div>

<div className="container home-sec">

   <div className="col-md-12 row"> 


<h2>Why Join Alfa Kidz?</h2>


<p> <b> Best Kids Learning Platform in Mumbai</b>: Alfa Kidz offers a unique and engaging learning experience.</p>
<p> <b> Register for Kids Talent Showcase in Mumbai</b>: A great opportunity for kids to shine and develop their creativity.</p>
<p> <b> Sign up for AI and Robotics Learning in Mumbai</b>: Equip your child with cutting-edge knowledge and skills.</p>
<p> <b> Enroll in Online Skill Development Programs for Children</b>: Flexible courses designed for your child's growth.</p>
<p> <b> Motivational Speakers and Workshops for Kids</b>: Inspire your child with motivational content and guidance.</p>



 </div>

</div>



<div className="container home-sec">

   <div className="col-md-12 row"> 

<h2>What does our platform will provide?</h2>

<p> <b> Kids Talent Showcase in Mumbai </b> : A gallery where kids can submit and display their projects, from artwork and music to creative writing and more! Receive feedback and encouragement from a supportive community while developing confidence in showcasing their talents.</p>

<p> <b>Explore Technology & Skill Development for Kids </b> : With our Tech Explorer blog, kids can discover new technologies and innovations, explained in a kid-friendly way. Learn about AI and Robotics Learning in Mumbai, Coding, and more through engaging video tutorials. Develop new skills in a fun and interactive environment.</p>

<p><b>Meet Motivational Speakers </b>: Our Inspiration Section is dedicated to empowering young minds with motivational stories, quotes, and videos from renowned speakers and exceptional young achievers. By sharing their remarkable journeys, challenges, and achievements, we aim to inspire kids to pursue their passions and unlock their full potential. Hearing the stories of those who have overcome obstacles and achieved success will help young kids develop the confidence and motivation needed to chase their dreams.
</p>

<p> <b>Online Skill Development Programs for Children</b> : Alfa Kidz offers flexible <b>online skill development</b> programs that help kids build essential skills at their own pace. From future-ready skills like AI and Robotics to creative and academic learning, your child can explore endless possibilities.</p>


<p> <b>Online Community for Kids</b> : Our Kids' Community, a safe and supportive space where children can freely discuss their projects, ask questions, and receive valuable feedback from peers and experienced mentors. Here, kids connect with like-minded individuals who share similar interests and passions, fostering meaningful relationships and a sense of belonging. By sharing ideas, asking questions, and receiving constructive feedback, young minds grow and develop in a positive and encouraging environment, nurturing confidence, creativity, and collaboration.</p>


   </div> 


   </div>


<div className="container home-sec">

   <div className="col-md-12 row"> 
   <div className="col-md-6"> 

   <h2>Our vision</h2>
<p>
 At Alfa Kidz, we prioritize safety and learning through rigorous content review. Every video undergoes strict evaluation to ensure it aligns with our educational and family-friendly standards. We aim to provide future-ready skills with AI and Robotics at Alfa Kidz, fostering growth and development in a secure, inclusive online space.</p>



<h2>Our platform promotes:</h2>

<ul> 

<li>Educational content promoting Science, History, Geography, Maths, Language, and other essential subjects.</li>

<li>
Elegant dance formats, such as Classical or Western.</li>
<li>Mono acts, School Dramas, and Storytelling.</li>
<li>Sports techniques and motivational speakers to foster growth.</li>
<li>Content that improves skills, encourages kids to adopt new skills, and learns new cultures and traditions.</li>


</ul>
 





<p>
Only approved content will be displayed in our app. We strive to set a benchmark for kids' applications, providing a secure and enriching environment.</p>


 <h3>Our review process guarantees:</h3>
 <ul> 
<li>Age-appropriate material</li>
<li> Positive messaging</li>
<li> Respectful and inclusive content</li>
<li> Adherence to copyright laws</li>
</ul>

<p>
By upholding these standards, we empower kids to grow, learn and thrive in a safe online space.
</p>

    </div>
    <div className="col-md-6"> 



<h2>Our platform capability</h2>
<p>
In today's competitive educational landscape, schools strive to stand out. Our innovative application provides a unique platform for schools to showcase their extracurricular activities, student group projects, and achievements.</p>

  <h3>This digital showcase enables:</h3>
<ul> 
<li> Parents to stay informed and engaged with their child's school life</li>
<li> Friends and family to share in students' successes</li>
<li> Prospective parents to make informed decisions about school selection</li>
</ul>

<p>By highlighting each school's distinct strengths and programs, our platform:</p>

<ul> 
<li> Fosters community engagement and transparency</li>
<li>Encourages healthy competition among schools</li>
<li>Empowers parents to make informed choices</li>
</ul>

<p>Discover, compare, and connect with top schools in your area through our user-friendly platform.</p>


    </div>
 
   </div>

</div>


   <div className="   home-sec-bg-dark">

  <Footer />
  </div>


</div>


 
      

      :'' }

    {( (AkuserId ==  null || AkuserId == '' || typeof AkuserId == 'undefined') && showSignup == 1) ?    <Signup /> :'' }
    {(AkuserId !=  null && AKcountsupdated != null && showDashboard == 1) ?   <Dashboard /> : '' }


      </div>
   </div>
 
  );
};
export default Home;