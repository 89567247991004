import React, {useEffect, useState } from "react";
  import {UpdateNotify,GetSubjectsCommon,GetSchoolsCommon,baseUrl,Url,ModalPopupDelete,DeleteNotify,checkpermissionredirect} from './../../Api.js';
  import {    
   Allowclients_view
 } from './../../Permissions.js';

import Select from 'react-select';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";


//import Pagination from "../../includes/Pagination";

 
const Clientlist = () => {

 
 
 

 const [clientsList, setclientsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(clientsList.length / recordsPerPage)
const [school, setSchool] = useState(""); 
const [subject, setSubject] = useState(""); 

const [keyword, setKeyword] = useState(""); 


 const [schoolsList, setschoolsList] = useState([]);
        const [subjectsList, setsubjectsList] = useState([]);
 var [s_no, setSno]=useState(1)


/***Schools only***/
        const [Schooloptions, setschoolsOptions] = useState();
        const [selectedSchoolOption, setselectedSchoolOption] = useState({ value: '', label: 'Select School*' } )


       const  emptyselectedSchoolOption =() => {
          setselectedSchoolOption({ value: ' ', label: ' ' })
        }
        const handleChange = (value) => {
        document.getElementsByClassName('select-school-manage-input')[0].classList.remove('notify-input-missing');
        setSchool(value.value);
        setselectedSchoolOption(value)
        }
   /***Schools only***/



/***Subjects only***/
        const [Subjectoptions, setsubjectsOptions] = useState();
        const [selectedSubjectOption, setselectedSubjectOption] = useState({ value: '', label: 'Select Subject*' } )
        

       const  emptyselectedSubjectOption =() => {
          setselectedSubjectOption({ value: ' ', label: ' ' })
        }
        const handleChangeSubject = (value) => {
        document.getElementsByClassName('select-subject-manage-input')[0].classList.remove('notify-input-missing');
        setSubject(value.value);
        setselectedSubjectOption(value)
        }
   /***Subjects only***/



 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetClients()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetClients()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetClients(value)  
          
         
            
           

        }  

  const GetClients = async (value ,reset='') => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

    
    if(reset == 'reset'){
        

           var postdata={'currentpage':value,'perpage':recordsPerPage ,'school':'' , 'usertype':1 , 'keyword':'' };

    }else{
        
      var postdata={'currentpage':value,'perpage':recordsPerPage,'school':school   , 'usertype':1 ,'keyword':keyword };

        
    }

     

        const response = await fetch(Url+"getclients", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setclientsList(data.data );
        if(data.count > 0){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };


  const DeleteConfirm = async (id) => {

    if(window.confirm("Are you sure?")){
        var postdata={'id' : id };
        const response = await fetch(Url+"deleteclient", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
                   

          if (data.success) { 
            DeleteNotify();
              

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
                 
          }

           
        })

    }

     


  };



  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = clientsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(clientsList.length / recordsPerPage)



//pagination part
  


      
   
     const handleSearchInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;
     
    switch (name) {
        case 'school':
            setSchool(value);
             
       
             
            break;    
              case 'keyword':
            setKeyword(value);
             
       
             
            break;    

    }
  }


  const Searchstart = async () => {




 
     GetClients(1);
 

  }
  const Resetsearch = async () => {
  setSchool('');
   setKeyword('');
     GetClients(1,'reset');
     //window.location.reload();
 
   }


 

   const GetSchools = async (value, reset = '') => {

       (async function() { 

             setschoolsOptions  ( await GetSchoolsCommon())

             })()


            var postdata = {active:1};
            const response = await fetch(Url + "getschools", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setschoolsList(data.data);
                    } else {
                         
                    }
                })
        };

        const GetSubjects = async (value, reset = '') => {

           (async function() { 

             setsubjectsOptions  ( await GetSubjectsCommon())

             })()

            var postdata = {active:1};
            const response = await fetch(Url + "getsubjects", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setsubjectsList(data.data);
                    } else {
                         
                    }
                })
        };

 


const updateEntriescount = async() => {
  var postdata={ type:'teachers'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}



   const changeStatus = async (id,status) => {
     
    if(window.confirm("Are you sure?")){
 
        var postdata={'id' : id , status :status };
        const response = await fetch(Url+"update-client-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           UpdateNotify(data.message)
            
                 GetClients(1);

  
          } else{
             
             
          }

           
        })

    }

     


  };

    useEffect(() => {
        GetSchools();
             GetSubjects();
                            checkpermissionredirect(Allowclients_view)
                            updateEntriescount()

   GetClients();
  }, []);
 

  return (
      <div id="app">
   <Header title="Teachers List" />
   <Sidebar activePage="Clients" activeChildPage="Teachers" />
   <div id="main">
      <Navbar />
      <div className="main-content container-fluid">
         <Pagetitle activePage="Teachers" />
         {ModalPopupDelete()}
         <section className="section">
            <div className="card">
               <div className="card-header">
                  <span>Teachers</span>
               </div>
               <div className="card-body">
                  <div className="col-md-12 col-lg-12 row">
                     <div className="col-md-3">
                        <div className="col-md-12">
                           <label>Keyword  </label>
                        </div>
                        <div className="col-md-12 form-group">
                           <input type="text" value={keyword} name="keyword" className="form-control"  placeholder="Name / Email / Mobile" onChange={(e) => handleSearchInput(e)}  />
                        </div>
                     </div>
                     <div className="col-md-3">
                        <div className="col-md-12">
                           <label>School  </label>
                        </div>
                        <div className="col-md-12 form-group">
                           <Select className="form-control no-border-radius   MustEnterField select-school-manage-input" 
                              name={"school"}
                              value={selectedSchoolOption}
                              onChange={handleChange}
                              options={Schooloptions}
                              />
                        </div>
                     </div>
                     <div className="col-md-3">
                     <div className="col-md-12">
                     <label>Subject  </label>
                     </div>
                     <div className="col-md-12 form-group">
                     <Select className="form-control no-border-radius   MustEnterField select-subject-manage-input" 
                        name={"subject"}
                        value={selectedSubjectOption}
                        onChange={handleChange}
                        options={Subjectoptions}
                        />        
                     { /*
                     <select name="subject" className="form-control" onChange={(e) => handleSearchInput(e)} >
                     <option value="">Select Subject</option>
                     {subjectsList.map((subjectsingle) => (
                     <option  selected={subjectsingle._id == subject ? 'selected':''} value={subjectsingle._id}>{subjectsingle.name  }</option>
                     ))}    
                     </select>  */ }
                     </div> 
                     </div>
                     <div className="col-md-1">
                        <br/>
                        <a  onClick={Searchstart}  className="btn btn-success me-1 mb-1">Search</a>
                     </div>
                     <div className="col-md-1">
                        <br/>
                        <a  onClick={Resetsearch}  className="btn btn-success me-1 mb-1">Reset</a>
                     </div>
                  </div>
                  <table className='table table-striped' id="table1">
                     <thead>
                        <tr>
                           <th>S.No</th>
                           <th>Name</th>
                           <th>Email</th>
                           <th>Mobile</th>
                           <th>University</th>
                           <th>Referred By</th>
                           <th>Status</th>
                           <th>Enable / Disable</th>
                        </tr>
                     </thead>
                     <tbody>
                        {clientsList.map((client) => (
                        <tr>
                           <td>{(s_no++)}    </td>
                           <td>{client.name}</td>
                           <td>{client.email}</td>
                           <td>{client.mobile}</td>
                           <td>{client.university}</td>
                           <td>{(client.referredby != '')?  client.referredby :  <span> - </span> }</td>
                           <td>
                              {(client.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}
                           </td>
                           <td className="table-action-sections table-action-sections-post ">
                              <div>
                                 {(client.status == 1) ? 
                                 <a     onClick={() => changeStatus(client._id,0 )}  className="fa-del-btn"    ><i className="fa fa-close"></i> </a>
                                 : ''}
                                 {(client.status != 1) ?
                                 <a     onClick={() => changeStatus(client._id,1 )}  className="fa-edit-btn"    ><i className="fa fa-check"></i> </a>
                                 : ''}
                              </div>
                           </td>
                        </tr>
                        ))}
                     </tbody>
                  </table>
               </div>
            </div>
            {  pageNumbers.length > 1 ? 
            <nav>
               <ul className='pagination pagination-primary justify-content-center'>
                  <li className="page-item prev-paging-single">
                     <a className="page-link" 
                        onClick={goToPrevPage} 
                        href='#'>
                     Previous
                     </a>
                  </li>
                  {pageNumbers.map(pgNumber => (
                  <li key={pgNumber} 
                  className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
                  <a onClick={(e) => goToPage(pgNumber)  }  
                  className='page-link' 
                  href='#'>
                  {pgNumber}
                  </a>
                  </li>
                  ))}
                  <li className="page-item paging-next-single">
                     <a className="page-link" 
                        onClick={goToNextPage}
                        href='#'>
                     Next
                     </a>
                  </li>
               </ul>
            </nav>
            : ''
            }
         </section>
         <Footer />
      </div>
   </div>
</div>
 
  );
};
export default Clientlist;