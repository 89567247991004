import React, {    useState} from "react";
 import { Link, useNavigate } from "react-router-dom";

 import {SiteLogo, Notifyalert, RemoveNotificationAfterName, SetNotificationAfterName,   frontUrl,    Url,    Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined} from '../Frontapi.js';
  
import Header from '../includes/Header';
 import Footer from '../includes/Footer';

const Forgotpassword = () => {
          Redirectdashboard();


       
       
        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");

        const [email, setEmail] = useState("");
        const [emailError, setEmailerror] = useState(false);
        const [emailvalid, setEmailvalid] = useState("");
        const [formValid, setformValid] = useState(true);
        const [validationSubmit, setvalidationSubmit] = useState(false);

         const [submitLoading, setsubmitLoading] = useState(false);

    const handleUserInput = (e) => {
    RemoveNotificationAfterName();
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
            setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
         
      }
      (email != ''  ) ? setformValid(true): setformValid(false)
   }

        const Resetpassword = async (e) => {
            e.preventDefault();
 
           

            /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-25');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
              if(error === 1){
                    return false
            }
             /***Check Required***/



            var postdata = {
                email: email, url:frontUrl
            };
            try {
                const response = await fetch(Url + "user-reset-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                             Notifyalert('success', data.message)
                             setEmail('');

                            
                        } else {
                             SetNotificationAfterName('email',  data.message)
                           //Notifyalert('error', data.message)
                        }
                    })
                    setsubmitLoading(false)
            } catch (error) {
                setnotificationtype('error')
                setnotification(error)
            }
        };
      


  return (
  <div>

  <Header pageInfo="Login" />

    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
    
    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 


    <div className="page-content">

    <div className="login-container"> 
        
        <div className="login-section"> 
        <h1 className="login-title">Reset Password</h1>
 
                    <form action="" autoComplete="off">
                        <div className="form-group">
                               <input  className="form-control login-input mb-25 MustEnterField"   type="email" name="email" placeholder="Enter your registered email address*" value={email}
                              onChange={(e) => handleUserInput(e)}
                              required/>

                           <p className="forgot-password-inst"  > <Link to={frontUrl+'login'} >Go back to Login</Link> </p>             


                        </div>
                         <div className="clearfix">
                            <button className="login-button" onClick={Resetpassword}>Send</button>
                        </div>  

                    </form>
             
                


  </div>

    </div>
    </div>


<Footer />
    </div>


    </div>



    </div>
 
  );
};
export default Forgotpassword;