import React, { useState,useEffect } from "react";
 
import {SiteLogo,baseUrl,Url,CommonNotify} from './../Api.js';
 
 import { Link } from 'react-router-dom';

import { Allowdashboard_view ,
   Allowsettings_view ,
   Allowsettings_edit ,
   Allowusers_view ,
   Allowusers_add ,
   Allowusers_edit ,
   Allowusers_delete ,
   Allowcms_view ,
   Allowcms_edit ,
   Allowemailtemplates_view ,
   Allowemailtemplates_edit ,
   Allowposts_view ,
   Allowposts_edit ,
   Allowads_view ,
   Allowads_edit ,
   Alloworders_view ,
   Alloworders_edit ,
   Allowenquiries_view ,
   Allowstreams_view ,
   Allowschools_view ,
   Allowschools_add ,
   Allowschools_edit ,
   Allowschools_delete ,
   Allowsubjects_view ,
   Allowsubjects_add ,
   Allowsubjects_edit ,
   Allowsubjects_delete ,
   Allowclients_view,
   Allowrolesview,
   Allowchallenges_view ,
   Allowchallenges_add ,
   Allowchallenges_edit ,
   Allowchallenges_delete 


} from './../Permissions.js';

 
const Sidebar = ({activePage,activeChildPage}) => {
 


const  [showUserMenu,setshowUserMenu]=useState(0);
const  [showRoleMenu,setshowRoleMenu]=useState(0);
const  [showSchoolMenu,setshowSchoolMenu]=useState(0);
const  [showSubjectMenu,setshowSubjectMenu]=useState(0);
const  [showClientMenu,setshowClientMenu]=useState(0);
const  [showChallengeMenu,setshowChallengeMenu]=useState(0);


const dashboardActive = (activePage === 'Dashboard') ? 'active':''; 

const settingsActive = (activePage === 'Settings') ? 'active':''; 
const cmsActive = (activePage === 'CMS') ? 'active':''; 
const emailtemplatesActive = (activePage === 'EmailTemplates') ? 'active':'';


const userActive = (activePage === 'Users') ? 'active':''; 
const rolesActive = (activePage === 'Roles') ? 'active':''; 
const subjectActive = (activePage === 'Subjects') ? 'active':''; 
const schoolActive = (activePage === 'Schools') ? 'active':''; 

const clientActive = (activePage === 'Clients'  ) ? 'active':''; 
 const TeacherActive=   (activeChildPage == 'Teachers')?'active':''; 
const StudentActive=   (activeChildPage == 'Students')?'active':''; 

const postActive = (activePage === 'Posts') ? 'active':''; 

const adsActive = (activePage === 'Ads') ? 'active':''; 
const ordersActive = (activePage === 'Orders') ? 'active':''; 



const EnquiryActive = (activePage === 'Enquiries') ? 'active':''; 

const StreamActive = (activePage === 'Streams') ? 'active':''; 



const challengesActive = (activePage === 'Challenges') ? 'active':''; 



const AddActive=   (activeChildPage == 'Add')?'active':''; 
const ListActive=   (activeChildPage == 'List')?'active':''; 
const ListChildActive=   (activeChildPage == 'Listchild')?'active':''; 


const handleMenu = (e,type)=>{

    
 
switch (type) {
                case 'users':
                    setshowUserMenu(1)
                    break;
                 case 'roles':
                    setshowRoleMenu(1)
                    break;
                 case 'schools':
                    setshowSchoolMenu(1)
                    break;      
                case 'subjects':
                    setshowSubjectMenu(1)
                    break;     
                     case 'clients':
                    setshowClientMenu(1)
                    break;  

                 case 'challenges':
                    setshowChallengeMenu(1)
                    break; 
            }            

}
const SetMenuActive = async() => {

    if(activePage  == 'Users'){
     setshowUserMenu(1);
    }
     if(activePage  == 'Roles'){
     setshowRoleMenu(1);
    }
     if(activePage  == 'Schools'){
     setshowSchoolMenu(1);
    }
     if(activePage  == 'Subjects'){
     setshowSubjectMenu(1);
    }

     if(activePage  == 'Clients'){
     setshowClientMenu(1);
    }

       if(activePage  == 'Challenges'){
     setshowChallengeMenu(1);
    }
}




const  [postscount,setPostscount]=useState(0);
const  [adscount,setAdscount]=useState(0);
const  [challengescount,setChallengescount]=useState(0);
const  [orderscount,setOrderscount]=useState(0);

const  [teacherscount,setTeacherscount]=useState(0);
const  [studentscount,setStudentscount]=useState(0);

const  [streamscount,setStreamscount]=useState(0);

const  [enquiriescount,setEnquiriescount]=useState(0);

const getEntriescount = async() => {

   var postdata={ };

        const response = await fetch(Url+"get-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            var data = data.data
                setPostscount(data.posts)
               setAdscount(data.ads)
               setChallengescount(data.challenges)
               setOrderscount(data.orders)

               setTeacherscount(data.teachers)
               setStudentscount(data.students)

               setStreamscount(data.streams)

               setEnquiriescount(data.enquiries)

       
         
          } else{
                 
          }

           
       })


}









    useEffect(() => {
   SetMenuActive();

       getEntriescount()


  }, []);



  return (

 <div id="sidebar" className='active'>
   <div className="sidebar-wrapper active">
      <div className="sidebar-header">
         <img src={SiteLogo} alt="" srcSet={SiteLogo} />
      </div>
      <div className="sidebar-menu">
        <ul className="menu">
   <li className='sidebar-title'>Main Menu</li>




{(Allowdashboard_view == 1 ) ?
   <li className= {'sidebar-item '+dashboardActive} >
   <Link to={baseUrl+'dashboard'} className='sidebar-link amigo-link text-black text-black'>
   <i className="fa fa-home" width="20"></i> 
   <span>Dashboard</span>
   </Link>
   </li> : '' }






{(Allowschools_view == 1 ) ?


<li className={'sidebar-item '+schoolActive}>
<a   onClick={(e) => handleMenu(e,'schools')} data-head="schools"  className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-building" width="20"></i> 
<span>Schools</span>
</a>

  <ul className={showSchoolMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'schools'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Schools</span> </Link>
   </li>

   {(Allowschools_add == 1 ) ?

   <li >
      <Link to={baseUrl+'schools/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add School</span> </Link>
   </li>: '' }
</ul>

</li>: '' }





{(Allowsubjects_view == 1 ) ?


<li className={'sidebar-item '+subjectActive}>
<a  onClick={(e) => handleMenu(e,'subjects')} data-head="subjects" className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-book" width="20"></i> 
<span>Subjects</span>
</a>


  <ul className={showSubjectMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'subjects'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Subjects</span> </Link>
   </li>

{(Allowsubjects_add == 1 ) ?

   <li >
      <Link to={baseUrl+'subjects/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Subject</span> </Link>
   </li>: '' }



</ul>


</li>: '' }





{(Allowclients_view == 1 ) ?

<li className={'sidebar-item '+clientActive}>
<a  onClick={(e) => handleMenu(e,'clients')} data-head="clients" className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-users" width="20"></i> 
<span>Teachers / Students</span> { ((teacherscount+studentscount) >0)? <small className="entries-count">{teacherscount+studentscount} </small> : '' }
</a>


  <ul className={showClientMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'teachers'} className={TeacherActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Teachers</span> { (teacherscount >0)? <small className="entries-count">{teacherscount} </small> : '' }  </Link>
   </li>
   <li >
      <Link to={baseUrl+'students'} className={StudentActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Students</span>  { (studentscount >0)? <small className="entries-count">{studentscount} </small> : '' }   </Link>
   </li>
</ul>


</li>: '' }






{(Allowposts_view == 1 ) ?

<li className={'sidebar-item '+postActive}>
<Link to={baseUrl+'posts'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-sticky-note" width="20"></i> 
<span>Posts</span> { (postscount >0)? <small className="entries-count">{postscount} </small> : '' }  
</Link>
</li>: '' }


 



{(Allowads_view == 1 ) ?

<li className={'sidebar-item '+adsActive}>
<Link to={baseUrl+'ads'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-sticky-note" width="20"></i> 
<span>Ads</span> { (adscount >0)? <small className="entries-count">{adscount} </small> : '' }  
</Link>
</li>: '' }



{(Alloworders_view == 1 ) ?

<li className={'sidebar-item '+ordersActive}>
<Link to={baseUrl+'orders'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-money" width="20"></i> 
<span>Orders</span> { (orderscount >0)? <small className="entries-count">{orderscount} </small> : '' }  
</Link>
</li>: '' }







{(Allowstreams_view == 1 ) ?

<li className={'sidebar-item '+StreamActive}>
<Link to={baseUrl+'streams'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-wifi" width="20"></i> 
<span>Streams</span>  { (streamscount >0)? <small className="entries-count">{streamscount} </small> : '' }  
</Link>
</li>: '' }





{(Allowchallenges_view == 1 ) ?

<li className={'sidebar-item '+challengesActive}>
<a   onClick={(e) => handleMenu(e,'challenges')} data-head="challenges"  className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-building" width="20"></i> 
<span>Challenges</span> 
</a>

  <ul className={showChallengeMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'challenges'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Challenges</span> </Link>
   </li>

  

   {(Allowchallenges_add == 1 ) ?

   <li >
      <Link to={baseUrl+'challenges/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Challenge</span> </Link>
   </li>: '' }


     <li >
      <Link to={baseUrl+'challengependingposts'} className={ListChildActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Pending Posts</span> 
      { (challengescount >0)? <small className="entries-count">{challengescount} </small> : '' }   </Link>
   </li>

   
   
</ul>

</li>: '' }







{(Allowenquiries_view == 1 ) ?


<li className={'sidebar-item '+EnquiryActive}>
<Link to={baseUrl+'enquiries'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-comment" width="20"></i> 
<span>Enquiries</span> { (enquiriescount >0)? <small className="entries-count">{enquiriescount} </small> : '' }  
</Link>
</li>: '' }


{(Allowcms_view == 1 ) ?


<li className={'sidebar-item '+cmsActive}>
<Link to={baseUrl+'cms'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-sticky-note" width="20"></i> 
<span>CMS</span>
</Link>
</li>: '' }



{(Allowemailtemplates_view == 1 ) ?


<li className={'sidebar-item '+emailtemplatesActive}>
<Link to={baseUrl+'emailtemplates'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-envelope" width="20"></i> 
<span>Email Templates</span>
</Link>
</li>: '' }




{(Allowsettings_view == 1 ) ?

<li className={'sidebar-item '+settingsActive}>
<Link to={baseUrl+'settings'} className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-cog" width="20"></i> 
<span>Settings</span>
</Link>
</li>: '' }



{(Allowusers_view == 1 ) ?

 <li className={'sidebar-item '+userActive}>
   <a onClick={(e) => handleMenu(e,'users')}   className='sidebar-link amigo-link text-black head-link'>
   <i className="fa fa-user link-icons-sidebar" width="20"></i> 
   <span>Users</span>
   </a>
   <ul className={showUserMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'users'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Users</span> </Link>
   </li>
   {(Allowusers_add == 1 ) ?

   <li >
      <Link to={baseUrl+'users/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add User</span> </Link>
   </li>: '' }

</ul>
</li>  : '' }



{(Allowrolesview == 1 ) ?


<li className={'sidebar-item '+rolesActive}>


<a onClick={(e) => handleMenu(e,'roles')} data-head="roles"   className='sidebar-link amigo-link text-black head-link'>
<i className="fa fa-user-secret" width="20"></i> 
<span>Roles</span>
</a>

  <ul className={showRoleMenu == 1 ? '' :'display_none'} > 
   <li >
      <Link to={baseUrl+'roles'} className={ListActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>List Roles</span> </Link>
   </li>
   <li >
      <Link to={baseUrl+'roles/add'} className={AddActive+' sidebar-link amigo-link text-black'}>
      <i className="fa fa-circle-o"></i><span>Add Role</span> </Link>
   </li>
</ul>


</li>: '' }



</ul>

 



       
      </div>
      <button className="sidebar-toggler btn x amigo-button"><i className="fa fa-eyex"></i></button>
   </div>
</div>


  
  );
};
export default Sidebar;