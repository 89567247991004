import React, { useState ,useEffect} from "react";
 
 import {baseUrl,Url,CommonNotify} from './../Api.js';

import Chart from 'react-apexcharts'

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";


import Footer from "../includes/Footer";



 
const Dashboard = () => {


       const [usersCount, setusersCount] = useState(1);
       const [schoolsCount, setschoolsCount] = useState(1);
       const [subjectsCount, setsubjectsCount] = useState(1);
       const [clientsCount, setclientsCount] = useState(1);

  const [userdatavalues, setuserdatavalues] = useState(0);
  const [postdatavalues, setpostdatavalues] = useState(0);
  const [adsdatavalues, setadsdatavalues] = useState(0);
  const [orderdatavalues, setorderdatavalues] = useState(0);




       const [clientdata,setclientdata] =useState({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
      }]
    })
        const [postdata,setpostdata] =useState({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
      }]
    })
         const [adsdata,setadsdata] =useState({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
      }]
    })
          const [orderdata,setorderdata] =useState({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: [30, 40, 35, 50, 49, 60, 70, 91, 125]
      }]
    })


  const GetDashboard = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)
 
 

        const response = await fetch(Url+"dashboard", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var counts = data.data;
             


             setclientdata({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: counts.userkeys
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: counts.uservalues
      }]
    })



             setpostdata({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: counts.postkeys
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: counts.postvalues
      }]
    })


             setadsdata({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: counts.adskeys
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: counts.adsvalues
      }]
    })


              setorderdata({ options: {
        chart: {
          id: 'apexchart-example'
        },
        xaxis: {
          categories: counts.orderkeys
        },
          colors: ['#000' ],
      },

      series: [{
        name: 'series-1',
        data: counts.ordervalues
      }]
    })

          setuserdatavalues(counts.userdatavalues)
          setpostdatavalues(counts.postdatavalues)
          setadsdatavalues(counts.adsdatavalues)
          setorderdatavalues(counts.orderdatavalues)

            setusersCount(counts.usersCount);
            setschoolsCount(counts.schoolsCount);
            setsubjectsCount(counts.subjectsCount);
             setclientsCount(counts.clientsCount);
          } else{
           }

           
       })


 
  };
 
 
 
          useEffect(() => {
   GetDashboard();
  }, []);
   
  
  
  return (

      <div id="app">
            <Header title="Dashboard" />

         <Sidebar  activePage="Dashboard" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                <Pagetitle  activePage="Dashboard"  />


                    <section className="section">


                       <div className="row mb-2">

                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-user"></i> Users</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{usersCount}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>


                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-building"></i> Schools</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{schoolsCount}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>

                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-book"></i> Subjects</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{subjectsCount}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>


                          <div className="col-12 col-md-3">
                             <div className="card card-statistic">
                                <div className="card-body p-0">
                                   <div className="d-flex flex-column">
                                      <div className='px-3 py-3 d-flex justify-content-between'>
                                         <h3 className='card-title'> <i className="fa fa-user"></i> Clients</h3>
                                         <div className="card-right d-flex align-items-center dashboard-card">
                                            <p>{clientsCount}</p>
                                         </div>
                                      </div>
                                   </div>
                                </div>
                             </div>
                          </div>

                       </div>
 

   <div className="row mb-2">

   {(userdatavalues> 0) ? 
                          <div className="col-12 col-md-6">
                <h3 className='card-title'> <i className="fa fa-user"></i> Clients</h3>

                             <Chart options={clientdata.options} series={clientdata.series} type="bar" width={500} height={320} />
 </div> : ''}

   {(postdatavalues> 0) ? 

<div className="col-12 col-md-6">
                <h3 className='card-title'> <i className="fa fa-sticky-note"></i> Posts</h3>

                             <Chart options={postdata.options} series={postdata.series} type="bar" width={500} height={320} />
 </div>: ''}
   {(adsdatavalues> 0) ? 

<div className="col-12 col-md-6">
                <h3 className='card-title'> <i className="fa fa-sticky-note"></i> Ads</h3>
   <Chart options={adsdata.options} series={adsdata.series} type="bar" width={500} height={320} />
 </div>: ''}

   {(orderdatavalues> 0) ? 

<div className="col-12 col-md-6">
                <h3 className='card-title'> <i className="fa fa-money"></i> Transactions</h3>
     <Chart options={orderdata.options} series={orderdata.series} type="bar" width={500} height={320} />
 </div>: ''}



  </div>


                    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Dashboard;