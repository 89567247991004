import React, {useEffect, useState } from "react";
 
 import {Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,    validEmailRegex ,checkpermissionredirect} from './../../Api.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 
import {    
    Allowusers_view ,
    Allowusers_add ,
    Allowusers_edit ,
    Allowusers_delete 
} from './../../Permissions.js';

 

const General = () => {

 
 
 



const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

const[id] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
const[username, setUsername] = useState(""); 
const[email, setEmail] = useState(""); 
const[password, setPassword] = useState(""); 
const[confirmpassword, setConfirmPassword] = useState(""); 
const[role, setRole] = useState(""); 
const [rolesList, setrolesList] = useState([]);
         const [submitLoading, setsubmitLoading] = useState(false);
const[status, setStatus] = useState(1); 

 
 const Getuser = async (e) => {
  
  try{
     var postdata={_id:id};

     const response = await fetch(Url+"getuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var userdata=data.data;
               setName(userdata.name);
              setUsername(userdata.username);
              setEmail(userdata.email);
               setRole(userdata.role);
                  setStatus(userdata.status);
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


 
  const Updateuser = async (e) => {
    e.preventDefault();
     setsubmitLoading(true)
    if(checkEmptyUndefined(name)=== false || checkEmptyUndefined(username)=== false ||checkEmptyUndefined(email)=== false ||checkEmptyUndefined(role)=== false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false

    }
    if(validEmailRegex.test(email) == false){
                

                 setnotificationtype('error')
                    setnotification('Invalid Email')
                    setsubmitLoading(false)
                    return false
     }

    if(password !== confirmpassword){
       setnotificationtype('error')
                    setnotification('Passwords dont match')
                    setsubmitLoading(false)
                    return false
    }

    try {

       var statusupdate = (typeof status == 'undefined' || status =='' || status =='1')?'1':0;
        
      var postdata={_id:id ,name :name,username:username,email:email,role:role ,status:statusupdate , password:password};
 
      const response = await fetch(Url+"updateuser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
                    redirectPage('users')
          } else{
              setnotificationtype('error')
                    setnotification(data.message)
          }

           
       })
   setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
     switch (name) {
        case 'name':
            setName(value);
             
             
            break;
        case 'email':
            setEmail(value);
            break;
        case 'username':
            setUsername(value);
            break;
        case 'password':
            setPassword(value);
            break;  
        case 'confirmpassword':
            setConfirmPassword(value);
            break;  
        case 'role':
            setRole(value);
            break;   
         case 'status':
            setStatus(value);
            break;  
        default:
            break;      
    }
 }


 const GetRoleslist = async ( ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

 
      var postdata={'currentpage':1,'perpage':10000};

        const response = await fetch(Url+"getroles", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           setrolesList(data.data );
         
         
          } else{
                 
          }

           
       })


 
  };
    
useEffect(() => {
    GetRoleslist();
   Getuser(id);

if(typeof id != 'undefined' && id !=''){
    checkpermissionredirect(Allowusers_edit)
}else{
    checkpermissionredirect(Allowusers_add)

}

  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit User":"Add User"} />

         <Sidebar activePage="Users"  activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit User":"Add User"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit User":"Add User"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" autocomplete="off">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>
                        <div className="col-md-4">
                            <label>Username <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="username" placeholder="Username" value={username} 
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>
                         
                        <div className="col-md-4">
                            <label>Email <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="text" name="email" placeholder="Email" value={email} 
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>


                        <div className="col-md-4">
                            <label>Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="password" placeholder="Password" value={password} 
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>

                        <div className="col-md-4">
                            <label>Confirm Password</label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="confirmpassword" placeholder="Confirm Password" value={confirmpassword} 
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>

                        <div className="col-md-4">
                            <label>Role <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="role"  onChange={(e) => handleUserInput(e)}> 
                            <option value="">Choose</option>
                            {rolesList.map((rolelist) => (
                                <option value={rolelist._id}  selected={rolelist._id == role ? 'selected' :''}  >{rolelist.name}</option>
                             ))}
                            </select>
                           
                        </div>
                        
                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                         {( submitLoading === false) ?  (
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateuser}>Submit</button>
                         </div>
                          ) : '' }
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default General;