import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Paymentgateway = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [paymentmode, setPaymentmode] = useState("");
  const [paymentsandboxapiurl, setPaymentsandboxapiurl] = useState("");
  const [paymentliveapiurl, setPaymentliveapiurl] = useState("");
  const [paymentsandboxusername, setPaymentsandboxusername] = useState("");
  const [paymentsandboxpassword, setPaymentsandboxpassword] = useState("");
  const [paymentliveusername, setPaymentliveusername] = useState("");
  const [paymentlivepassword, setPaymentlivepassword] = useState("");


  const [altpaymentsandboxusername, setaltPaymentsandboxusername] = useState("");
  const [altpaymentsandboxpassword, setaltPaymentsandboxpassword] = useState("");
  const [altpaymentliveusername, setaltPaymentliveusername] = useState("");
  const [altpaymentlivepassword, setaltPaymentlivepassword] = useState("");


  const [phonepemode, setPhonepemode] = useState("");
 
  const [phonepesandboxapiurl, setPhonepesandboxapiurl] = useState("");
  const [phonepeliveapiurl, setPhonepeliveapiurl] = useState("");
const [phonepesandboxmerchantid, setPhonepesandboxmerchantid] = useState("");
const [phonepesandboxkey, setPhonepesandboxkey] = useState("");
const [phonepelivemerchantid, setPhonepelivemerchantid] = useState("");
const [phonepelivekey, setPhonepelivekey] = useState("");




                const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {



  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
            setPaymentmode(settingsdata.paymentmode );
            setPaymentsandboxapiurl(settingsdata.paymentsandboxapiurl );
            setPaymentliveapiurl(settingsdata.paymentliveapiurl );

            setPaymentsandboxusername(settingsdata.paymentsandboxusername );
            setPaymentsandboxpassword(settingsdata.paymentsandboxpassword );

            setPaymentliveusername(settingsdata.paymentliveusername );
            setPaymentlivepassword(settingsdata.paymentlivepassword );


            setaltPaymentsandboxusername(settingsdata.altpaymentsandboxusername );
            setaltPaymentsandboxpassword(settingsdata.altpaymentsandboxpassword );

            setaltPaymentliveusername(settingsdata.altpaymentliveusername );
            setaltPaymentlivepassword(settingsdata.altpaymentlivepassword );
           

            setPhonepemode(settingsdata.phonepemode );
            setPhonepesandboxapiurl(settingsdata.phonepesandboxapiurl );
            setPhonepeliveapiurl(settingsdata.phonepeliveapiurl );

            setPhonepesandboxmerchantid(settingsdata.phonepesandboxmerchantid );
            setPhonepesandboxkey(settingsdata.phonepesandboxkey );
            setPhonepelivemerchantid(settingsdata.phonepelivemerchantid );
            setPhonepelivekey(settingsdata.phonepelivekey );

         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updatepaymentsettings = async (e) => {
    e.preventDefault();
    setnotification("");

    setsubmitLoading(true)
      if(checkEmptyUndefined(paymentmode) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={
        paymentmode: paymentmode, 
            paymentsandboxapiurl: paymentsandboxapiurl,
            paymentliveapiurl: paymentliveapiurl,
        paymentsandboxusername: paymentsandboxusername,
         paymentsandboxpassword: paymentsandboxpassword,
          paymentliveusername: paymentliveusername,
           paymentlivepassword: paymentlivepassword ,

        altpaymentsandboxusername: altpaymentsandboxusername,
        altpaymentsandboxpassword: altpaymentsandboxpassword,
        altpaymentliveusername: altpaymentliveusername,
        altpaymentlivepassword: altpaymentlivepassword ,


                phonepemode: phonepemode, 
                    phonepesandboxapiurl: phonepesandboxapiurl,
            phonepeliveapiurl: phonepeliveapiurl,
                phonepesandboxmerchantid: phonepesandboxmerchantid,
                phonepesandboxkey: phonepesandboxkey,
                phonepelivemerchantid: phonepelivemerchantid,
                phonepelivekey: phonepelivekey 


             };
 
       const response = await fetch(Url+"update-payment-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                     setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           setsubmitLoading(false)
       })
 
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'paymentmode':

              setPaymentmode(value);
             break;
        case 'paymentsandboxapiurl':

              setPaymentsandboxapiurl(value);
             break;
        case 'paymentliveapiurl':

              setPaymentliveapiurl(value);
             break;
        case 'paymentsandboxusername':

              setPaymentsandboxusername(value);
             break;
        case 'paymentsandboxpassword':

              setPaymentsandboxpassword(value);
             break;
        case 'paymentliveusername':

              setPaymentliveusername(value);
             break;               
         case 'paymentlivepassword':

              setPaymentlivepassword(value);
             break;   

            case 'altpaymentsandboxusername':

            setaltPaymentsandboxusername(value);
            break;
            case 'altpaymentsandboxpassword':

            setaltPaymentsandboxpassword(value);
            break;
            case 'altpaymentliveusername':

            setaltPaymentliveusername(value);
            break;               
            case 'altpaymentlivepassword':

            setaltPaymentlivepassword(value);
            break;    


             case 'phonepemode':

              setPhonepemode(value);
             break;

            case 'phonepesandboxapiurl':

            setPhonepesandboxapiurl(value);
            break;
            case 'phonepeliveapiurl':

            setPhonepeliveapiurl(value);
            break;

          

            case 'phonepesandboxmerchantid':

            setPhonepesandboxmerchantid(value);
            break;
            case 'phonepesandboxkey':

            setPhonepesandboxkey(value);
            break;
            case 'phonepelivemerchantid':

            setPhonepelivemerchantid(value);
            break;               
            case 'phonepelivekey':

            setPhonepelivekey(value);
            break;   
     

 

          
             default :
              break;
    }


 }


 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Payment mode<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">

                                 <select className="form-control"  name="paymentmode" onChange={(e) => handleUserInput(e)} >
                                 <option value="">Choose</option>
                                 <option value="sandbox"  selected={paymentmode == 'sandbox' ? 'selected' : '' } >Sandbox</option>
                                 <option value="live" selected={paymentmode == 'live' ? 'selected' : '' }>Live</option>

                                 </select>
                                    
                                 </div>


                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxapiurl">Api Sandbox Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxapiurl" placeholder="Api Sandbox Url" value={paymentsandboxapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxusername">Sandbox username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxusername" placeholder="Sandbox username / Client Id" value={paymentsandboxusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="paymentsandboxpassword">Sandbox password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentsandboxpassword" placeholder="Sandbox password / Secretkey" value={paymentsandboxpassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                


                                <div className="col-md-4">
                                    <label htmlFor="altpaymentsandboxusername">Alt Sandbox username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="altpaymentsandboxusername" placeholder="Sandbox username / Client Id" value={altpaymentsandboxusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="altpaymentsandboxpassword">Alt Sandbox password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="altpaymentsandboxpassword" placeholder="Sandbox password / Secretkey" value={altpaymentsandboxpassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>








                                <div className="col-md-4">
                                    <label htmlFor="paymentliveapiurl">Api Live Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentliveapiurl" placeholder="Api Live Url" value={paymentliveapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>



                                <div className="col-md-4">
                                    <label htmlFor="paymentliveusername">Live username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentliveusername" placeholder="Live username / Client Id" value={paymentliveusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="paymentlivepassword">Live password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="paymentlivepassword" placeholder="Live password / Secretkey" value={paymentlivepassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>





                                <div className="col-md-4">
                                <label htmlFor="altpaymentliveusername">Alt Live username / Client Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                <input className="form-control" type="text" name="altpaymentliveusername" placeholder="Alt Live username / Client Id" value={altpaymentliveusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                <label htmlFor="altpaymentlivepassword">Alt Live password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                <input className="form-control" type="text" name="altpaymentlivepassword" placeholder="Alt Live password / Secretkey" value={altpaymentlivepassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                <hr/>


                                <div className="col-md-4">
                                    <label htmlFor="address">Phonepe mode<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">

                                 <select className="form-control"  name="phonepemode" onChange={(e) => handleUserInput(e)} >
                                 <option value="">Choose</option>
                                 <option value="sandbox"  selected={phonepemode == 'sandbox' ? 'selected' : '' } >Sandbox</option>
                                 <option value="live" selected={phonepemode == 'live' ? 'selected' : '' }>Live</option>

                                 </select>
                                    
                                 </div>


                                 <div className="col-md-4">
                                    <label htmlFor="phonepesandboxapiurl">Phonepe Api Sandbox Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phonepesandboxapiurl" placeholder="Api Sandbox Url" value={phonepesandboxapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                  <div className="col-md-4">
                                    <label htmlFor="phonepeliveapiurl">Phonepe Api Live Url</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phonepeliveapiurl" placeholder="Api Live Url" value={phonepeliveapiurl} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="phonepesandboxmerchantid">Phonepe  Username / Merchant Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phonepelivemerchantid" placeholder="Username / Merchant Id" value={phonepelivemerchantid} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="phonepesandboxkey">Phonepe password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phonepelivekey" placeholder="Password / Secretkey" value={phonepelivekey} onChange={(e) => handleUserInput(e)} required/>
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="phonepesandboxmerchantid">Phonepe Sandbox Username / Merchant Id</label>
                                </div>
                                <div className="col-md-8 form-group">
                                 <input className="form-control" type="text" name="phonepesandboxmerchantid" placeholder="Sandbox Username / Merchant Id" value={phonepesandboxmerchantid} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="phonepesandboxkey">Phonepe password / Secretkey</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="phonepesandboxkey" placeholder="Sandbox Password / Secretkey" value={phonepesandboxkey} onChange={(e) => handleUserInput(e)} required/>
                                </div>



                               
                               
                                   {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatepaymentsettings}>Submit</button> : ''}
                               </div>
                                ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Paymentgateway;