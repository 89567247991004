import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";
import reactStringReplace from 'react-string-replace';
  import Header from '../includes/Header';


 import Sidebar from "../includes/Sidebar";



import {ReplaceRender,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId,frontUrl,SiteLogoDark,Socketiourl,Akusername} from '../Frontapi.js';


  import {angleLeft  } from '../ImagesIcons.js';

 
 import {Socketcall} from '../../socketcall/Socketcall.js';


 
const Notifications = () => {
    const[updateSidecount,setupdateSidecount]=useState(0)
   const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

 const [notificationslist,setnotificationslist]=useState([])

 const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')

 

 const Getnotifications =  async () => {
  var postdata={ 'userid':AkuserId };


        const response = await fetch(Url+"get-notifications", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

if(data.data.length == 0){
   setcheckrecordsLength('No notifications found')



}

 var notificationlist=data.data;
 
            setnotificationslist(notificationlist)

                } else{
                 
          }


           
       })


}
 


const updateNotiview=  async () => {
  var postdata={'userid':AkuserId };


        const response = await fetch(Url+"update-noti-view", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

                setupdateSidecount(1)

              } else{
                 
          }

           
       })


}


const  StringReplaceForFollowerNotify= (notifytext)=>{

notifytext = notifytext.replace("[[", "")
notifytext = notifytext.replace("]]", "")
return notifytext
}


const updateFollower=async(e,username)=>{





  var followed = e.target.attributes.getNamedItem('data-update-followed').value;
if(parseInt(followed)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-followed',1);
      

      e.target.innerHTML ='Following'

      


Socketcall('on', 'joinuser',{token:username})
Socketcall('emit', 'newfollow',{token:username,  from:Akusername})




}else{
    e.target.setAttribute('data-update-followed',0);
      e.target.innerHTML ='Follow'

}



var postdata={following: username, follower:AkuserId}
        const response = await fetch(Url + "update-follower", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success  ) {
                
   //   Notifyalert('success', data.message)

                        

                }  
            })



}


 
useEffect(() => {
    
Getnotifications();
updateNotiview();
    }, []);


  return (

     <div>
        <Header pageInfo="Notifications" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} updateSidecount={updateSidecount} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section">
         <div className="page-content">
            <div className="profile-container">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span> Notifications</h1>
               </div>
               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row notification-wrapper  ">
                  { (notificationslist.length==0) ? 
                  <div className="no-records"> {checkrecordsLength} </div>
                  :
                  <div>
                     {notificationslist.map((notificationsingle) => (
                     <div className="notification-list-box" data-attr-name={notificationsingle.username} >
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 notification-single padding-0 row   ">
                           <div className="col-md-2 col-lg-2 col-sm-2 col-xs-2 col-2">
                              <div className="notification-single-section-img">

                              { (notificationsingle.link != '') ?  <Link to={userProfileurl+notificationsingle.username}><img src={notificationsingle.userprofilepicture} /> </Link>    :   <img src={notificationsingle.userprofilepicture} /> }



 

                              </div>
                           </div>
                           <div className="col-md-7 col-lg-7 col-sm-7 col-xs-7 col-7">
                              <div className="notification-single-section-title">
                                 <p className="notify-message">



                                   <span>
                                   {notificationsingle.notifymessage.split(' ').map((notifytext) => (
                                    <span>
                                   <span>

                                    { ( notifytext.includes('[[') && notifytext.includes(']]') )    ?  <Link to={userProfileurl+ StringReplaceForFollowerNotify(notifytext)  }>{StringReplaceForFollowerNotify(notifytext)}</Link>      :     notifytext   } 

                 </span><span>  </span> </span>
                                       ))}

                                       </span>


                                       {/* 

                                    {reactStringReplace(notificationsingle.notifymessage, /[[[a-z]*]]/i , (match, i) => (
                                    <span>
                                       <Link   to={userProfileurl+ notificationsingle.username } >
                                       {notificationsingle.username} </Link> {match} 
                                    </span>
                                    ))}*/}
                                    <br/><span className="notify-timestamp">{notificationsingle.timediff}</span>
                                 </p>
                              </div>
                           </div>
                           {(notificationsingle.postthumbnail != '' && notificationsingle.notifytype != 2) ?
                           <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3">
                              <div className="notification-single-section-img">
                                 <Link   to={postDetailurl+ notificationsingle.postlink } >
                                 <img className="no-border-radius" src={notificationsingle.postthumbnail} />
                                 </Link> 
                              </div>
                           </div>
                           :''
                           } 
                           {(notificationsingle.notifytype == 2) ?
                           <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3">
                              <div className="notification-single-section-img">
                                 <button type="submit" className=" me-1 mb-1 profile-main-btn" data-update-followed={notificationsingle.following} onClick={(e)=>updateFollower(e,notificationsingle.username)}   > {(notificationsingle.following == 1) ? 'Following' : 'Follow' } </button>
                              </div>
                           </div>
                           : ''
                           }
                        </div>
                     </div>
                     ))}
                  </div>
                  }
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
 
  );
};
export default Notifications;