import React, {useEffect, useState } from "react";
 
 import {Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,checkpermissionredirect} from './../../Api.js';

  import {    
   Allowemailtemplates_view ,
   Allowemailtemplates_edit 
 } from './../../Permissions.js';

 import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 

 

const General = () => {

 
 
 



const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

const[id] = useState(UrlSplitter(4));

const[name, setName] = useState(""); 
const[content, setContent] = useState(""); 
 const[status, setStatus] = useState(1); 

         const [submitLoading, setsubmitLoading] = useState(false);

 
 const Getemailtemplates = async (e) => {
  
  try{
     var postdata={_id:id};

     const response = await fetch(Url+"getemailtemplates", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var emailtemplatesdata=data.data;
               setName(emailtemplatesdata.name);
               setStatus(emailtemplatesdata.status);
              setContent(emailtemplatesdata.content);
         
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


 
  const Updateemailtemplates = async (e) => {
    e.preventDefault();
    setsubmitLoading(true)
            if(checkEmptyUndefined(content)=== false       ){
            setnotificationtype('error')
            setnotification('* Fields Required')
            setsubmitLoading(false)
            return false

            }

    try {
                                 var  statusupdate = (typeof status == 'undefined'  || status == ''|| status == '1')?'1':0;
      var postdata={_id:id ,name :name,content:content ,status:statusupdate};
 
      const response = await fetch(Url+"updateemailtemplate", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
                    redirectPage('emailtemplates')
          } else{
              setnotificationtype('success')
                    setnotification(data.message)
          }

           
       })
  setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    console.log(value)
    switch (name) {
        case 'name':
            setName(value);
             
             
            break;
         case 'content':
                    setContent(value);
                    break;        
         case 'status':
            setStatus(value);
            break;      
      
        default:
            break;      
    }
 }

 
useEffect(() => {
    Getemailtemplates(id);
  
              checkpermissionredirect(Allowemailtemplates_edit)

  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Email templates":"Add Email template"} />

         <Sidebar activePage="Email templates" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Email templates":"Add Email template"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Email templates":"Add Email templates"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleUserInput(e)}
                required/>
                        </div>
                        


                        <div className="col-md-4">
                            <label>Content</label>
                        </div>
                        <div className="col-md-8 form-group">
                   

                      <ReactQuill value={content}  onChange={setContent} name="content" placeholder="Enter your content*" rows="10" className="quill-manage-input"  />


                   {/*<textarea name="content"  onChange={(e) => handleUserInput(e)} rows="15" cols="100" className="form-control" value={content} >{content}</textarea>*/}

                  </div>
                   <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleUserInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>

                         {( submitLoading === false) ?  (   
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateemailtemplates}>Submit</button>
                         </div>
                         ) : '' }       
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default General;