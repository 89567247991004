import React, { useState , useEffect} from "react";
  import {
    Link 
} from "react-router-dom";

import {frontUrl,Url,SiteLogo,SiteLogoDark,AkuserId,Akusername, Notifyalert, RemoveNotificationAfterName ,UrlSplitter} from '../Frontapi.js';

  import {userIcon ,challengeIcon} from '../ImagesIcons.js';
 import Sidebar from "../includes/Sidebar";

    import Singlepost from "../pages/Singlepost"


  
 
 

 const Challenges = ( ) => {


const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
    

const [showpostvotesubmitoption,setShowpostvotesubmitoption] = useState(1)

 
 
 const postid = UrlSplitter(2);
 const challengeid = UrlSplitter(3);


const ShowVoteoptionnotexists =async() =>{

setShowpostvotesubmitoption(0)
 
      var postdata={'userid':AkuserId,'postid':postid,'challengeid':challengeid  };


  const response = await fetch(Url+"check-post-vote", {
        method: "POST",
        body: JSON.stringify({postdata }),
           headers: {
          "Content-Type": "application/json",
        } ,
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


            if(data.showaskvote == true){
               window.location.href=frontUrl
            }
             if (data.showsubmitvote) { 
                setShowpostvotesubmitoption(1)

             }

             
             
     
               

          } 

           
       })

   // 


}


const postYourvote =async() =>{

             setShowpostvotesubmitoption(0)

      var postdata={'userid':AkuserId,'postid':postid,'challengeid':challengeid  };

      

         const response = await fetch(Url+"post-submit-vote", {
        method: "POST",
        body: JSON.stringify({postdata }),
           headers: {
          "Content-Type": "application/json",
        } ,
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
 
             Notifyalert('success', data.message)

             
            
          
              //navigate("/my-profile")

           //   const get= document.getElementById('my-profile');  
            //  get.click();  

               

          } else{
               
          }

           
       })

}


 


/*********Vote*********/
 

  useEffect(() => {
    ShowVoteoptionnotexists()

  },[]);
 


return (
<div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">


            

           


 



 


     <div>
    <div className="page-container-section"  >
    <h1 className="page-container-title cur-poi"  >  Submit Your Vote</h1>
    </div> 


      <Singlepost postid={postid} />

      {(    showpostvotesubmitoption == 1)  ? 
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 post-vote">

          <a   className=" me-1 mb-1 new-post-main-btn"  onClick={   postYourvote}   >I vote</a>

                   


       </div>
       : ''}
 

      

     </div>
 
       






            </div>
         </div>
      </div>
   </div>
</div>

	)













 }
export default Challenges;