import React, { useState , useEffect} from "react";

import {Url,SiteLogo,SiteLogoDark,AkuserId,GetEmbedVideoUrl} from '../Frontapi.js';
 
  import ReactPlayer from 'react-player'


 const Video = ({videopostid}) => {


 
var screenheight =  parseInt(window.screen.height) - parseInt(175)


const [videolink,setvideolink]=useState('');

const [topic,settopic]=useState('');


const Getpostdetails =async(e) =>{

 
 


 var postdata={_id:videopostid, checkuserid:AkuserId}

         const response = await fetch(Url+"get-post", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 


  (async function() { 

            var postdata= data.data;

              
                var videolink =  await GetEmbedVideoUrl( postdata.youtubeurllink ) ;

                    setvideolink(videolink)
                  
                settopic(postdata.topic)


            })()    

          } else{
               
          }

           
       })
 
  

}


  useEffect(() => {
    Getpostdetails();

  
    },[] );

 
return (


 
               <div className="video-modal-options">

               <h2 className="video-post-topic">{topic}</h2>
<iframe width="100%" height={screenheight} src={videolink+'?&rel=0&loop=1&controls=1&showinfo=0&modestbranding=0'} title="How to setup whatsapp cloud API" frameborder="0"  allowfullscreen="allowfullscreen"></iframe>
              
              {/*<ReactPlayer className="reactplayer" width="100%" height="1000px"  url="https://www.youtube.com/watch?v=mh9neOt5zUo&t=2s" />
        
           */}
          
            </div>



	)













 }
export default Video;