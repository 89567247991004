import React, {    useState} from "react";
import {    useLocation,    useNavigate} from "react-router-dom";
import { SiteLogo,SiteFavicon ,  baseUrl,    Url,    Redirectdashboard,validEmailRegex,CommonNotify,UrlSplitter} from './../Api.js';
  

const Resetpassword = () => {
          Redirectdashboard();


   

        const token = UrlSplitter(3);

       
       

        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");

        const [password, setPassword] = useState("");
 
        const [confirm_password, setConfirmPassword] = useState("");
 
        const [formValid, setformValid] = useState(false);
        const [validationSubmit, setvalidationSubmit] = useState(false);

    const handleUserInput = (e) => {
       
            setnotification("");
         setTimeout(function(){       
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
            case 'password':
            setPassword(value);
            break;
            case 'confirm_password':
            setConfirmPassword(value);
            break;    

            }

            if(   password != confirm_password )  { setnotificationtype('error');  setnotification('Passwords not matched'); }
            (password != '' &&  password == confirm_password ) ? setformValid(true): setformValid(false)

        },200)
    
   }

        const Updatepassword = async (e) => {
            e.preventDefault();
            var postdata = {
                password: password,token:token
            };
            try {
                const response = await fetch(Url + "update-password", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setnotificationtype('success')
                                setnotification(data.message)
                        } else {
                           setnotificationtype('error')
                           setnotification(data.message)
                        }
                    })
            } catch (error) {
               // setnotificationtype('error')
                //setnotification(error)
            }
        };


        
      


  return (
 
  <div id="auth">
   <div className="container">
      <div className="row">
         <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card py-4">
               <div className="card-body">
                  <div className="text-center mb-5">
                     <img src={SiteLogo} height="48" className='mb-4'/>
                     <h3>Change Password</h3>
                  </div>
                  <form  >
                     <div className="form-group">
                        <label htmlFor="first-name-column">Password<span className="field-required">*</span></label>
                        <input  className="form-control"  type="password" name="password"   placeholder="Password"  
                           onKeyUp={(e) => handleUserInput(e)}
                        required/>
                     </div>
                     <div className="form-group">
                        <label htmlFor="first-name-column">Confirm Password<span className="field-required">*</span></label>
                        <input  className="form-control"  type="password" name="confirm_password"   placeholder="Confirm Password"  
                           onKeyUp={(e) => handleUserInput(e)}
                        required/>
                     </div>
                     <div className="clearfix">
                        <a href={baseUrl }   className='float-end'>
                        <small>Go back to Login</small>
                        </a>
                     </div>
                     { ( formValid) ?  (
                     <div className="clearfix">
                        <button className="btn btn-primary float-end" onClick={Updatepassword}>Submit</button>
                     </div>
                     ) : ''}
                     {
                     (notification!=='') ?  (
                     CommonNotify(notification,notificationtype)
                     ) :'' 
                     }
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
     
  );
};
export default Resetpassword;