import React, {
    useEffect,
    useState
} from "react";
import {
   
    Url,
    
    CommonNotify,
    UrlSplitter,checkEmptyUndefined,redirectPage
} from './../../Api.js';
import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";
import Footer from "../../includes/Footer";
const General = () => {
        const [notification, setnotification] = useState("");
        const [notificationtype, setnotificationtype] = useState("");
        const [id] = useState(UrlSplitter(4));
        const [name, setName] = useState("");
        const [permissions, setPermissions] = useState("");
         const [submitLoading, setsubmitLoading] = useState(false);
const[status, setStatus] = useState(1); 

       
        const Getrole = async (e) => {
            try {
                var postdata = {
                    _id: id
                };
                const response = await fetch(Url + "getrole", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            var roledata = data.data;
                            setName(roledata.name);
                            setStatus(roledata.status);
                            setPermissions(JSON.parse(roledata.permissions));
                            setSelectedCheckboxes(JSON.parse(roledata.permissions));
                        } else {
                            //   
                        }
                    })
            } catch (error) {
                //
            }
        }
        const Updaterole = async (e) => {
            e.preventDefault();
            setsubmitLoading(true)
            if(checkEmptyUndefined(name)=== false       ){
            setnotificationtype('error')
            setnotification('* Fields Required')
            setsubmitLoading(false)
            return false

            }

            const form = document.querySelector('form');
            const data = new FormData(form);

            var inputdata = {};
            data.forEach(function(value, key) {
                inputdata[key] = value;
            });
            
             var  statusupdate = (typeof status == 'undefined'  || status == ''|| status == '1')?'1':0;
            try {
                var postdata = {
                    _id: id,
                    name: inputdata.name,
                    permissions: inputdata,
                    status:statusupdate
 
                };
                 
                const response = await fetch(Url + "updaterole", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            setnotificationtype('success')
                            setnotification(data.message)
                            redirectPage('roles')
                        } else {
                             setnotificationtype('error')
                            setnotification(data.message)
                        }
                    })
                     setsubmitLoading(false)
            } catch (error) {
                
            }
        };
        const handleRoleInput = (e) => {
            setnotification("");
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'name':
                    setName(value);
                    break;
                 case 'status':
            setStatus(value);
            break;      
               
               
            }
        }
        useEffect(() => {
            Getrole(id);
         }, []);



const checkValuePresentorNot=(value)=>{
    //alert(value)

    if(typeof permissions[value] !== 'undefined'  && permissions[value] == '1'){
         return 'checked'
    }

    

   
}

const  setSelectedCheckboxes=async(permissions)=>{
 
  Object.keys(permissions).forEach(function(key) {
   var checkbox = document.querySelector('input[name='+key+']');
   if( typeof checkbox!== 'undefined'   ){ 
  checkbox.checked = (typeof permissions[key] !== 'undefined'  && permissions[key] == '1') ? true : false;
    }
 });
 
 
}
    

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Role":"Add Role"} />

         <Sidebar activePage="Roles" activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Role":"Add Role"}/>
                              <div className="row match-height">
            <div className="col-md-9 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Role":"Add Role"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal" id="roles-form">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Name</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                onChange={(e) => handleRoleInput(e)}
                required/>
                        </div>
<table className="table table-bordered" cellpadding="5" cellspacing="5">
   <thead>
      <tr>
         <td>  Name  </td>
         <td>  View</td>
         <td>Add </td>
         <td>  Edit</td>
         <td> Delete </td>
      </tr>
   </thead>
   <tbody>
      <tr>
         <td> Dashboard</td>
         <td><input type="checkbox"     name="dashboard_view" value="1" /> </td>
         <td colspan="3"> </td>
      </tr>
      <tr>
         <td> Settings</td>
         <td><input type="checkbox"     name="settings_view" value="1" /> </td>
         <td  > </td>
         <td><input type="checkbox"    name="settings_edit" value="1" /> </td>
         <td  > </td>
      </tr>
      <tr>
         <td> User</td>
         <td><input type="checkbox"      name="users_view" value="1" /> </td>
         <td  ><input type="checkbox"     name="users_add" value="1" /> </td>
         <td><input type="checkbox"    name="users_edit" value="1" /> </td>
         <td  > <input type="checkbox"     name="users_delete" value="1" /></td>
      </tr>


        <tr>
         <td> CMS</td>
         <td><input type="checkbox"      name="cms_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="cms_edit" value="1" /> </td>
         <td  >  </td>
      </tr>

       <tr>
         <td> Email Templates</td>
         <td><input type="checkbox"      name="emailtemplates_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="emailtemplates_edit" value="1" /> </td>
         <td  >  </td>
      </tr>


        <tr>
         <td> Posts</td>
         <td><input type="checkbox"      name="posts_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="posts_edit" value="1" /> </td>
         <td  >  </td>
      </tr>


      <tr>
         <td> Ads</td>
         <td><input type="checkbox"      name="ads_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="ads_edit" value="1" /> </td>
         <td  >  </td>
      </tr>

      <tr>
         <td> Orders</td>
         <td><input type="checkbox"      name="orders_view" value="1" /> </td>
         <td  >  </td>
         <td><input type="checkbox"    name="orders_edit" value="1" /> </td>
         <td  >  </td>
      </tr>


      <tr>
         <td> Enquiries</td>
         <td><input type="checkbox"      name="enquiries_view" value="1" /> </td>
         <td  >  </td>
         <td> </td>
         <td  >  </td>
      </tr>

      <tr>
         <td> Streams</td>
         <td><input type="checkbox"      name="streams_view" value="1" /> </td>
         <td  >  </td>
         <td>  </td>
         <td  >  </td>
      </tr>

      <tr>
         <td> Schools</td>
         <td><input type="checkbox"      name="schools_view" value="1" /> </td>
         <td  ><input type="checkbox"      name="schools_add" value="1" />   </td>
         <td> <input type="checkbox"      name="schools_edit" value="1" />  </td>
         <td  ><input type="checkbox"      name="schools_delete" value="1" />   </td>
      </tr>


      <tr>
         <td> Subjects</td>
         <td><input type="checkbox"      name="subjects_view" value="1" /> </td>
         <td  > <input type="checkbox"      name="subjects_add" value="1" />  </td>
         <td> <input type="checkbox"      name="subjects_edit" value="1" />  </td>
         <td  > <input type="checkbox"      name="subjects_delete" value="1" />  </td>
      </tr>


        <tr>
         <td> Clients</td>
         <td><input type="checkbox"      name="clients_view" value="1" /> </td>
         <td  >  </td>
         <td>  </td>
         <td  >  </td>
      </tr>


   </tbody>
</table>
<div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleRoleInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>
                         {( submitLoading === false) ?  (  
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updaterole}>Submit</button>
                         </div>
                             ) : '' }
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default General;