import React, { useState ,useEffect} from "react";
   import {
    Link,
    useNavigate
} from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Timepicker from 'react-time-picker';  
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { CTimePicker } from '@coreui/react-pro'

  
 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

  import {angleLeft ,calendarIcon,clockIcon} from '../ImagesIcons.js';


import {frontUrl,SiteLogo,SiteLogoDark,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';


const AddLivestream = () => {

  const navigate = useNavigate();

  const [value, onChange] = useState('10:00');

const [currentDate, setCurrentDate] = useState(new Date());


const [topic,setTopic]= useState('');
const [caption,setCaption]= useState('');
const [meetinglink,setMeetinglink]= useState('');



const [date,setDate]= useState('');
const [time,setTime]= useState('');

const [displaydate,setdisplaydate]= useState('');
const [displaytime,setdisplaytime]= useState('');




const [showdatePickeroption,setshowdatePickeroption]= useState(0);

const ShowDatepickerSection=(value)=>{
 	setshowdatePickeroption(value)
}

const setDatevalue=(value)=>{
const d = new Date(value).toLocaleDateString('en-US');
 	setDate(d)


var displaydate = new Date(value).toLocaleDateString('en-GB');

setdisplaydate(displaydate)
 	setshowdatePickeroption(0)

}
const setTimevalue=(value)=>{



 var timevalue = value.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [value];

  if (timevalue.length > 1) { // If time format correct
    timevalue = timevalue.slice (1);  // Remove full string match value
    timevalue[5] = +timevalue[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    timevalue[0] = +timevalue[0] % 12 || 12; // Adjust hours
  }
 
   setdisplaytime(timevalue.join (''))


	setTime(value)


}



const Createnewstream =async(e) =>{

  e.preventDefault();



  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-10');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-10');
                        error=1;

                       

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });



              if(error === 1){
                    return false
            }
               if(date === ''  ){
setshowdatePickeroption(1)
                    return false


              		}
              		               if(time === ''  ){

              		                  SetNotificationAfterName('time', 'time' + ' required','noneed')

              		               }
             	


             /***Check Required***/



       
     

      var postdata={userid:AkuserId,topic:topic,caption:caption, meetinglink : meetinglink, date:date ,time:time }


         const response = await fetch(Url+"create-new-stream", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

            Notifyalert('success', data.message)

 
                           window.location.href=frontUrl+'livestreams'

           //  document.getElementById("addstreamform").reset()
                             
              

          } else{
            if(typeof data.message !='undefined'){
               Notifyalert('error', data.message) 
            }
                 
          }

           
       })
 
  

}


 
  return (

						<div>
   <Header pageInfo="Add Stream" />



						<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
						<div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 


						<Sidebar />

						</div>
						<div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">


						<div className="page-content">


						<div className="container-mobile">

						 <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi"   > <span className="goto-section" ><Link  to='/my-streams' >  <img src={angleLeft} /> </Link> </span> Add New Stream

</h1>
                  </div>

                  	<form id="addstreamform" >

 <input    autoComplete="off"   type="text" name="topic" onChange={(e)=>setTopic(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Topic"    />

 <input    autoComplete="off"   type="text" name="caption" onChange={(e)=>setCaption(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Caption"    />



 <input    autoComplete="off"   type="text" name="meetinglink" onChange={(e)=>setMeetinglink(e.target.value)} className="form-control stream-input mb-25 MustEnterField"   placeholder="Meeting Link"    />



 				<div className="date-time-stream ">


 				  				 <div className="date-picker-section cur-poi" onClick={(value)=>ShowDatepickerSection(1)}  > 

 				<span ><img src={calendarIcon}  />   <span> Select Date</span> </span> 
 </div>

  				  				 <div className="date-picker-section"  > 


 				 				<span className="display_flex" ><img src={clockIcon}  /> <span>      <input name="time" onChange={(e) => setTimevalue(e.target.value)}  className="time-picker-input MustEnterField" type="time" /> 
 </span>  </span> 

 </div>

 					    


 				 </div>


 				 <div className="date-time-picker-section"  > 
 				 {(showdatePickeroption == 1) ?
 				  				 <div className="date-picker-section"  > 
 				  				 	 <DatePicker dateFormat="MMMM d, yyyy" onChange={(date) => setDatevalue(date)}    minDate={currentDate}
       inline />

 				  				  </div>:''}
 				  				  <div className="time-picker-section"  > 
 				  				  	 
 				  				  </div>


 				
 				 </div>

{(displaydate != '' ) ?  
 		 <p className="live-stream-select-date">  Selected Date & Time :  <span>  {displaydate} </span><span>  {displaytime} </span></p>
: '' }

                  <button onClick={Createnewstream}  className=" me-1 mb-1 submit-stream-btn mt-50"   >Confirm</button>


                  	</form>



						</div>
						</div>

						</div>
						</div> </div>
                     )


}



export default AddLivestream;