import React, {useEffect, useState } from "react";

 import { Link } from 'react-router-dom';

  import {convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,checkpermissionredirect} from './../../Api.js';

  import {    
   Allowads_view ,
   Allowads_edit
 } from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

 
 
 

 const [adsList, setadsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(adsList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetPosts()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetPosts()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetPosts(value)  
          
               
         
            
           

        }  

  const GetPosts = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage,admin:1,ads:1};

        const response = await fetch(Url+"getposts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setadsList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };



/****POPUP REJECTION****/
  const [rejectpostid,setRejectpostid]= useState();
const [rejectpostusername,setRejectpostusername]= useState();
const [comment,setComment]= useState('');

  const setCommentText= async (e) => {


      var value = e.target.value;

      setComment(value)
   }


const ShowRejectConfirmPopup =async(id,username)=>{

setRejectpostid(id)
setRejectpostusername(username)

 setTimeout(function(){
        if(document.getElementById('commonmodal')){
            document.getElementById('commonmodal').style.display='none'
        }


        document.getElementById('commonmodal').style.display='block'

        },200)

}



const closeLogoutModal=async() =>{
document.getElementById('commonmodal').style.display='none'

}

/****POPUP REJECTION****/



  const RejectPost = async ( ) => {
        var postdata={'id' : rejectpostid , status : 2 , comment : comment };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              UpdateNotify(data.message, 'success')
            GetPosts(1)
              setComment('');
            closeLogoutModal();
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
            
             
          }

           
        })

     
}

  const ApprovePost = async (id) => {
     
    if(window.confirm("Are you sure?")){
        var postdata={'id' : id , status : 1 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           UpdateNotify(data.message)
            GetPosts(1)
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
             
             
          }

           
        })

    }

     


  };


  

  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = adsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(adsList.length / recordsPerPage)



//pagination part
  
const updateEntriescount = async() => {
  var postdata={ type:'ads'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}


          useEffect(() => {
   GetPosts();

            checkpermissionredirect(Allowads_view)

  updateEntriescount()


  }, [comment]);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Posts List" />

         <Sidebar activePage="Ads" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Ads"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Ads</span>
             </div>
            <div className="card-body">

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                         <th>Username / Email / Phone</th>
                            <th>Topic</th>
                            


                            <th>URL</th>
                             <th>Comments</th>

                            <th>Date</th>
                            
                            <th>Status</th>
                             <th>Action</th>  
                        </tr>
                    </thead>
                      {(adsList.length > 0) ? 

                    <tbody>

 
                       {adsList.map((ads) => (
                        <tr>


                            <td>
                            {ads.username}

                            {(ads.useremail != '')?  ' / '+ads.useremail : ''    }

                                                        {(ads.userphone != '')?  ' / '+ads.userphone : ''    }


                             



                            </td>


                            <td>{ads.topic}</td>



                            <td>{ads.youtubeurllink}</td>
                            <td>{ (ads.commentscount > 0)  ?  <Link className="cur-poi" to={baseUrl+'comments/'+ads._id} > <i className="fa fa-eye"> </i></Link>  : '-' }</td>

                            <td>  {  ads.createddatetime   }   </td>
                          
                            <td>
                                {(ads.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}
                            </td>
                            <td className="table-action-sections table-action-sections-post ">

                            {(Allowads_edit == 1) ? 
                            <div>
                            {(ads.status == 1) ? '' :   <a     onClick={() => ApprovePost(ads._id)}  className="fa-edit-btn"    ><i className="fa fa-check"></i> </a> }
   
                            <a     onClick={() => ShowRejectConfirmPopup(ads._id)}  className="fa-del-btn"    ><i className="fa fa-close"></i> </a></div>
                            : ''}

                             </td> 
                        </tr>
                        
                           ))}
                        
                    </tbody> :<tbody><tr> <td colspan="8" align="center">No Ads Found</td></tr></tbody>}
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>


<div   className="commonmodal display_none" id="commonmodal">
   <div className="newmodal-dialog">
      {/*<span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>*/ }
      <div className="newmodal-content"  >
         <h3 >Post Rejection </h3>
        
            <p className="text-dark"  >Are you sure you want to reject this post? </p>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">  
            <textarea rows="5"     onChange={(e) => setCommentText(e)}
 className="width-100-percent amigo-text-popup" value={comment}>{comment}</textarea>
            </div>

            <div className="proceed-confirm-box">  


          
   <button className="confirm-btn proceed-green" onClick={RejectPost}    >Yes </button>


              <button  className="close   confirm-btn cancel-red" onClick={(e)=>
         closeLogoutModal(e)} >No </button>
            

          

           
         </div>
      </div>
   </div>
</div>



                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;