import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined} from './../Api.js';
 
 import {    
    Allowsettings_edit
} from './../Permissions.js';


const Smtp = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [smtphost, setSmtphost] = useState("");
  const [smtpusername, setSmtpusername] = useState("");
  const [smtppassword, setSmtppassword] = useState("");
  const [smtpport, setSmtpport] = useState(""); 
           const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setSmtphost(settingsdata.smtphost );
             setSmtpusername(settingsdata.smtpusername );
             setSmtppassword(settingsdata.smtppassword );
             setSmtpport(settingsdata.smtpport );
           

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updatesmtpsettings = async (e) => {
    e.preventDefault();
    setnotification("");
     setsubmitLoading(true)
     if(checkEmptyUndefined(smtphost) === false || checkEmptyUndefined(smtpusername) === false|| checkEmptyUndefined(smtppassword) === false|| checkEmptyUndefined(smtpport) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {

      var postdata={smtphost:smtphost,smtpusername:smtpusername,smtppassword:smtppassword,smtpport:smtpport   };
 
 
      const response = await fetch(Url+"update-smtp-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                     setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           
       })
       setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'smtphost':

              setSmtphost(value);
             break;
        case 'smtpusername':
            setSmtpusername(value);
            break;
       case 'smtppassword':
           setSmtppassword(value );
            break;
         case 'smtpport':
             setSmtpport(value );
            break;
             default :
              break;
    }


 }
   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Host<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtphost" placeholder="Host" value={smtphost} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Username<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtpusername" placeholder="Username" value={smtpusername} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Password<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="password" name="smtppassword" placeholder="Password" value={smtppassword} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Port<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="smtpport" placeholder="Port" value={smtpport} onChange={(e) => handleUserInput(e)} required/>
                                </div>

                                
                                  {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                 {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatesmtpsettings}>Submit</button> : ''}
                               </div>
                                 ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Smtp;