import React, {useEffect, useState } from "react";
  import {baseUrl,Url,ModalPopupDelete,DeleteNotify,checkpermissionredirect} from './../../Api.js';



import {    
    Allowschools_view ,
   Allowschools_add ,
   Allowschools_edit ,
   Allowschools_delete
} from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";


//import Pagination from "../../includes/Pagination";

 
const Schoollist = () => {

 
 
 

 const [schoolsList, setschoolsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(schoolsList.length / recordsPerPage)
const [name, setName] = useState("");
const [pincode, setPincode] = useState("");

const [namesearch, setNameSearch] = useState("");
const [pincodesearch, setPincodeSearch] = useState("");
 var [s_no, setSno]=useState(1)
 

 const goToFirstPage = () => {
       
                setCurrentPage(1)
             GetSchools( 1)

         
   }
 const goToLastPage = () => {

        
                setCurrentPage(pageNumbers.length)
             GetSchools( pageNumbers.length)

         
   }


 const goToNextPage = () => {
           //   if(currentPage !== nPages) {
 

                setCurrentPage(currentPage + 1) 
                GetSchools(currentPage + 1)
           //  }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
                setCurrentPage(currentPage - 1)
             GetSchools(currentPage - 1)

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetSchools(value)  
          
               
         
            
           

        }  

  const GetSchools = async (value ,reset='') => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)
    value = (typeof value !== 'undefined') ? value : currentPage;

    
    if(reset == 'reset'){
        

           var postdata={'currentpage':value,'perpage':recordsPerPage ,'name':'','pincode':''  };

    }else{
        
      var postdata={'currentpage':value,'perpage':recordsPerPage,'name':name ,'pincode':pincode  };

        
    }

     

        const response = await fetch(Url+"getschools", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setschoolsList(data.data );
        if(data.count > 0){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };


  const DeleteConfirm = async (id) => {

    if(window.confirm("Are you sure?")){
        var postdata={'id' : id };
        const response = await fetch(Url+"deleteschool", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
                   

          if (data.success) { 
            DeleteNotify();
              

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
                 
          }

           
        })

    }

     


  };



  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = schoolsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(schoolsList.length / recordsPerPage)



//pagination part
  


      
   
     const handleSearchInput = (e) => {
     var name = e.target.name;
    var value = e.target.value;
     
    switch (name) {
        case 'name':
            setName(value);
             
             
            break;
        case 'pincode':
            setPincode(value);
             
             
            break;    

    }
  }
 

  const Searchstart = async () => {




 
     GetSchools(1);
 

  }
  const Resetsearch = async () => {
  setName('');
  setPincode('');    
    GetSchools(1,'reset');
     //window.location.reload();
 
   }

    useEffect(() => {
                        checkpermissionredirect(Allowschools_view)

   GetSchools();
  }, []);

  //pagination part

  return (
      <div id="app">
            <Header title="Schools List" />

         <Sidebar activePage="Schools" activeChildPage="List" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Schools" />

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
                <span>Schools</span>
                     <div className="pull-right"><a href={baseUrl+'schools/add'}  className="btn btn-success me-1 mb-1">Add School</a></div>
            </div>
            <div className="card-body">

                <div className="col-md-12 col-lg-12 row"> 

                    <div className="col-md-3">

                       <div className="col-md-12">
                            <label>Name  </label>
                            </div>
                            <div className="col-md-12 form-group">
                            <input className="form-control"  value={name}  type="text" name="name" placeholder="Name"  
                            onChange={(e) => handleSearchInput(e)}
                            required/>
                            </div> 


                    </div>
                    <div className="col-md-3">

                       <div className="col-md-12">
                            <label>Pincode  </label>
                            </div>
                            <div className="col-md-12 form-group">
                            <input className="form-control"  value={pincode}  type="text" name="pincode" placeholder="Pincode"  
                            onChange={(e) => handleSearchInput(e)}
                            required/>
                            </div> 


                    </div>
                    <div className="col-md-1">
                    <br/>
                        <a  onClick={Searchstart}  className="btn btn-success me-1 mb-1">Search</a>
                    </div>
                     <div className="col-md-1">
                    <br/>
                        <a  onClick={Resetsearch}  className="btn btn-success me-1 mb-1">Reset</a>
                    </div>

                </div>


       

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>

                          <th>S.No</th>
                            <th>Name</th>
                            <th>Pincode</th>
                           
                           
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                       {schoolsList.map((school) => (

                        <tr>
                         <td>{(s_no++)}    </td>
                            <td>{school.name}</td>
                            <td>{school.pincode}</td>
                           
                            <td>
                                {(school.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}
                            </td>
                            <td  className="table-action-sections">

 
 

                             {(Allowschools_edit == 1) ?
                            <a href={baseUrl+'schools/edit/'+school._id} className="fa-edit-btn"  > <i className="fa fa-pencil"></i></a>: ''}
  
                             {(Allowschools_delete == 1) ?

                            <a      onClick={() => DeleteConfirm(school._id)}  className="fa-del-btn"    ><i className="fa fa-trash"></i> </a>: ''}


                              

                             </td>
                        </tr>
                        
                           ))}
                        
                    </tbody>
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 
       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>


      <li className="page-item  ">
         <a className="page-link" 
            onClick={goToFirstPage} 
            href='#'>
          &lt;&lt;
         </a>
      </li>


       {pageNumbers.map(pgNumber => (


      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >

        {((currentPage-pgNumber == 1   ) ||  (pgNumber-currentPage ==1) || (pgNumber==currentPage  )  ) ?

      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
:''}

      </li>



      ))}




      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>



      <li className="page-item  ">
         <a className="page-link" 
            onClick={goToLastPage} 
            href='#'>
          &gt;&gt;
         </a>
      </li>


   </ul>
</nav> : ''

}

    </section>

                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Schoollist;