import React, { useState } from "react";
 import {  useNavigate } from "react-router-dom";


 import {SiteLogo,baseUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined} from './../Api.js';
import Header from "../includes/Header";

 
const Login = () => {

Redirectdashboard();
 

const navigate = useNavigate();
 
const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");
const [email, setEmail] = useState("");
const [password, setPassword] = useState("");
const [emailError, setEmailerror] = useState(false);
const [emailvalid, setEmailvalid] = useState("");
const [passwordvalid, setPasswordvalid] = useState("");
const [formValid, setformValid] = useState(true);
const [validationSubmit, setvalidationSubmit] = useState(false);
const [submitLoading, setsubmitLoading] = useState(false);


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'email':
            setEmail(value);
            setEmailvalid(true);
            validEmailRegex.test(value) ? setEmailerror(false) : setEmailerror(true)
            break;
        case 'password':
            setPassword(value)
            setPasswordvalid(true);
            break;
    }
  //  (email != '' && password != '') ? setformValid(true): setformValid(false)
}


const signIn = async (e) => {
    e.preventDefault();

    setsubmitLoading(true)
      if(checkEmptyUndefined(email) === false  || checkEmptyUndefined(password) === false){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    setvalidationSubmit(true)
    var postdata = {
        email: email,
        password: password
    };
    try {
        const response = await fetch(Url + "login", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success) {
                    var userdata = data.data;
                    localStorage.setItem('userId', userdata._id)
                    localStorage.setItem('userEmail', userdata.email)
                    localStorage.setItem('userName', userdata.username)
                    window.location.href=baseUrl+'dashboard';
                } else {
                    setnotificationtype('error')
                    setnotification(data.message)
                }
            })
              setsubmitLoading(false)
    } catch (error) {
        
    }
};
 


  return (
  <div id="auth">
   <div className="container">
      <div className="row">
         <div className="col-md-5 col-sm-12 mx-auto">
            <div className="card pt-4">
               <div className="card-body">
                  <div className="text-center mb-5">
                     <img src={SiteLogo} height="48" className='mb-4' />
                     <h3>Sign In</h3>
                     <p>Please sign in to continue.</p>
                  </div>
                  <form  autocomplete="off" >
                     <div className="form-group position-relative has-icon-right">
                        <label htmlFor="username">Email Address / Username <span className="field-required">*</span> </label>
                        <div className="position-relative">
                           <input   type="email" name="email" className="form-control {  (!emailvalid && validationSubmit) ?  '  is-invalid'  : ''  }"   placeholder="Username@gmail.com"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>
                           <div className="form-control-icon">
                              <i data-feather="user"></i>
                           </div>
                        </div>
                     </div>
                     <div className="form-group position-relative has-icon-right">
                        <div className="clearfix">
                           <label htmlFor="password">Password<span className="field-required">*</span> </label>
                           <a href={baseUrl+'forgot-password'}   className='float-end'>
                           <small>Forgot password?</small>
                           </a>
                        </div>
                        <div className="position-relative">
                           <input  className="form-control {  (!passwordvalid && validationSubmit) ?  '  is-invalid'  : ''  }"   type="password" name="password" placeholder="············"  
                              onKeyUp={(e) => handleUserInput(e)}
                           required/>
                           <div className="form-control-icon">
                              <i data-feather="lock"></i>
                           </div>
                        </div>
                     </div>
                     { (!emailError && formValid && submitLoading===false) ?  (

                     <div className="clearfix">
                        <button className="btn btn-primary float-end"  onClick={signIn}>Submit</button>
                     </div>
                     ) :'' } 
                     {
                     (notification!=='') ?  (
                     CommonNotify(notification,notificationtype)
                     ) :'' 
                     }
                  </form>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
 
  );
};
export default Login;