import React from "react";
import {    useLocation,    useNavigate} from "react-router-dom";
  
 import {Vision,SiteLogo,SiteLogoDark,SiteFavicon, Banner} from './ImagesIcons.js';


 
 import 'react-notifications/lib/notifications.css';
import {NotificationContainer, NotificationManager} from 'react-notifications';

import swal from 'sweetalert'


const GetfrontUrl_Url = async(param) => { 
 
if(window.location.origin === 'http://localhost:3000'){
   if(param === 'base_url'){
         return   'http://localhost:3000/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5020/' ;
    } 
    if(param === 'socekturl'){
         return   'http://localhost:5030/' ;
    }
     
}else if(window.location.origin === 'http://localhost'){

    if(param === 'base_url'){
         return   'http://localhost/' ;
     }
    if(param === 'url'){
         return   'http://localhost:5020/' ;
    } 
    if(param === 'socekturl'){
         return   'http://localhost:5030/' ;
    }
      


}else if(window.location.origin === 'https://dev.alfakidz.com'){
    if(param === 'base_url'){
         return   'https://dev.alfakidz.com/' ;
    }
    if(param === 'url'){
         return   'https://api.alfakidz.com/?url=' ;
    } 

     if(param === 'socekturl'){
         return   'https://wso.alfakidz.com/' ;
    }


}else if(window.location.origin === 'https://alfakidz.com'){
    if(param === 'base_url'){
         return   'https://www.alfakidz.com/' ;
    }
    if(param === 'url'){
         return   'https://www.alfakidz.com/api/?url=' ;
    } 

     if(param === 'socekturl'){
         return   'https://wso.alfakidz.com/' ;
    }


}else if(window.location.origin === 'https://www.alfakidz.com'){
    if(param === 'base_url'){
         return   'https://www.alfakidz.com/' ;
    }
    if(param === 'url'){
         return   'https://www.alfakidz.com/api/?url=' ;
    } 

     if(param === 'socekturl'){
         return   'https://wso.alfakidz.com/' ;
    }


}else if(window.location.origin === 'https://alfakidz.amigoways.in'){
    if(param === 'base_url'){
         return   'https://alfakidz.amigoways.in/' ;
    }
    if(param === 'url'){
         return   'https://www.alfakidz.com/api/?url=' ;
    } 

     if(param === 'socekturl'){
         return   'https://wso.alfakidz.com/' ;
    }


}


}

const frontUrl=await GetfrontUrl_Url('base_url')
const Url=await GetfrontUrl_Url('url')
 

const Socketiourl=await GetfrontUrl_Url('socekturl')


const userProfileurl=frontUrl+'u/';
const postDetailurl=frontUrl+'p/';

const editPosturl=frontUrl+'edit-post/';
const votePosturl=frontUrl+'v-post/';


const redirectfrontPage = (page) => {
  window.location.href=frontUrl+page;

 // browserHistory(frontUrl+page)




}

const Redirectdashboard = () => {

 if(localStorage.getItem('AKuserId') !== '' && localStorage.getItem('AKuserId') !== null ){
     window.location.href=frontUrl;
    }
}

const Redirectlogin = () => {
   if(localStorage.getItem('AKuserId') === '' || localStorage.getItem('AKuserId') === null){
   window.location.href=frontUrl ;
   }
}

const Redirecttermsagreement = () => {
   window.location.href=frontUrl+'terms-agreement';
  
}

const Redirecttermsagreementstudent = () => {
   
  window.location.href=frontUrl+'terms-agreement-student';
  
}



const CommonNotify = (data,type) => {
  
  if(type === 'success') {
    // NotificationManager.success(data);
  }
   if(type === 'error') {
   //  NotificationManager.error(data);
  }


   
 

  return (
    <div>   
    <div id="alert" className={type === 'success' ? 'alert alert-success' : 'alert alert-danger'} >{data} </div>
    </div>
    )
 
}

const checkEmptyUndefined=(data)=>{

if(typeof data === 'undefined' || data === ''){
  return false
}
return true

}
 


 const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

 const validPhoneRegex = RegExp(
        /^\+[1-9]{1}[0-9]{3,14}$/

);
 const validPasswordRegex = RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&])[A-Za-z\d@.#$!%*?&]{8,15}$/



);


 



const containerStart =  '<div className="container-mb"> '  
const containerEnd =  '</div>'  


const AkuserId=localStorage.getItem('AKuserId');
const Akusertype=localStorage.getItem('AKusertype');
const Akusername=localStorage.getItem('AKusername');

 const AKcountsupdated=localStorage.getItem('AKcountsupdated');


 

const Notifyalert = (type,message) => {

     if(type == 'error' ){


  swal({
  title: message,
   timer: 2000,
   type: 'success',
  showCancelButton: false,
  showConfirmButton: false,
  buttons: false,
  icon: "error" 
 
})
    //    swal(" ", message, "error");



        // NotificationManager.error(message);
    }else{ 
/*
document.getElementById("notify-alert-notification").innerHTML=message;

document.getElementById("notify-alert-notification").classList.add('fadein')
          document.getElementById("notify-alert-notification").classList.remove('fadeout')
          
       
          setTimeout(function(){
 document.getElementById("notify-alert-notification").classList.remove('fadein')
          document.getElementById("notify-alert-notification").classList.add('fadeout')
            },2000)
 */

        swal({
  title: message,
   timer: 2000,
   type: 'success',
  showCancelButton: false,
  showConfirmButton: false,
  buttons: false,
  icon: "success" 
 
})

     //swal(" ", message, "success");

     

         // NotificationManager.success(message);
          setTimeout(function(){
               //   window.location.reload()
          },500)
    }



  
}
const UrlSplitter = (params) => {
         const location = useLocation();
            var url =location.pathname;
            var urls=url.split('/')
            return urls[params]
}


const TriggerLogout = (data) => {

localStorage.removeItem('termsReloaded');

localStorage.removeItem('AKuserId');
 localStorage.removeItem('AKusertype');
    localStorage.removeItem('AKcountsupdated');
       localStorage.removeItem('AKusername');
        localStorage.removeItem('AKreferralcode');
         localStorage.removeItem('dashboardReloaded');

      
}



const SetNotificationAfterName=(name,message,noneed='')=>{
var element= document.getElementsByName(name)[0] ;

                 if(noneed != '' && noneed != null && typeof noneed != 'undefined'){

                
                }else{
                    var requiredelement = document.createElement("div");
                requiredelement.classList.add('notify-input-text');
                requiredelement.innerHTML=   message
                     element.after(requiredelement);
                }
               
                 element.focus();


}

const RemoveNotificationAfterName=()=>{
if( document.querySelector(".notify-input-text")){
     if(document.getElementsByClassName('notify-input-text')[0]){
  document.getElementsByClassName('notify-input-text')[0].remove();

             } 
}
  if( document.querySelector(".notify-input-missing")){

  document.getElementsByClassName('notify-input-missing')[0].classList.add('mb-25');
  document.getElementsByClassName('notify-input-missing')[0].classList.remove('notify-input-missing');
}

   
}
const getUseragreementcounts=async(AkuserIdVal)=>{

var userid= AkuserId;
if(typeof AkuserIdVal != 'undefined' &&   AkuserIdVal != '' &&   AkuserIdVal != null){
    userid= AkuserIdVal;
}
 




if(typeof userid != 'undefined' &&   userid != '' &&   userid != null){
  var postdata = {
        userid: userid
    };
var getUseragreement =  await  fetch(Url + "getuseragreement", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
         postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {

            var agreedCounts=0;

         if (data.success &&  data.data !== null) {
            var userdata = data.data;
        

            if(typeof userdata.postcontent != 'undefined' &&  userdata.postcontent != '0' && userdata.postcontent != ''){

              agreedCounts = agreedCounts + 1;

            }
             if(typeof userdata.youtubepostcontent != 'undefined' &&  userdata.youtubepostcontent != '0' && userdata.youtubepostcontent != ''){

              agreedCounts = agreedCounts + 1;

            }
             if(typeof userdata.adultpostcontent != 'undefined' &&  userdata.adultpostcontent != '0' && userdata.adultpostcontent != ''){

              agreedCounts = agreedCounts + 1;

            }
             if(typeof userdata.afterpostcontent != 'undefined' &&  userdata.afterpostcontent != '0' && userdata.afterpostcontent != ''){

              agreedCounts = agreedCounts + 1;

            }
             if(typeof userdata.kidscontent != 'undefined' &&  userdata.kidscontent != '0' && userdata.kidscontent != ''){

              agreedCounts = agreedCounts + 1;

            }
             if(typeof userdata.advcontent != 'undefined' &&  userdata.advcontent != '0' && userdata.advcontent != ''){

              agreedCounts = agreedCounts + 1;

            }
            if(typeof userdata.invitecontent != 'undefined' &&  userdata.invitecontent != '0' && userdata.invitecontent != ''){

              agreedCounts = agreedCounts + 1;

            }

             
            
        return agreedCounts;
         
        }  else{
              return agreedCounts;

        }
    })


    return getUseragreement;
}
}



const RedirectDashboard_Login=async()=>{
 

 if(localStorage.getItem('AKuserId') === '' || localStorage.getItem('AKuserId') === null){
            Redirectlogin()

        }else{

             var  counts = await getUseragreementcounts();
   if(parseInt(Akusertype)  ===  parseInt(1) ){
  if(parseInt(counts)  ==  parseInt(7) ){

      localStorage.setItem('AKcountsupdated','updated')
                Redirectdashboard(); 
                   
                    
                } 
             }
                if(parseInt(Akusertype)  ==  parseInt(2) ){
  if(parseInt(counts)  ===  parseInt(6) ){
      localStorage.setItem('AKcountsupdated','updated')
                     
                      Redirectdashboard();
                    
                
                } 
             }

        }

}

const RedirectLogin_Terms=async()=>{

   
        if(localStorage.getItem('AKuserId') === '' || localStorage.getItem('AKuserId') === null){
            Redirectlogin()

        }else  if(localStorage.getItem('AKuserId') !== '' && localStorage.getItem('AKuserId') !== null){

           if(localStorage.getItem('AKcountsupdated') == null)
        {
             var  counts = await getUseragreementcounts();
   if(parseInt(Akusertype)  ===  parseInt(1) ){

                if(counts != 7){
                Redirecttermsagreement(); 
                   
                    
                } else{
                     localStorage.setItem('AKcountsupdated','updated')
                }
                      

             }
                if(parseInt(Akusertype)  ===  parseInt(2) ){

 
                if(counts != 6){
                                        
                      Redirecttermsagreementstudent();
                    
                
                } else{
                     localStorage.setItem('AKcountsupdated','updated')
                }

             }
        }


 

}
 

}




const Redirectdashboard_Terms=async(AkuserIdVal,Akusertypeval)=>{
 
     var  counts = await getUseragreementcounts(AkuserIdVal);


     
    if(AkuserIdVal !== '' && AkuserIdVal !== null){

  

 
  if(parseInt(Akusertypeval)  ===  parseInt(1) ){

 
  if(parseInt(counts)  ===  parseInt(7) ){

                        localStorage.setItem('AKcountsupdated','updated')
 
                  Redirectdashboard();
                  
                }else{
                     
                    Redirecttermsagreement(); 
                         
                       
                    
                   
                }
             }
  if(parseInt(Akusertypeval)  ===  parseInt(2) ){

  if(parseInt(counts)  ===  parseInt(6) ){
                              localStorage.setItem('AKcountsupdated','updated')
                

                   Redirectdashboard();
                
                }else{
                   
                Redirecttermsagreementstudent();
                   
                }
             }

 

}

}





const checkTermsAgreed = async(noredirect) => {

 /* 
 */
}




const checkRedirectAfterLogin=async()=>{
/*
checkTermsAgreed();

 
*/

}
/*
const Getstandards=async()=>{

var standards={0:'1st'};

return standards;
}


*/
const Getstandardvalues = '';


const CheckUserExists = async() => {   


var postdata={  userid:AkuserId}
var result =  await  fetch(Url + "check-user", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
    
     if(!data.success){
       TriggerLogout(); 
    }
         


         
         
    })

//
}

const CheckUserExistsv=await CheckUserExists()



const GetProfilePicturedata = async(param) => {   
if(typeof AkuserId != 'undefined' && AkuserId != null){ 

var postdata={  userid:AkuserId}
var result =  await  fetch(Url + "get-user-profile", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
          postdata
        }),
    }).then(async (response) => response.json())
    .then(async  (data) => {
 
             
            var userdata = data.data;
          
               return   userdata;


         
         
    })
  try {
     if(param === 'name'){
        return result.name;
    }else{
         if(result.profilepicture != ''){
                  return result.profilepicture;

        }
       

}
   
   
    } catch (err) {
   return null;
  }
}
}

const GetProfilePicture =  await GetProfilePicturedata() 

 
const GetSchoolsCommon = async() => {   


  var postdata = {active:1};
            const response = await fetch(Url + "getschools", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       

                        var schooldata= data.data ;

var Schooloptions=[];
  Object.keys(schooldata).forEach(function(key) {



var label =  (schooldata[key].pincode != '')? ' - '+schooldata[key].pincode : '';
   Schooloptions.push({ value: schooldata[key]._id, label:  schooldata[key].name +  label})  ;

});

  return Schooloptions;
                             // setschoolsOptions(Schooloptions);

                     } else {
                        ;
                    }
                })

                return response;

                }





 
const GetSubjectsCommon = async() => {   


  var postdata = {active:1};
            const response = await fetch(Url + "getsubjects", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                       

                        var subjectdata= data.data ;

var Subjectoptions=[];
  Object.keys(subjectdata).forEach(function(key) {



    Subjectoptions.push({ value: subjectdata[key]._id, label:  subjectdata[key].name  })  ;

});

  return Subjectoptions;
 
                     } else {
                        ;
                    }
                })

                return response;

                }



//const ProfilePictureOutput=await GetProfilePicture('profilepicture')

const showLoader=async(status)=>{

if(status == true){
  //    document.getElementsByClassName('page-loader-dynamic')[0].classList.remove('display_none');

}
 
setTimeout(function(){

   //    document.getElementsByClassName('page-loader-dynamic')[0].classList.add('display_none');

},1000)

 



}



const GetThumbnailImage=async(videourl)=>{

 
 
  var youtubeviid ='';

if(videourl.includes('watch')){
  //  var youtubeviid = videourl.replace('https://www.youtube.com/watch?v=','')
}else{
     var youtubeviid = videourl.replace('https://youtu.be/','')

}



if( videourl.includes('https://www.youtube.com/shorts/') === true ) {
     var youtubeviid = videourl.replace('https://www.youtube.com/shorts/','')

     return 'https://img.youtube.com/vi/'+youtubeviid+'/0.jpg';

}

// var youtubeviid = videourl.replace('https://www.youtube.com/watch?v=','')
youtubeviid = youtubeviid.split('&');


 return 'https://img.youtube.com/vi/'+youtubeviid[0]+'/0.jpg';



}


const GetEmbedVideoUrl=async(videourl)=>{

if(videourl.includes('watch')){
 return videourl.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/')


}else{
      return videourl.replace('https://youtu.be/', 'https://www.youtube.com/embed/')

}

 

}

const GetWatchVideoId=async(videourl)=>{
if(videourl.includes('watch')){

  return videourl.replace('https://www.youtube.com/watch?v=', '')

}else{
       return videourl.replace('https://youtu.be/', '')

}

 

}


const ReplaceRender=async()=>{

 
  return (
    <div id="alert" className="alert alert-danger">test </div>
    )


}





export {Vision,Banner,GetWatchVideoId,GetEmbedVideoUrl,GetThumbnailImage,votePosturl, ReplaceRender, Akusername,showLoader,GetSubjectsCommon,GetSchoolsCommon,AKcountsupdated,userProfileurl,postDetailurl,GetProfilePicture,Getstandardvalues,RedirectDashboard_Login,RedirectLogin_Terms,Redirectdashboard_Terms,checkRedirectAfterLogin,RemoveNotificationAfterName,SetNotificationAfterName,TriggerLogout,UrlSplitter,Notifyalert,AkuserId,Akusertype,validEmailRegex,validPhoneRegex,validPasswordRegex,CommonNotify,checkEmptyUndefined,frontUrl,Url,Socketiourl,containerStart,containerEnd, Redirectdashboard,Redirectlogin,redirectfrontPage,SiteLogoDark,SiteLogo,SiteFavicon,editPosturl};