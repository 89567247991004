import React, { useEffect, useState } from "react";
 
import {baseUrl,Url,Redirectlogin,CommonNotify} from '../Api.js';
import { Link } from "react-router-dom";
  
   
 
const Pagetitle = ({activePage}) => {
    

 
  return (


 <div className="page-title">
   <div className="row">
      <div className="col-12 col-md-6 order-md-1 order-last">
         <h3>{activePage}</h3>
      </div>
      <div className="col-12 col-md-6 order-md-2 order-first">
         <nav aria-label="breadcrumb" className='breadcrumb-header'>
            <ol className="breadcrumb">
               <li className="breadcrumb-item"><a href={baseUrl+'dashboard'}>Dashboard</a></li>
               <li className="breadcrumb-item active" aria-current="page">{activePage}</li>
            </ol>
         </nav>
      </div>
   </div>
</div>

 
 

  
  );
};
export default Pagetitle;