import React, { useEffect, useState } from "react";
 

import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";


import {showLoader, SiteLogo,SiteLogoDark,AkuserId,Url  , frontUrl} from '../Frontapi.js';

  import Suggestion from "../sidebarsection/Suggestions"



const Invitefriendscomponent = () => {
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

 const [Referralcode,setReferralcode]=useState()

 const [shareurl,setshareurl] = useState() ;
  const [glink,setglink] = useState() ;

 


  const Getprofile = async (e) => {
  var postdata = {
        userid: AkuserId
    };

     const response = await fetch(Url + "get-user-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userinfo = data.data;

                    
                

                   setshareurl(frontUrl+'signup?referral='+userinfo.referralcode) 
                        
                   setglink('https://mail.google.com/mail/u/0/?ui=2&fs=1&tf=cm&su=Refer+your+friend&body='+shareurl)
                }  
            })



  }


    useEffect(() => {
      Getprofile();
   

  
    }, [shareurl ]);

  return (

    <div>
    
                  <h1 className="page-container-title text-center cur-poi"   >
                   
                     Invite Friends
                  </h1>
               

               <div className="invite-friends-section"> 

 <div className="social-share-single">
       <a href={glink} target="_blank"><button>   <span className="social-share-btn btn-gm" >  <i className="fa fa-google"> </i>   </span>  </button></a>
            </div>


                <div className="social-share-single">
            <EmailShareButton url={shareurl} ><span className="social-share-btn btn-mail" >  <i className="fa fa-envelope"> </i>   </span> </EmailShareButton >
            </div>
              <div className="social-share-single">
            <WhatsappShareButton url={shareurl}  >  <span className="social-share-btn btn-wt" >  <i className="fa fa-whatsapp"> </i>    </span> </WhatsappShareButton >
            </div>
            <div className="social-share-single">
          <FacebookShareButton url={shareurl} > <span className="social-share-btn btn-fb" >  <i className="fa fa-facebook"> </i>   </span> </FacebookShareButton >
            </div>
            <div className="social-share-single">
            <LinkedinShareButton url={shareurl} >  <span className="social-share-btn btn-li" >  <i className="fa fa-linkedin"> </i>   </span> </LinkedinShareButton >
            </div>
        



               </div>


               <Suggestion nobg="1" />

</div>

               
         
 
  
  );
}

export default Invitefriendscomponent;