import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";

 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";

  import {angleLeft  } from '../ImagesIcons.js';


import {frontUrl,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId,Akusername} from '../Frontapi.js';

 




 
const Search = () => {

  const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
   const [searchList,setsearchList] = useState([])

 const [searchHistoryList,setsearchHistoryList] = useState([])

 const [ShowSearchHistory,setShowSearchHistory] = useState(1)

 const [ShowSearchLoader,setShowSearchLoader] = useState(0)

 
 const [noSearchresultfound,setnoSearchresultfound] = useState(0)


const [focuselement,setfocuselement]=useState(0)


const focusNext= async (e) => {

 
if(e.keyCode == 40){


         setfocuselement(parseInt(1)+parseInt(focuselement))

   if(document.getElementsByClassName('search-single-link')[parseInt(1)+parseInt(focuselement)]){
  document.getElementsByClassName('search-single-link')[parseInt(1)+parseInt(focuselement)].focus();

 
             } 

             return false
 }

 if(e.keyCode == 38){

 
               setfocuselement(parseInt(focuselement)-parseInt(1))
 
     if(document.getElementsByClassName('search-single-link')[parseInt(focuselement)-parseInt(1)]){
  document.getElementsByClassName('search-single-link')[parseInt(focuselement)-parseInt(1)].focus();

             } 
             return false
}


}


const updateSearchresult = async (username,postid) => {
   var postdata={username:username , postid : postid  , userid : AkuserId };

        const response = await fetch(Url+"searchhistory", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
        

           
       })

}

const getSearchhistory = async ( ) => {
   var postdata={ userid : AkuserId , username: Akusername };

        const response = await fetch(Url+"getsearchhistory", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
        
        var searchhistoryresultdata = data.data

                Object.keys(searchhistoryresultdata).forEach(function(key) {

                  if(searchhistoryresultdata[key].type == 1 ){

                    if(searchhistoryresultdata[key].result == Akusername){

                      searchhistoryresultdata[key].url = frontUrl + 'my-profile'

                    }else{
                        searchhistoryresultdata[key].url =         userProfileurl+searchhistoryresultdata[key].result
                    }


            


                  }else{
                        searchhistoryresultdata[key].url =         postDetailurl+searchhistoryresultdata[key].result

                  }

 
 });


                          setsearchHistoryList(searchhistoryresultdata);

           
       })
 
}
 

const GotoSearch= async (e) => {

 setfocuselement(0)
if(e.keyCode == 40){


  
   if(document.getElementsByClassName('search-single-link')[focuselement]){
  document.getElementsByClassName('search-single-link')[focuselement].focus();
             } 

              
             return false

}

setfocuselement(0)
setsearchList([])
setShowSearchHistory(1)

    var value = e.target.value;

if(value != ''){
      setShowSearchLoader(1)

}

 setnoSearchresultfound(0)
 if(value != ''){
    var postdata={searchterm:value , userid : AkuserId, includePost:1 };

        const response = await fetch(Url+"search", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              var searchresultdata=data.data;
setShowSearchHistory(0)
setShowSearchLoader(0)


           if(searchresultdata.length == 0){
              setnoSearchresultfound(1)
           }
           

 
 


              setsearchList(searchresultdata);

              } else{
                 
          }

           
       })

}

};
 

 
useEffect(() => {

    getSearchhistory()
    

    }, [setfocuselement,focuselement]);


  return (

      <div>
           <Header pageInfo="Search" />


     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />
           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 col-12 full-section"> 


              <div className="page-content">

                <div className="profile-container"> 

                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   >  <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span>  Search</h1>
                </div>
                    <div className="search-box-sec">
                     <i className="fa  fa-search search-input-icon"> </i> 
                    <input placeholder="Search by post, username" type="text" onClik={(e)=>setfocuselement(0)} className="search-input"  onKeyUp={(e)=>GotoSearch(e)} />
                      {  (ShowSearchLoader == 1) ? <i className="fa fa-spinner search-spinner fa-spin"> </i> : '' }
                       </div>



                  {  (noSearchresultfound == 1   ) ?  <p className="no-records" > No Results Found </p> : '' }


                       


                    {  (ShowSearchHistory == 1  && searchHistoryList.length > 0) ? 
                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row search-history-wrapper  "> 
                    <h2>Search History</h2>

                          {searchHistoryList.map((searchHistorySingle) => (
                            <Link   to={  searchHistorySingle.url } className="search-single-link"   >
                               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12   padding-0 row   "> 


                                

                                <div className="col-md-8 col-lg-8 col-sm-8 col-xs-8 col-8" >
                                <div className="search-single-section-title">
                                  <p> {searchHistorySingle.result} </p>
                                  </div>
                                </div>


                               </div>

                        </Link>

                                ))}


                    </div> : ''}


                               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row search-wrapper  "> 

                               {/*single search**/}


                 {searchList.map((post) => (
                               <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 search-single search-single-link padding-0 row   "> 


                                <div className="col-md-3 col-lg-2 col-sm-4 col-xs-4 col-4" >
                                    <div className="search-single-section-img">
                                    <Link onClick={(username,postid)=>updateSearchresult(post.result,post._id)} to={ (post.type==1) ?   userProfileurl+post.result : postDetailurl+post._id  }   onKeyUp={(e)=>focusNext(e)} >
  
                                    <img src={post.image} />
                                     </Link>
                                    </div>

                                </div>

                                <div className="col-md-9 col-lg-10 col-sm-8 col-xs-8 col-8" >
                                <div className="search-single-section-title">
                                     <p>
                                   <Link onClick={(username,postid)=>updateSearchresult(post.result,post._id)} to={ (post.type==1) ?   userProfileurl+post.result : postDetailurl+post._id  }   onKeyUp={(e)=>focusNext(e)} >
  
                                  {post.result} 
                                   </Link>
                                   </p>
                                  </div>
                                </div>


                               </div>

                       

                                ))}



                                                          {/*single search** 

                                <div className="col-md-12 col-lg-4 col-sm-4 col-xs-4 col-4 search-single padding-0    "> 

                                  <div className="search-single-info">

                                    <div className="search-single-info-img">
                                    <img src={SiteLogo} />
                                    </div>

                                    <div className="search-single-info-title">
                                      <p> Title</p>
                                    </div>
                                  </div>

                                </div>
                                <div className="col-md-12 col-lg-4 col-sm-4 col-xs-4 col-4 search-single padding-0    "> 

                                  <div className="search-single-info">

                                    <div className="search-single-info-img">
                                    <img src={SiteLogo} />
                                    </div>

                                    <div className="search-single-info-title">
                                      <p> Title</p>
                                    </div>
                                  </div>

                                </div>
                                <div className="col-md-12 col-lg-4 col-sm-4 col-xs-4 col-4 search-single padding-0    "> 

                                  <div className="search-single-info">

                                    <div className="search-single-info-img">
                                    <img src={SiteLogo} />
                                    </div>

                                    <div className="search-single-info-title">
                                      <p> Title</p>
                                    </div>
                                  </div>

                                </div>

                                {/*single search**/}
                                
                                 
                                
                               </div>


               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Search;