import React, { useState ,useEffect} from "react";
   import {
    Link 
} from "react-router-dom";
import reactStringReplace from 'react-string-replace';
 
 import Header from "../includes/Header";

 import Sidebar from "../includes/Sidebar";
 import Footer from "../includes/Footer";



import {UrlSplitter,ReplaceRender,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId,frontUrl,SiteLogoDark,Socketiourl,Akusername} from '../Frontapi.js';



 


 
const Notifications = () => {
    const[updateSidecount,setupdateSidecount]=useState(0)
   const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)
 
const [title,setTitle]= useState('')
const [content,setContent]= useState('')


   const cmsurl = UrlSplitter(1);


 const Getcms =async() =>{
 

 var postdata={  cmsurl:cmsurl}

         const response = await fetch(Url+"get-cms-data", {
        method: "POST",
         headers: {
          "Content-Type": "application/json" 
        } ,
        body:JSON.stringify({postdata}) 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            setTitle(data.data.name)
            setContent(data.data.content)
    } else{
               
          }

           
       })
 
  

}

 
useEffect(() => {
    
    Getcms();
 
    }, [title,content]);


  return (

     <div>
                    <Header pageInfo="Privacy Policy" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} updateSidecount={updateSidecount} noauthneeded="1" />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section">
         <div className="page-content">
            <div className="cms-container">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > {title}</h1>
               </div>

  <div className="page-cms-content" dangerouslySetInnerHTML={{__html:  content}} >
                </div>

             
              
            </div>
         </div>

         <Footer />


      </div>
   </div>
</div>
 
  );
};
export default Notifications;