import React, { useState , useEffect} from "react";
 import {
    Link 
} from "react-router-dom";
import reactStringReplace from 'react-string-replace';

import {ReplaceRender,SiteLogo ,Url,userProfileurl ,postDetailurl,AkuserId,frontUrl,SiteLogoDark,Socketiourl,Akusername} from '../Frontapi.js';
 
 

 const Mininotification = ({videopostid}) => {

 const [notificationslist,setnotificationslist]=useState([])
 const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')



const  StringReplaceForFollowerNotify= (notifytext)=>{

notifytext = notifytext.replace("[[", "")
notifytext = notifytext.replace("]]", "")
return notifytext
}

 const Getnotifications =  async () => {
  var postdata={ 'userid':AkuserId , limit : 3};


        const response = await fetch(Url+"get-notifications", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

if(data.data.length == 0){
   setcheckrecordsLength('No notifications found')



}

 var notificationlist=data.data;

 

            setnotificationslist(notificationlist)

                } else{
                 
          }


           
       })


}
  


  useEffect(() => {
 
  Getnotifications();

    },[] );

 
return (

  <div>
  { (notificationslist.length==0) ? ''
                  
                  :
             <div className="mini-notification-section mb-25 bg-card-sidebar"> 
                { (notificationslist.length>0) ? 
                    <h3 className="mb-25">Notifications</h3> : ''}

                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 row notification-wrapper  ">
                 
                  <div>
                     {notificationslist.map((notificationsingle) => (
                     <div className="notification-list-box" data-attr-name={notificationsingle.username} >
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 notification-single padding-0 row   ">
                           <div className="col-md-3 col-lg-2 col-sm-2 col-xs-2 col-2">
                              <div className="notification-single-section-img">

                              { (notificationsingle.link != '') ?  <Link to={userProfileurl+notificationsingle.username}><img src={notificationsingle.userprofilepicture} /> </Link>    :   <img src={notificationsingle.userprofilepicture} /> }
                              

                              </div>
                           </div>
                           <div className="col-md-6 col-lg-8 col-sm-7 col-xs-7 col-7">
                              <div className="notification-single-section-title">
                                 <p className="notify-message">


                                   <span>
                                   {notificationsingle.notifymessage.split(' ').map((notifytext) => (
                                    <span>
                                   <span>

                                    { ( notifytext.includes('[[') && notifytext.includes(']]') )    ?  <Link to={userProfileurl+ StringReplaceForFollowerNotify(notifytext)  }>{StringReplaceForFollowerNotify(notifytext)}</Link>      :     notifytext   } 

                 </span><span>  </span> </span>
                                       ))}

                                       </span>

                                       {/* 
                                    {reactStringReplace(notificationsingle.notifymessage, /[[[a-z]*]]/i , (match, i) => (
                                    <span>
                                       <Link   to={userProfileurl+ notificationsingle.username } >
                                       {notificationsingle.username} </Link> {match} 
                                    </span>
                                    ))} */}
                                    <br/><span className="notify-timestamp">{notificationsingle.timediff}</span>
                                 </p>
                              </div>
                           </div>
                           {(notificationsingle.postthumbnail != '') ?
                           <div className="col-md-3 col-lg-2 col-sm-3 col-xs-3 col-3">
                              <div className="notification-single-section-img ">
                                 <Link   to={postDetailurl+ notificationsingle.postlink } >
                                 <img src={notificationsingle.postthumbnail} className="no-border-radius" />
                                 </Link> 
                              </div>
                           </div>
                           :''
                           } 
                            
                        </div>
                     </div>
                     ))}
                  </div>
                 
               </div>

             

               <p><Link to={frontUrl+'notifications'} >Show more</Link> </p>  


          

                </div>
                 }

    </div>
	)













 }
export default Mininotification;