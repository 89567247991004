import React, { useState } from "react";
 
import {userId,Url,CommonNotify,ProfilePictureOutput} from './../Api.js';

import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";

import Footer from "../includes/Footer";

 
 

const Profile = () => {

 
 
  const [Profilepicture, setProfilepicture] = useState({ preview: ProfilePictureOutput, data: '' });

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");



  const Updateprofile = async (e) => {
    e.preventDefault();
     setnotification("");

    try {

       let formData = new FormData()
      formData.append('profilepicture', Profilepicture.data)
      formData.append('id', userId)
       

      const response = await fetch(Url+"update-profile", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
             
         setnotificationtype('success')
                    setnotification(data.message)
          } else{
              //   
          }

           
       })
 
    } catch (error) {
   //   
    }
  };

 const handlePictureChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setProfilepicture(img)
  }


 
  return (
      
      <div id="app">
         <Header  title="My Profile"    />
        <Sidebar />
        <div id="main">
        <Navbar />


            <div className="main-content container-fluid">
                
              <div className="page-title">
              <div className="row">
              <div className="col-12 col-md-6 order-md-1 order-last">
              <h3>My Profile</h3>
               </div>
              <div className="col-12 col-md-6 order-md-2 order-first">
              <nav aria-label="breadcrumb" className='breadcrumb-header'>
              <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Settings</a></li>
              <li className="breadcrumb-item active" aria-current="page">My Profile</li>
              </ol>
              </nav>
              </div>
              </div>
              </div>


                <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">My Profile</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">

                       


                         <div className="col-md-4">
                            <label>Profile Picture</label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control" onChange={handlePictureChange}  type="file" name="profilepicture" placeholder="Email"      
              
                required/>
                <br/>
                                    <img alt="Profile" className="img-responsive w-100px" src={Profilepicture.preview} />
                        </div> 



                         
                        
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateprofile}  >Submit</button>
                         </div>

                     
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


         <Footer />
            </div>


         </div>

      </div>
   
 
  );
};
export default Profile;