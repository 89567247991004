import React, {useEffect, useState } from "react";
  import {convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,Socketiourl,checkpermissionredirect} from './../../Api.js';

 import { Link } from 'react-router-dom';
 import io from 'socket.io-client';

 import {Socketcall} from '../../../socketcall/Socketcall.js';



  import {    
   Allowposts_view ,
   Allowposts_edit
 } from './../../Permissions.js';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

 
 
 

 const [postsList, setpostsList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

const [rejectpostid,setRejectpostid]= useState();
const [rejectpostusername,setRejectpostusername]= useState();
const [comment,setComment]= useState();

 //const nPages = Math.ceil(postsList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetPosts()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetPosts()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetPosts(value)  
          
               
         
            
           

        }  




  const GetPosts = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={'currentpage':value,'perpage':recordsPerPage,admin:1};

        const response = await fetch(Url+"getposts", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setpostsList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };


  const setCommentText= async (e) => {


      var value = e.target.value;

      setComment(value)
   }


const ShowRejectConfirmPopup =async(id,username)=>{

setRejectpostid(id)
setRejectpostusername(username)

 setTimeout(function(){
        if(document.getElementById('commonmodal')){
            document.getElementById('commonmodal').style.display='none'
        }


        document.getElementById('commonmodal').style.display='block'

        },200)

}



const closeLogoutModal=async() =>{
document.getElementById('commonmodal').style.display='none'

}



  const RejectPost = async () => {
 //if(window.confirm("Are you sure?")){


    Socketcall('on', 'joinuser',{token:rejectpostusername})
        Socketcall('emit', 'newrejection',{token:rejectpostusername, from:''})

 

        var postdata={'id' : rejectpostid , comment:comment,  status : 2 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              UpdateNotify(data.message, 'success')
            GetPosts(1)
            setComment('');
            closeLogoutModal();
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
            
             
          }

           
        })

    //}
}

  const ApprovePost = async (id,username) => {
     
    if(window.confirm("Are you sure?")){

       
    Socketcall('on', 'joinuser',{token:username})
        Socketcall('emit', 'newapproval',{token:username, from:''})


        var postdata={'id' : id , status : 1 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           UpdateNotify(data.message)
            GetPosts(1)
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
             
             
          }

           
        })

    }

     


  };


  const convertTimeStampval =   (timestampVal) => {

 
var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
var date = new Date(timestampVal*1000);
var year = date.getFullYear();
var month = months_arr[date.getMonth()];
var day = date.getDate();
var hours = date.getHours();
var minutes = "0" + date.getMinutes();
var seconds = "0" + date.getSeconds();
var fulldate = month+' '+day+', '+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
var convdataTime = month+' '+day;
 return fulldate;

  
}


  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = postsList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(postsList.length / recordsPerPage)



//pagination part
  
const updateEntriescount = async() => {
  var postdata={ type:'posts'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}


          useEffect(() => {
            GetPosts();


            checkpermissionredirect(Allowposts_view)


  updateEntriescount()


  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Posts List" />

         <Sidebar activePage="Posts" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Posts"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Posts</span>
             </div>
            <div className="card-body">

                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>
                            <th>Topic</th>
                            <th width="20%">Caption</th>
                            <th>URL</th>
                             <th>Comments</th>

                            <th>Date</th>
                            
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                       {(postsList.length > 0) ? 

                    <tbody>

                       {postsList.map((post) => (
                        <tr>
                            <td>{post.topic}</td>

                             <td width="20%" style={{"word-break":"break-all"}}>{post.caption}</td>

                            <td>{post.youtubeurllink}</td>


                            <td>{ (post.commentscount > 0)  ?  <Link className="cur-poi" to={baseUrl+'comments/'+post._id} > <i className="fa fa-eye"> </i></Link>  : '-' }</td>

                            <td>  {  post.createddatetime   }   </td>
                          
                            <td>
                                {(post.status == 1) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">InActive</span>}
                            </td>
                            <td className="table-action-sections table-action-sections-post ">
                              {(Allowposts_edit == 1) ? 
                            <div>

                            {(post.status != 1) ? 
                             <a     onClick={() => ApprovePost(post._id,post.username)}  className="fa-edit-btn"    ><i className="fa fa-check"></i> </a>
                              : ''}
                              
                            <a     onClick={() =>ShowRejectConfirmPopup(post._id,post.username) }  className="fa-del-btn"    ><i className="fa fa-close"></i> </a>

                            </div>
                            : ''}

                             </td>
                        </tr>
                        
                           ))}




                        
                    </tbody> :<tbody><tr> <td colspan="8" align="center">No Posts Found</td></tr></tbody>}



                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>



<div   className="commonmodal display_none" id="commonmodal">
   <div className="newmodal-dialog">
      {/*<span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>*/ }
      <div className="newmodal-content"  >
         <h3 >Post Rejection </h3>
        
            <p className="text-dark"  >Are you sure you want to reject this post? </p>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">  
            <textarea rows="5"     onChange={(e) => setCommentText(e)}
 className="width-100-percent amigo-text-popup" value={comment}>{comment}</textarea>
            </div>

            <div className="proceed-confirm-box">  


          
   <button className="confirm-btn proceed-green" onClick={RejectPost}    >Yes </button>


              <button  className="close   confirm-btn cancel-red" onClick={(e)=>
         closeLogoutModal(e)} >No </button>
            

          

           
         </div>
      </div>
   </div>
</div>



                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;