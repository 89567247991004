import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";
 import Sidebar from "../includes/Sidebar";

import {
    angleLeft
} from '../ImagesIcons.js';

import {TriggerLogout,frontUrl,SiteLogo,AkuserId,Url  , SiteLogoDark,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';



 


 
const Deleteprofile = () => {
  const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

    
 


  

  const navigateToHome = () => {
    window.location.href=frontUrl;
    //navigate(baseUrl); // Use the navigate function to go to the /register route when "Register Now" is clicked
  };

  
 
  const stoppropagation = async (e) => {
            e.stopPropagation();
        }

         const DeleteAccount = async (e) => {
            e.preventDefault();





            var postdata = {
                 
                userid: AkuserId
            }
            const response = await fetch(Url + "delete-user-profile", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        postdata
                    })
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {


                      TriggerLogout();
   navigateToHome();
                        
                    } else {
                        //  ;
                    }
                })



          }



          const closeDeleteAccountModal=async() =>{
document.getElementById('deleteaccountmodal').style.display='none'

}

const showDeleteAccountModal=async() =>{


 setTimeout(function(){
        if(document.getElementById('deleteaccountmodal')){
            document.getElementById('deleteaccountmodal').style.display='none'
        }


        document.getElementById('deleteaccountmodal').style.display='block'

        },200)

}



  useEffect(() => {
  
 
   

    }, [ ]);


 
  return (

      <div>
       

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 full-section"> 


              <div className="page-content">

                <div className="container-mb"> 
               
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section" > <Link to={-1}> <img src={angleLeft} /></Link> </span> Account Deletion</h1>
                </div>


              
              <ul className="delete-account-content"> 

<li className="text-left text-white">If you delete your account, all your data on Alfakidz will also be permanently removed. </li>

<li className="text-left text-white">For questions about your active subscriptions, please reach out to our support team.</li>


<li className="text-left text-white">We're sorry to hear you'd like to delete your Alfakidz account. If you'd like to share any feedback before you go, please feel free to email us at contactus@alfakidz.com. We're always looking for ways to improve. </li>


</ul>

                  

  <button type="submit" className=" me-1 mb-1 edit-profile-main-btn"  onClick={showDeleteAccountModal}  >Delete Account</button>
 

  <div   className="deleteaccountmodal display_none" id="deleteaccountmodal">
   <div className="newmodal-dialog">
      {/*<span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>*/ }
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Account Deletion </h3>
         <div className="update-profile-pic-section following-list-section"  >

           
            <div className="modal-follow-wrapper">
                  
            <p className="logout-confirm-msg">Are you sure you want to delete? </p>

            <div className="logout-confirm-box">  

            <a className="confirm-btn proceed-green"   onClick={DeleteAccount}  >Yes </a>
             <button  className="close   confirm-btn cancel-red" onClick={(e)=>
         closeDeleteAccountModal(e)} >No </button>

            </div>

            </div>
         </div>
      </div>
   </div>
</div>



               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Deleteprofile;