import React, { useEffect,useState } from "react";
  import {SiteLogo,SiteFavicon,Url,CommonNotify} from './../Api.js';

 

 import {    
    Allowsettings_edit
} from './../Permissions.js';

const General = () => {

  
  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [companyname, setCompanyname] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
    const [mobile, setMobile] = useState("");

  const [website, setWebsite] = useState("");
  const [logo, setLogo] = useState({ preview: SiteLogo, data: '' })
  const [favicon, setFavicon] = useState({ preview: SiteFavicon, data: '' })


 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setCompanyname(settingsdata.companyname );
             setAddress(settingsdata.address );
             setCity(settingsdata.city );
             setCountry(settingsdata.country );
             setState(settingsdata.state );
             setEmail(settingsdata.email );
             setPhone(settingsdata.phone );
             setMobile(settingsdata.mobile );
             setWebsite(settingsdata.website );

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 




  const Updategeneralsettings = async (e) => {
    e.preventDefault();
     setnotification("");

    try {

     // var postdata={companyname:companyname,address:address,city:city,country:country,state:state,email:email,phone:phone,website:website};
      let formData = new FormData()
      formData.append('logo', logo.data)
      formData.append('favicon', favicon.data)
      formData.append('companyname', companyname)

      formData.append('address', address)
      formData.append('city', city)
      formData.append('country', country)
      formData.append('state', state)
      formData.append('email', email)
      formData.append('phone', phone)
        formData.append('mobile', mobile)
      formData.append('website', website)

      const response = await fetch(Url+"update-settings", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           
       })
 
    } catch (error) {
      
    }
  };



 const handleLogoChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setLogo(img)
  }
 const handleFaviconChange = (e) => {
    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setFavicon(img)
  }

  



 useEffect(() => {
 Getsettings();
  }, []); 

   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                <div className="col-md-4">
                                    <label htmlFor="companyname">Company Name</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="companyname" placeholder="Company Name" value={companyname} onChange={(e) => setCompanyname(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Address</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">City</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="City" value={city} onChange={(e) => setCity(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">State</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="State" value={state} onChange={(e) => setState(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Country</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Email</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Phone</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} required/>
                                </div>


                                 <div className="col-md-4">
                                    <label htmlFor="city">Mobile</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Mobile" value={mobile} onChange={(e) => setMobile(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="city">Website</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input className="form-control" type="text" name="target" placeholder="Website" value={website} onChange={(e) => setWebsite(e.target.value)} required/>
                                </div>

                                <div className="col-md-4">
                                    <label htmlFor="logo">Logo</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input type="file"  id="logo" name="logo"   onChange={handleLogoChange}/>
                                    <br/>
                                    <br/>
                                    <img alt="logo" className="img-responsive w-100px"  src={logo.preview} />
                                </div>


                                <div className="col-md-4">
                                    <label htmlFor="favicon">Favicon</label>
                                </div>
                                <div className="col-md-8 form-group">
                                    <input type="file" id="favicon" name="favicon" onChange={handleFaviconChange} />
                                    <br/>
                                    <br/>
                                    <img alt="favicon" className="img-responsive w-100px" src={favicon.preview} />
                                </div>

                                {(Allowsettings_edit == 1) ? 

                                 <div className="col-sm-12 d-flex justify-content-end">
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updategeneralsettings}>Submit</button>
                               </div> : '' }

                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default General;