import React, { useState } from "react";
  import {userId,Url,CommonNotify,checkEmptyUndefined} from './../Api.js';


import Header from "../includes/Header";
import Sidebar from "../includes/Sidebar";
import Navbar from "../includes/Navbar";
import Pagetitle from "../includes/Pagetitle";

import Footer from "../includes/Footer";
 
const Changepassword = () => {

 
  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

  const [currentpassword, setCurrentpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
    
         const [submitLoading, setsubmitLoading] = useState(false);

 

  const Updatepassword = async (e) => {
    e.preventDefault();
       setsubmitLoading(true)
    if(checkEmptyUndefined(currentpassword) === false || checkEmptyUndefined(newpassword) === false ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false
    }

    try {
      var postdata={userid:userId, currentpassword:currentpassword,newpassword:newpassword};
      const response = await fetch(Url+"change-password", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ postdata}),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                    setnotificationtype('success')
                    setnotification(data.message)
          } else{
                setnotificationtype('error')
                    setnotification(data.message)
          }

           
       })
       setsubmitLoading(false)
    } catch (error) {
      
    }
  };


   
 
  return (

    
      <div id="app">
            <Header title="Change Password" />

         <Sidebar />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
              <Pagetitle  activePage="Change Password"  />


                <div className="row match-height">
            <div className="col-md-7 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">Change Password</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Current Password <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  type="password" name="currentpassword" placeholder="Current Password" value={currentpassword}
                onChange={(e) => setCurrentpassword(e.target.value)}
                required/>
                        </div>
                        <div className="col-md-4">
                            <label>New Password <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                             <input className="form-control"   type="password" name="newpassword" placeholder="New Password" value={newpassword}
                onChange={(e) => setNewpassword(e.target.value)}
                required/>
                        </div>
                         
                        {( submitLoading === false) ?  (
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatepassword}>Update</button>
                         </div>
                         ) : '' }

                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>

                <Footer />
            </div>


         </div>

      </div>
  
  );
};
export default Changepassword;