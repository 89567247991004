import React, {useEffect, useState } from "react";
  import {convertTimeStamp,baseUrl,Url,ModalPopupDelete,UpdateNotify,checkpermissionredirect} from './../../Api.js';

  import {    
   Alloworders_view ,
   Alloworders_edit
 } from './../../Permissions.js';

import Select from 'react-select';


import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";

//import Pagination from "../../includes/Pagination";

 
const Postlist = () => {

 
 
  const [posttype, setPosttype] = useState();


 const [ordersList, setordersList] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(10);

//const [notification, setnotification] = useState("");
//const [notificationtype, setnotificationtype] = useState("");
const [nPages, setnPages] = useState(1);
    const [pageNumbers,setpageNumbers] = useState([...Array(nPages + 1).keys()].slice(1));

 //const nPages = Math.ceil(ordersList.length / recordsPerPage)

 const goToNextPage = () => {
             if(currentPage !== nPages) {
                setCurrentPage(currentPage + 1) 
                GetOrders()
             }
    }
    const goToPrevPage = () => {
        if(currentPage !== nPages){
            setCurrentPage(currentPage - 1)
             GetOrders()

        }  
   }


      const goToPage = (value) => {
           setCurrentPage(value)
          
             GetOrders(value)  
          
               
         
            
           

        }  

//Filter Select Dropdown
const [Postoptions, setpostsOptions] = useState([{ value: 'challenge' , label: 'Challenge'  } , { value: 'advertisement' , label: 'Advertisement'  }]);
const [selectedPostOption, setselectedPostOption] = useState({ value: '', label: 'Select Type' } )

const handleChange = (value) => {
        
        setPosttype(value.value);
        setselectedPostOption(value)
        }

//Filter Select Dropdown


const Searchstart = async () => {
     GetOrders(1);
 

  }


  const GetOrders = async (value ) => {

    // let formData = new FormData()
    //  formData.append('currentpage', currentPage)
   //   formData.append('perpage', recordsPerPage)

    value = (typeof value !== 'undefined') ? value : currentPage;

      var postdata={  posttype :posttype,  'currentpage':value,'perpage':recordsPerPage,admin:1};

        const response = await fetch(Url+"get-orders", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
        setordersList(data.data );
        if(data.count > 1){
            //setnPages(data.count / recordsPerPage);
 
            setpageNumbers(  [...Array( Math.ceil(data.count / recordsPerPage) + 1).keys()].slice(1) )

        }
         
          } else{
                 
          }

           
       })


 
  };



/****POPUP REJECTION****/
  const [rejectpostid,setRejectpostid]= useState();
const [rejectpostusername,setRejectpostusername]= useState();
const [comment,setComment]= useState();

  const setCommentText= async (e) => {


      var value = e.target.value;

      setComment(value)
   }


const ShowRejectConfirmPopup =async(id,username)=>{

setRejectpostid(id)
setRejectpostusername(username)

 setTimeout(function(){
        if(document.getElementById('commonmodal')){
            document.getElementById('commonmodal').style.display='none'
        }


        document.getElementById('commonmodal').style.display='block'

        },200)

}



const closeLogoutModal=async() =>{
document.getElementById('commonmodal').style.display='none'

}

/****POPUP REJECTION****/


  const RejectPost = async () => {
  
        var postdata={'id' : rejectpostid , status : 2 , comment : comment };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              UpdateNotify(data.message, 'success')
            GetOrders(1)  
            setComment('');
            closeLogoutModal();
        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
            
             
          }

           
        })

    
}

  const ApprovePost = async (id) => {
     
    if(window.confirm("Are you sure?")){
        var postdata={'id' : id , status : 1 };
        const response = await fetch(Url+"update-post-status", {
        method: "POST",
           headers: {
          "Content-Type": "application/json",
        } ,
           body: JSON.stringify({postdata }),
         
        }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
           UpdateNotify(data.message)
            GetOrders(1)

        // setnotificationtype('success')
            //        setnotification(data.message)
          } else{
             
             
          }

           
        })

    }

     


  };


  const convertTimeStampval =   (timestampVal) => {

 
var months_arr = ['January','February','March','April','May','June','July','August','September','October','November','December'];
var date = new Date(timestampVal*1000);
var year = date.getFullYear();
var month = months_arr[date.getMonth()];
var day = date.getDate();
var hours = date.getHours();
var minutes = "0" + date.getMinutes();
var seconds = "0" + date.getSeconds();
var fulldate = month+' '+day+', '+year+' '+hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
var convdataTime = month+' '+day;
 return fulldate;

  
}


  // const indexOfLastRecord = currentPage * recordsPerPage;
  //  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  //  const currentRecords = ordersList.slice(indexOfFirstRecord, indexOfLastRecord);
  //  const nPages = Math.ceil(ordersList.length / recordsPerPage)



//pagination part
  
const updateEntriescount = async() => {
  var postdata={ type:'orders'};
     const response = await fetch(Url+"update-entries-count", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => {           
       })


}


          useEffect(() => {
   GetOrders();

               checkpermissionredirect(Alloworders_view)
    updateEntriescount()



  }, []);
   
     
  //pagination part

  return (
      <div id="app">
            <Header title="Orders List" />

         <Sidebar activePage="Orders" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage="Orders"/>

{ModalPopupDelete()}
        
              <section className="section">
        <div className="card">
            <div className="card-header">
               <span>Orders</span>
             </div>
            <div className="card-body">

                <div className="col-md-12 col-lg-12 row">

                <div className="col-md-3">
                        <div className="col-md-12">
                           <label>Post Type  </label>
                        </div>
                        <div className="col-md-12 form-group">
                           <Select className="form-control no-border-radius   MustEnterField select-school-manage-input" 
                              name={"posttype"}
                              value={selectedPostOption}
                              onChange={handleChange}
                              options={Postoptions}
                              />
                        </div>
                     </div>

                     <div className="col-md-1">
                        <br/>
                        <a  onClick={Searchstart}  className="btn btn-success me-1 mb-1">Search</a>
                     </div>


                    </div>




                <table className='table table-striped' id="table1">
                    <thead>
                        <tr>    
                        <th>Order No</th>
                             <th>Username / Email / Phone</th>

                              <th>Type</th>
                            <th>Transaction Id</th>
                             <th>Price</th>
                            <th>Date</th>
                            
                            <th>Payment Status</th>

                            <th>Ad Status </th>
                            <th>Refund Status </th>
                            <th>Action</th>  
                        </tr>
                    </thead>
                     {(ordersList.length > 0) ? 
                    <tbody>
                       {ordersList.map((post) => (
                        <tr>
                         <td>{post.ordernumber}</td>

                            <td>{post.user}

                            {(post.useremail != '')?  ' / '+post.useremail : ''    }

                                                        {(post.userphone != '')?  ' / '+post.userphone : ''    }


                            </td>


                            <td>{post.posttype}</td>
                            <td>{post.transactionid}</td>
                            <td>{post.price}</td>

                            <td>  {  post.createddatetime   }   </td>


                            <td>  {  post.paymentstatus   }   </td>


                          
                            <td>
                                {(post.adstatus == 1 || post.adstatus == 'Active' ) ?  <span className="badge bg-success">Active</span>: <span className="badge bg-danger">{post.adstatus}</span>}
                            </td>


                             <td>  {  post.refundstatus   }   </td>

                           
                            <td className="table-action-sections table-action-sections-post ">

                            {(Alloworders_edit == 1 && post.adstatus != 'Rejected' && post.adstatus != 2 ) ? 
                            <div>
                            {(post.adstatus == 1   || post.adstatus == 'Active' ) ? '' :
                             <a     onClick={() => ApprovePost(post.postid)}  className="fa-edit-btn"    ><i className="fa fa-check"></i> </a>
                             }
                             
                            <a     onClick={() => ShowRejectConfirmPopup(post.postid)}  className="fa-del-btn"    ><i className="fa fa-close"></i> </a></div>
                            : '-'}
                             </td>  
                        </tr>
                        
                           ))}
                        
                    </tbody>:<tbody><tr> <td colspan="10" align="center">No Orders Found</td></tr></tbody>}
                </table>
            </div>
        </div>
{  pageNumbers.length > 1 ? 

       <nav>
   <ul className='pagination pagination-primary justify-content-center'>
      <li className="page-item prev-paging-single">
         <a className="page-link" 
            onClick={goToPrevPage} 
            href='#'>
         Previous
         </a>
      </li>
       {pageNumbers.map(pgNumber => (
      <li key={pgNumber} 
      className= {`page-item ${currentPage == pgNumber ? 'active' : ''} `} >
      <a onClick={(e) => goToPage(pgNumber)  }  
      className='page-link' 
      href='#'>
      {pgNumber}
      </a>
      </li>
      ))}
      <li className="page-item paging-next-single">
         <a className="page-link" 
            onClick={goToNextPage}
            href='#'>
         Next
         </a>
      </li>
   </ul>
</nav>
: ''

}

    </section>

    

<div   className="commonmodal display_none" id="commonmodal">
   <div className="newmodal-dialog">
      {/*<span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>*/ }
      <div className="newmodal-content"  >
         <h3 >Post Rejection </h3>
        
            <p className="text-dark"  >Are you sure you want to reject this post? </p>

              <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">  
            <textarea rows="5"     onChange={(e) => setCommentText(e)}
 className="width-100-percent amigo-text-popup" value={comment}>{comment}</textarea>
            </div>

            <div className="proceed-confirm-box">  


          
   <button className="confirm-btn proceed-green" onClick={RejectPost}    >Yes </button>


              <button  className="close   confirm-btn cancel-red" onClick={(e)=>
         closeLogoutModal(e)} >No </button>
            

          

           
         </div>
      </div>
   </div>
</div>



                <Footer />
            </div>


         </div>

      </div>
 
 
  );
};
export default Postlist;