import React, { useState ,useEffect} from "react";
   import {
    Link , useNavigate
} from "react-router-dom";

  import ReactPlayer from 'react-player'
 

 import Sidebar from "../includes/Sidebar";

  import CommentsPopup from "../commentspopup/Commentspopup"
  import Share from "../share/Share"
  import Video from "../video/Video"

   import Singlepost from "../pages/Singlepost"

import {Socketiourl, SiteLogo,SiteLogoDark,AkuserId,Akusername,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter,postDetailurl} from '../Frontapi.js';


 
 import {Socketcall} from '../../socketcall/Socketcall.js';



 
const Postdetail = () => {

  const navigate = useNavigate();


  const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)


const[Image,setImage]=useState();
  const [userimage, setUserimage]=useState();

const [username, setUsername]=useState();
const [topic, setTopic]=useState();
const [caption, setCaption]=useState();
const postid = UrlSplitter(2);


const [postLiked, setpostLiked ]=useState();
const [postSaved, setpostSaved ]=useState();
const [postLikescount, setpostLikescount ]=useState();
const [postTimediff, setppostTimediff ]=useState();

const [comment, setcomment]=useState();
const [comments, setcomments]=useState([]);

  const [viewpostid, setviewpostid] = useState('');
const [Showcommentspopup, setShowcommentspopup] = useState(0);



const [shareurl, setShareurl]= useState('')
const [Showsharepopup, setShowsharepopup] = useState(0);


const [Showvideopopup, setShowvideopopup] = useState(0);
  const [videopostid, setvideopostid] = useState('');
  const [youtubeurllink, setYoutubeurllink] = useState('');
 


const AddComment =async(e) =>{



  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
             /***Check Required***/

            if(error == 1){
              return false
            }

  var postdata={postid:postid,'userid':AkuserId, comment:comment};


 

Socketcall('on', 'joinuser',{token:username})
Socketcall('emit', 'newcomment',{token:username,  from:Akusername})



        const response = await fetch(Url+"add-comments", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



                Notifyalert('success', data.message)

                document.getElementById("Addcommentform").reset();



              } else{
                 
          }

           
       })

}




const UpdateLikes= async (e,value ) => {


  var liked = e.target.attributes.getNamedItem('data-update-like').value;

  if(parseInt(liked)  ===  parseInt(0) ){


   e.target.setAttribute('data-update-like',1);
      e.target.setAttribute('class','fa fa-heart');



 

Socketcall('on', 'joinuser',{token:username})
Socketcall('emit', 'newlike',{token:username,  from:Akusername})



}else{
    e.target.setAttribute('data-update-like',0);
      e.target.setAttribute('class','fa fa-heart-o');

}


    var postdata={postid:postid,'userid':AkuserId};

        const response = await fetch(Url+"update-likes", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              } else{
                 
          }

           
       })



};
 



 
 

const UpdateSaved= async (e, value ) => {


  var liked = e.target.attributes.getNamedItem('data-update-save').value;
if(parseInt(liked)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-save',1);
      e.target.setAttribute('class','fa fa-bookmark');


}else{
    e.target.setAttribute('data-update-save',0);
      e.target.setAttribute('class','fa fa-bookmark-o');

}


    var postdata={postid:postid,userid:AkuserId};

        const response = await fetch(Url+"update-saves", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

              } else{
                 
          }

           
       })



};

 



 


const openCommentpopup= async(postid)=>{

setviewpostid(postid)
 setShowcommentspopup(1)



}

const closeCommentpopup= async()=>{

setShowcommentspopup(0)



}



const showShareProfile= async()=>{

setShareurl(postDetailurl + postid);
setShowsharepopup(1)



}

const closeShareProfile= async()=>{

setShowsharepopup(0)



}




const goToPost = (id) => {

 //    window.location.href= frontUrl+'p/'+id;
        document.getElementsByClassName('post-mob-img-'+id)[0].style.display = "none";
     document.getElementsByClassName('post-video-'+id)[0].style.display = "block";

}
const openVideopopup= async(postid)=>{
   setvideopostid(postid)
 setShowvideopopup(1)



}

const closeVideopopup= async()=>{

setShowvideopopup(0)



}


   useEffect(() => {
    //Getpostdetails();

  
    },[] );

 
  return (

     <div>
   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="profile-container">
               <Singlepost postid={postid} />
               
                
            </div>
         </div>
      </div>
   </div>
 
</div>
 
  );
};
export default Postdetail;