import React, {useEffect, useState } from "react";
 
 import {Url, CommonNotify,UrlSplitter,checkEmptyUndefined,redirectPage,checkpermissionredirect} from './../../Api.js';
import {    
   Allowchallenges_view ,
   Allowchallenges_add ,
   Allowchallenges_edit ,
   Allowchallenges_delete
} from './../../Permissions.js';

import Header from "../../includes/Header";
import Sidebar from "../../includes/Sidebar";
import Navbar from "../../includes/Navbar";
import Pagetitle from "../../includes/Pagetitle";

import Footer from "../../includes/Footer";
 

 

const Add = () => {

 
 
 



const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");

const[id] = useState(UrlSplitter(4));

const[title, setTitle] = useState(""); 
 const[status, setStatus] = useState(1); 

         const [submitLoading, setsubmitLoading] = useState(false);
const[startdatetime, setStartdatetime] = useState(""); 

 const[enddatetime, setEnddatetime] = useState(""); 


  const[prize, setPrize] = useState(""); 



 const Getchallenge = async (e) => {
  
  try{
     var postdata={_id:id};

     const response = await fetch(Url+"getchallenge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
              
              var challengedata=data.data;
               setTitle(challengedata.title);
               setStatus(challengedata.status);
             
             setStartdatetime(challengedata.startdatetime);
               setEnddatetime(challengedata.enddatetime);
                
                setPrize(challengedata.prize);

            

          } else{
              //   
          }

           
       })



  } catch (error) {
      //
  }

 } 


 
  const Updatechallenge = async (e) => {
    e.preventDefault();
     setsubmitLoading(true)
    if(checkEmptyUndefined(title)=== false  || checkEmptyUndefined(prize) === false  ){
       setnotificationtype('error')
                    setnotification('* Fields Required')
                    setsubmitLoading(false)
                    return false

    }

    
     try {
    var  statusupdate = (typeof status == 'undefined'  || status == ''|| status == '1')?'1':0;
       var postdata={_id:id ,title :title,startdatetime :startdatetime,enddatetime :enddatetime,prize :prize  ,status:statusupdate};
 
      const response = await fetch(Url+"updatechallenge", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
         setnotificationtype('success')
                    setnotification(data.message)
                      redirectPage('challenges')
          } else{
              setnotificationtype('error')
                    setnotification(data.message)
          }

           
       })
   setsubmitLoading(false)
    } catch (error) {
      
    }
  };


const handleChallengeInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;
    console.log(value)
    switch (name) {
        case 'title':
            setTitle(value);
             
             
            break;
                case 'status':
            setStatus(value);
             break;

        case 'prize':
            setPrize(value);
 break;

             case 'startdatetime':
            setStartdatetime(value);
 break;
             case 'enddatetime':
            setEnddatetime(value);
              break;
             
           
        
        default:
            break;      
    }
 }

 
    
useEffect(() => {
    Getchallenge(id);


       if(typeof id != 'undefined' && id !=''){
    checkpermissionredirect(Allowchallenges_edit)
}else{
    checkpermissionredirect(Allowchallenges_add)

}


  
  },[] ); 

   return (

       <div id="app">
            <Header title={typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"} />

         <Sidebar activePage="Challenges" activeChildPage="Add" />
         <div id="main">
            <Navbar />


            <div className="main-content container-fluid">
                
                              <Pagetitle activePage={typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"}/>
                              <div className="row match-height">
            <div className="col-md-6 col-12 mx-auto">
            <div className="card">
                <div className="card-header">
                <h4 className="card-title">{typeof id !== 'undefined'?"Edit Challenge":"Add Challenge"}</h4>
                </div>
                <div className="card-content">
                <div className="card-body">
                    <form className="form form-horizontal">
                    <div className="form-body">
                        <div className="row">
                        <div className="col-md-4">
                            <label>Title <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <input className="form-control"  value={title}  type="text" name="title" placeholder="Title"  
                onChange={(e) => handleChallengeInput(e)}
                required/>
                        </div>

<div className="col-md-4">
                            <label>Start Date and Time <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="datetime-local"
   name="startdatetime"
   value={startdatetime}
   onChange={(e) => handleChallengeInput(e)}

    />
 </div>


 <div className="col-md-4">
                            <label>End Date and Time <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="datetime-local"
   name="enddatetime"
      onChange={(e) => handleChallengeInput(e)}

   value={enddatetime}
    />
 </div>


                        <div className="col-md-4">
                            <label>Status <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
                            <select className="form-control"  name="status"  onChange={(e) => handleChallengeInput(e)}> 
                             <option value='1'  selected={1 == status ? 'selected' :''}  >Active</option>
                             <option value='0'  selected={0 == status ? 'selected' :''}  >InActive</option>
                            
                            </select>
                           
                        </div>



 <div className="col-md-4">
                            <label>Prize <span className="field-required">*</span></label>
                        </div>
                        <div className="col-md-8 form-group">
<input className="form-control" 
  type="number"
   name="prize"

   onChange={(e) => handleChallengeInput(e)}

   value={prize}
    />
 </div>


                     
                         {( submitLoading === false) ?  (
                        <div className="col-sm-12 d-flex justify-content-end">
                            <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updatechallenge}>Submit</button>
                         </div>
                          ) : '' }
                        {
                             (notification!=='') ?  (
                              CommonNotify(notification,notificationtype)
                            ) :'' 
                           }


                        </div>
                    </div>
                    </form>
                </div>
                </div>
            </div>
            </div>
            </div>


                                <Footer />
            </div>


         </div>

      </div>
                   
   
 
  );
};
export default Add;