import React, { useState } from "react";
 import { Link, useNavigate } from "react-router-dom";


 import {SiteLogo,AkuserId,Akusertype, frontUrl,Url,Redirectdashboard,validEmailRegex,CommonNotify,checkEmptyUndefined,Redirectdashboard_Terms} from '../Frontapi.js';
 import Header from '../includes/Header';
 import Footer from '../includes/Footer';

 
const Register = () => {

  Redirectdashboard_Terms(AkuserId,Akusertype);
 

//  const urlParams = new URLSearchParams(window.location.search);

// const myParam = urlParams.get('referral');

//    localStorage.setItem('AKreferralcode', myParam)


 
  return (


    <div>


  <Header pageInfo="Register" />

    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
    
    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 
        {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 


    <div className="page-content">

    <div className="login-container"> 
        
        <div className="login-section"> 

        <h1 className="register-login-title  ">Alfa Kid's</h1>

        <p  className="register-login-inst">In this platform we encourage our
kids to bring up their talent in front of 
World.</p>

<p className="register-login-inst mb-25">
It can be Art, Dance, Skill, Mobile Game,
Group Project, Motivational speech etc. </p>
<p className="register-login-inst mb-50">

Come Learn, Compete with your Friends</p>

               <Link className="signup-button"  to={frontUrl+'login'} >Login</Link>              

                <p  className="register-login-inst-option"> OR</p>

              <Link className="signup-button signup-optional"  to={frontUrl+'teacher-register'} >Register as Teacher / Counselor

</Link>              
            
              <Link className="signup-button"  to={frontUrl+'student-register'} >Register as Student</Link>              
                                           
        </div>

    </div>
    </div>

 : ''}


<Footer />
    </div>


    </div>



    </div>


 
 
  );
};
export default Register;