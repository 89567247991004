import React, {useEffect, useState } from "react";
  import {Url,CommonNotify,checkEmptyUndefined,DeleteNotify} from './../Api.js';
 
  import {    
    Allowsettings_edit
} from './../Permissions.js';


const Seo = () => {
 

  const [notification, setnotification] = useState("");
const [notificationtype, setnotificationtype] = useState("");


  const [meta_title, setMetatitle] = useState("");
  const [meta_keywords, setMetakeywords] = useState("");
  const [meta_description, setMetadescription] = useState("");
            const [submitLoading, setsubmitLoading] = useState(false);


 const Getsettings = async (e) => {
  
  try{
     var postdata={getdata:1};

     const response = await fetch(Url+"get-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        } ,
          body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
            var settingsdata=data.data;
             setMetatitle(settingsdata.meta_title );
             setMetakeywords(settingsdata.meta_keywords );
             setMetadescription(settingsdata.meta_description );
              
           

            
         
          } else{
              //   
          }

           
       })



  } catch (error) {
      
  }

 } 

 useEffect(() => {
 Getsettings();
  }, []); 
  
  const Updateseosettings = async (e) => {
    e.preventDefault();
    setnotification("");
     setsubmitLoading(true)
     if(checkEmptyUndefined(meta_title) === false || checkEmptyUndefined(meta_keywords) === false || checkEmptyUndefined(meta_description) === false   ){
    
      setnotificationtype('error')
                   setnotification('* Fields Required')

                    setsubmitLoading(false)
                    return false
    }
    try {

      var postdata={meta_title:meta_title,meta_keywords:meta_keywords,meta_description:meta_description  };
 
       const response = await fetch(Url+"update-seo-settings", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({   postdata  }),
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 
                     setnotificationtype('success')
                    setnotification(data.message)
          } else{
                 
          }

           
       })
    setsubmitLoading(false)
    } catch (error) {
     }
  };


const handleUserInput = (e) => {
    setnotification("");
    var name = e.target.name;
    var value = e.target.value;

    switch (name) {
        case 'meta_title':

              setMetatitle(value);
             break;
        case 'meta_keywords':
            setMetakeywords(value);
            break;
       case 'meta_description':
           setMetadescription(value );
            break;
          
             default :
              break;
    }


 }
   

 
  return (

     <div  >
    
                    <form className="form form-horizontal">
                        <div className="form-body">
                            <div className="row">
                                 

                                <div className="col-md-4">
                                    <label htmlFor="address">Meta Title<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_title" placeholder="Meta Title" value={meta_title} onChange={(e) => handleUserInput(e)} required></textarea>
                                 </div>


                                 <div className="col-md-4">
                                    <label htmlFor="address">Meta Keywords<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_keywords" placeholder="Meta Keywords" value={meta_keywords} onChange={(e) => handleUserInput(e)} required></textarea>
                                 </div>

                                <div className="col-md-4">
                                    <label htmlFor="address">Meta Description<span className="field-required">*</span></label>
                                </div>
                                <div className="col-md-8 form-group">
                                   <textarea className="form-control" type="text" name="meta_description" placeholder="Meta Description" value={meta_description} onChange={(e) => handleUserInput(e)} required></textarea>
                                 </div>
                              
                               
                                   {
                               (submitLoading===false) ?  (
                                 <div className="col-sm-12 d-flex justify-content-end">
                                   {(Allowsettings_edit == 1) ? 
                                  <button type="submit" className="btn btn-primary me-1 mb-1" onClick={Updateseosettings}>Submit</button> : ''}
                               </div>
                                ) :'' 
                                }
                                 {
                               (notification!=='') ?  (
                                CommonNotify(notification,notificationtype)
                              ) :'' 
                             }


                            </div>
                        </div>
                    </form>
                    </div>
                   
   
 
  );
};
export default Seo;