import React, { useEffect, useState } from "react";
   import {
    Link 
} from "react-router-dom";

 import Header from '../includes/Header';

  import Sidebar from "../includes/Sidebar";


import {
  EmailShareButton,
  FacebookShareButton,
  GabShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
    angleLeft
} from '../ImagesIcons.js';
import {showLoader, SiteLogo,SiteLogoDark,AkuserId,Url  , frontUrl} from '../Frontapi.js';

  import Suggestion from "../sidebarsection/Suggestions"



const Invitefriends = () => {
const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

 const [Referralcode,setReferralcode]=useState()

 const [shareurl,setshareurl] = useState() ;
  const [glink,setglink] = useState() ;

 


  const Getprofile = async (e) => {
  var postdata = {
        userid: AkuserId
    };

     const response = await fetch(Url + "get-user-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userinfo = data.data;

                    
                

                   setshareurl( encodeURI(frontUrl+'?referral='+userinfo.referralcode) ) 
                        
                   setglink('https://mail.google.com/mail/u/0/?ui=2&fs=1&tf=cm&su=Refer+your+friend&body='+shareurl)
                }  
            })



  }


    useEffect(() => {
      Getprofile();
   

  
    }, [shareurl ]);

  return (

    <div>
       <Header pageInfo="Invite Friends" />


   <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section">
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus}  />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 col-12 full-section">
         <div className="page-content">
            <div className="container-mobile">
               <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   >
                    <span className="goto-section mob-view-back display_none" ><Link  to={-1}>  <img src={angleLeft} /> </Link>  </span>
                     Invite Friends
                  </h1>
               </div>

               <div className="invite-friends-section"> 

 <div className="social-share-single">
       <a href={glink} target="_blank"><button>   <span className="social-share-btn btn-gm" >  <i className="fa fa-google"> </i>   </span>  </button></a>
            </div>


                <div className="social-share-single">
            <EmailShareButton url={shareurl} ><span className="social-share-btn btn-mail" >  <i className="fa fa-envelope"> </i>   </span> </EmailShareButton >
            </div>

{/* 
 <div className="social-share-single">
       <a href={'https://wa.me/send?text='+shareurl} target="_blank"><button>   <span className="social-share-btn btn-wt" >  <i className="fa fa-whatsapp"> </i>   </span>  </button></a>
            </div>
            
*/ }

              <div className="social-share-single">
            <WhatsappShareButton url={shareurl}  >  <span className="social-share-btn btn-wt" >  <i className="fa fa-whatsapp"> </i>    </span> </WhatsappShareButton >
            </div>
            <div className="social-share-single">
          <FacebookShareButton url={shareurl} > <span className="social-share-btn btn-fb" >  <i className="fa fa-facebook"> </i>   </span> </FacebookShareButton >
            </div>
            <div className="social-share-single">
            <LinkedinShareButton url={shareurl} >  <span className="social-share-btn btn-li" >  <i className="fa fa-linkedin"> </i>   </span> </LinkedinShareButton >
            </div>
        



               </div>


               <Suggestion nobg="1" />


               
            </div>
         </div>
      </div>
   </div>
</div>
  
  );
}

export default Invitefriends;