import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";


 import Header from '../includes/Header';

 
 import Sidebar from "../includes/Sidebar";



import {GetThumbnailImage,frontUrl,SiteLogo,AkuserId,Url  ,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert} from '../Frontapi.js';


  import {angleLeft  } from '../ImagesIcons.js';




 
const Newpost = () => {
  const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const [topic, setTopic] = useState("");
const [caption, setCaption] = useState("");
const [youtubeurllink, setYoutubeurllink] = useState("");
 const [thumbnail, setThumbnail] = useState({ preview: '', data: '' })

const [submitButton, showSubmitbutton] = useState(1);


const Createnewpost =async(e) =>{

  e.preventDefault();

       

  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove();
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         el.classList.add('mb-10');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         el.classList.remove('mb-10');
                        error=1;

                       

                        //SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
              if(error === 1){
                    return false
            }
             /***Check Required***/

                 

          if(thumbnail.data == ''){

                 // document.getElementsByClassName('button-upload')[0].classList.add('notify-input-missing');

               // return false
             
          }


        
if(checkvalidYoutubeUrl()  == false){
      return false
}

showSubmitbutton(0)
    let formData = new FormData()
      formData.append('thumbnail', thumbnail.data)
      formData.append('userid',  AkuserId)
      formData.append('topic',  topic)
      formData.append('caption',  caption)
      formData.append('youtubeurllink',  youtubeurllink)


         const response = await fetch(Url+"create-new-post", {
        method: "POST",
        body:formData 
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

             Notifyalert('success', data.message)

             
             document.getElementById("NewPostForm").reset()
              setThumbnail({preview:''})  

              navigate("/my-profile")

           //   const get= document.getElementById('my-profile');  
            //  get.click();  

            //  window.location.href=frontUrl+'my-profile'
              

          } else{
            showSubmitbutton(1)

             Notifyalert('error', data.message)  
          }

           
       })
 
  

}


 const checkvalidYoutubeUrl=(e)=>{

   RemoveNotificationAfterName();

   if( youtubeurllink != '' &&  (youtubeurllink.includes('https://youtu.be') === false &&  youtubeurllink.includes('https://www.youtube.com/shorts/') === false )  ){
    showSubmitbutton(0)
    SetNotificationAfterName('youtubeurllink',   'Invalid Url' )
    return false
     } 

 }

   const handleUserInput = (e) => {
     showSubmitbutton(1)
     // checkvalidYoutubeUrl()

     var name = e.target.name;
    var value = e.target.value;
    switch (name) {
        case 'topic':
            setTopic(value);
             break;
        case 'youtubeurllink':
            setYoutubeurllink(value);
            setAutothumbnail(value)

          
            checkvalidYoutubeUrl()

             break;
        case 'caption':
            setCaption(value);
             break;      

           
         
      }
    }



 const handleThumbnailChange = (e) => {
      RemoveNotificationAfterName();

     const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    }
     setThumbnail(img)
  }
  
  const setAutothumbnail = (value) => {


  (async function() { 

   var imgresult= await GetThumbnailImage(value)
 
 const img = {
      preview:imgresult,
      data: '',
    }
     setThumbnail(img)


})()
 

 

  }

  return (

      <div>
       
         <Header pageInfo="Create New Post" />

     
          <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
          <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 "> 

               
<Sidebar showBottomNavbar={BottomNavbarstatus} />

           </div>
            <div className="col-md-9 col-lg-9 col-sm-12 col-xs-9 col-12 full-section"> 


              <div className="page-content">

                <div className="container-mb"> 
                <form id="NewPostForm" >
                 <div className="page-container-section"  >
                  <h1 className="page-container-title cur-poi"   > <span className="goto-section mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link> </span> New Post</h1>
                </div>


                  <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 

                    <input onChange={(e) => handleUserInput(e)} type="text" name="youtubeurllink" className="new-post-input MustEnterField mb-10" placeholder="Paste YouTube URL link of your content" />
                     </div>



 <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec post-input-file-sec"> 

                     <div className="button-wrap">
        <label className="button-upload" for="upload">Attach Thumbnail</label>
        <input id="upload" type="file"  accept="image/png, image/gif, image/jpeg" name="thumbnail"   onChange={handleThumbnailChange} />
         
                     {(thumbnail.preview != '') ? <img alt="thumbnail" className="thumb-img-prev img-responsive w-250px"  src={thumbnail.preview} />:''}

      </div>

 </div>


                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12   post-input-sec"> 
                    <input onChange={(e) => handleUserInput(e)} type="text" name="topic" className="new-post-input MustEnterField mb-10" placeholder="Topic" />
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12     post-input-sec"> 
                    <textarea onChange={(e) => handleUserInput(e)} name="caption" placeholder="Write Caption" rows="10" className="new-post-textarea MustEnterField mb-10"></textarea>
                    </div>

                   
                    
                   

                      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12  post-input-sec"> 
 {(submitButton == 1) ? 
                      <button type="submit" className=" me-1 mb-1 new-post-main-btn  "  onClick={Createnewpost}   >Confirm</button>
                    :   <button type="submit" className=" me-1 mb-1 new-post-main-btn disabled-btn"      >Confirm</button>}
                      
                    </div>

                    
</form>

               </div>
               </div>



           </div>
              

     </div>
    
           

      </div>
 
 
  );
};
export default Newpost;