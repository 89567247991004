import React, { useState ,useEffect} from "react";
     import {
    Link,
    useNavigate
} from "react-router-dom";


 import Header from '../includes/Header';

 import Sidebar from "../includes/Sidebar";
 
 

import { frontUrl,Akusername, editPosturl,SiteLogo,SiteLogoDark,AkuserId,Url , userProfileurl ,Socketiourl,SetNotificationAfterName,RemoveNotificationAfterName ,Notifyalert,UrlSplitter} from '../Frontapi.js';

  import Share from "../share/Share"

   import {angleLeft  } from '../ImagesIcons.js';


 
 import {Socketcall} from '../../socketcall/Socketcall.js';

 
const Userprofile = () => {
      const navigate = useNavigate();

const [BottomNavbarstatus,setBottomNavbarstatus] = useState(1)

const username = UrlSplitter(2);

const [Userprofilepicture,setUserprofilepicture] = useState(SiteLogoDark );

const [followingscount,setfollowingscount] = useState( );

const [followerscount,setfollowerscount] = useState( );

const [postscount,setpostscount] = useState( );

const [currentuserfollowed,setcurrentuserfollowed] = useState( 0);


const [postsList, setpostsList] = useState([]);


const [shareurl, setShareurl]= useState(userProfileurl+username)
const [Showsharepopup, setShowsharepopup] = useState(0);


const [followtext,setfollowtext]=useState('Follow')



const [Showmessagesection, setShowmessagesection] = useState(0);


const [Showprofilesection, setShowprofilesection] = useState(1);


const [message,setmessage]=useState()
const [messagedata,setmessagedata]=useState([])

 const [checkrecordsLength,setcheckrecordsLength] = useState('Loading...')


 


const Openmessagesection =async()=>{

setShowmessagesection(1)
setShowprofilesection(0)
setBottomNavbarstatus(0)
getMessages()

}
const Closemessagesection =async()=>{

setShowmessagesection(0)
setShowprofilesection(1)
setBottomNavbarstatus(1)

}


const Getuserdetails =async(e) =>{

if(username == Akusername){
    navigate("/my-profile")
}

   var postdata = {
        user_name: username , checkuserid:AkuserId
    };

     const response = await fetch(Url + "get-show-profile", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success && data.data !== null) {
                    var userinfo = data.data;
 
 
setUserprofilepicture(userinfo.profilepicture)
 


                setfollowerscount(userinfo.followerscount); 
                setfollowingscount(userinfo.followingscount); 
                setpostscount(userinfo.postscount);

                setcurrentuserfollowed(userinfo.currentuserfollowed);

                if(userinfo.currentuserfollowed == 1){
                     setfollowtext('Following')
                }

                        // setName(userinfo.name)
                        // setLastname(userinfo.lastname)
                        // setMobile(userinfo.mobile)
                        // setSchool(userinfo.school)
                        // setSubject(userinfo.subject)
                        // setStandard(userinfo.standard)
                        // setUniversity(userinfo.university)
                        // setPincode(userinfo.pincode)
                        // setNotifylikes(userinfo.notifylikes)
                        // setNotifycomments(userinfo.notifycomments)

                        

                }  
            })




}



const updateFollower=async(e)=>{





  var followed = e.target.attributes.getNamedItem('data-update-followed').value;
if(parseInt(followed)  ===  parseInt(0) ){
  e.target.setAttribute('data-update-followed',1);
     setfollowtext('Following')

 
        Socketcall('on', 'joinuser',{token:username})
Socketcall('emit', 'newfollow',{token:username,  from:Akusername})



}else{
    e.target.setAttribute('data-update-followed',0);
      setfollowtext('Follow')

}



var postdata={following: username, follower:AkuserId}
        const response = await fetch(Url + "update-follower", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {
                if (data.success  ) {
                
                Getuserdetails()
   //   Notifyalert('success', data.message)

                        

                }  
            })



}


const showShareProfile= async()=>{

setShowsharepopup(1)



}

const closeShareProfile= async()=>{

setShowsharepopup(0)



}



  const Getposts = async (e) => {
  var postdata = {
        username: username,
        status:1
    };

     const response = await fetch(Url + "get-user-posts", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    postdata
                }),
            }).then((response) => response.json())
            .then((data) => {

                if(data.data.length == 0){
                   setcheckrecordsLength('No posts')

                  
                }

                if (data.success && data.data !== null) {
                   // var userinfo = data.data;

                    var postdatavalues = data.data


                   

                    setpostsList(postdatavalues)
                     

                }  
            })



  }



  const getMessages =  async () => {
  var postdata={username:username,'userid':AkuserId };


        const response = await fetch(Url+"get-messages", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 

         setmessagedata(data.data)

 

              } else{
                 
          }

           
       })


}


  const sendMessage =  async (e) => {



  /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                         if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                         error=1;

                        

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
             /***Check Required***/

               if(error == 1){
              return false
            }

  var postdata={username:username,'userid':AkuserId, message:message};

        const response = await fetch(Url+"send-message", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



                Notifyalert('success', data.message)

                document.getElementById("Addmessageform").reset();

 

              } else{
                 
          }

           
       })



  }
 

 const [userOptions, setshowuserOptions] = useState(0);

const showuserOptions =(e) =>{

 
 // var shown = e.target.attributes.getNamedItem('data-show-user-options').value;
if( parseInt(userOptions)  ===  parseInt(0)  ){
  // e.target.setAttribute('data-show-user-options',1);
    
setshowuserOptions(1)

}else{
  //  e.target.setAttribute('data-show-user-options',0);
    setshowuserOptions(0)
}

}



const [blocktext,setblocktext]= useState('Block');

const updateBlock =async() =>{

var postdata={username:username,'userid':AkuserId };

        const response = await fetch(Url+"update-block", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



              checkBlockExists()
               

 

              } else{
                 
          }

           
       })


}


const checkBlockExists =async() =>{
setblocktext('Block')
var postdata={username:username,'userid':AkuserId };

        const response = await fetch(Url+"check-block", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



                setblocktext('Unblock')
               

 

              } else{
                 
          }

           
       })


}


const checkBlockbyExists =async() =>{
 var postdata={username:username,'userid':AkuserId };

        const response = await fetch(Url+"check-blockby", {
        method: "POST",
         headers: {
          "Content-Type": "application/json",
        } ,
        body: JSON.stringify({postdata }),
         
      }).then((response) => response.json())
         .then((data) => { 
          if (data.success) { 



                    setuserblockedcurrentuser(1)

 

              } else{
                 
          }

           
       })


}


const [userblockedcurrentuser,setuserblockedcurrentuser]=useState(0)



const [Followerslistview, setshowfollowers] = useState(0);
const [Followingslistview, setshowfollowings] = useState(0);
const [Followerslist, setfollowerslist] = useState([]);
const [Followingslist, setfollowingslist] = useState([]);

const showFollowers=()=>{
setshowfollowings(0)

        //HideAllSections()
        setshowfollowers(1)
        GetFollowers();

        setTimeout(function(){
        if(document.getElementById('followinglistmodal')){
            document.getElementById('followinglistmodal').style.display='none'
        }


        document.getElementById('followerlistmodal').style.display='block'

        },200)

}
 

   const showFollowings  = (e) => {
setshowfollowers(0)
   // HideAllSections()
    setshowfollowings(1)
     GetFollowings();

    setTimeout(function(){
        if(document.getElementById('followerlistmodal')){
document.getElementById('followerlistmodal').style.display='none'
        }
        

    document.getElementById('followinglistmodal').style.display='block'

    },200)

    }

const stoppropagation=async(e)=>{
        e.stopPropagation();

}



  const GetFollowers = async (value, reset = '') => {
            var postdata = {getfollowers:1, username:username };
            const response = await fetch(Url + "getfollowers", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                     if (data.success && data.data.length > 0) {
                        var followerdatavalues = data.data
             

 
                               

                                 setfollowerslist(followerdatavalues);
 

                         
                    } else {
                        
                    }
                })
        };

        const GetFollowings = async (value, reset = '') => {
            var postdata = {getfollowings:1, username:username};
            const response = await fetch(Url + "getfollowings", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {

                         var followingsdatavalues = data.data
                  

                
                            

                                 setfollowingslist(followingsdatavalues);

 

                          
                    } else {
                        
                    }
                })
        };




   useEffect(() => {

 

    Getuserdetails();
    checkBlockExists();
    checkBlockbyExists();
    Getposts();


        Socketcall('on', 'joinuser',{token:username})

 

  
    },[] );
 
  return (
<div>
   {(AkuserId !=  null) ?  
   <div>
      <Header pageInfo={username} />


      <div id={(Showsharepopup == 1 ) ? 'overlay-hidden':'' } className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 
      <div className="col-md-2 col-lg-2 col-sm-3 col-xs-3 ">
         <Sidebar showBottomNavbar={BottomNavbarstatus} />
      </div>
      <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9  col-12 full-section">
         <div className="page-content">
            <div className="profile-container">



               {/**profilesection**/}

             {(Showprofilesection == 1) ?  

             <div>

 <div className="my-profile-section"  >
   <div className="page-container-section"  >


         <h1 className="page-container-title cur-poi show-nav"  > <span className="goto-section  mob-view-back display_none" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span>  {username} 

 <a className="f-dropdown-link prof-nav-icon"><i className="fa fa-navicon"> </i>

                        <ul className="f-prof-dropdown">

                         <li><a onClick={(e)=>updateBlock(e)} > {blocktext} {username} </a></li>
                        </ul>
                       </a> 
           </h1>

{/*}
      <h1 className="page-container-title cur-poi show-nav"  > <span className="goto-section  mob-view-back" > <Link  to={-1}>  <img src={angleLeft} /> </Link>   </span>  {username} <span className="prof-nav-icon" data-show-user-options="0" onClick={(e) => showuserOptions(e)} ><i className="fa fa-navicon"> </i> </span>  </h1>
            

*/ }



                 {(userOptions == 1) ? 
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row user-options-main-sec  ">
                        <div className="col-md-2 col-lg-5 col-sm-3 col-xs-3 col-9 user-options-prof-sec">
                           <div className="user-options">
                              <ul>
                                 
                                 <li><a onClick={(e)=>updateBlock(e)} > {blocktext} {username} </a></li>
                              </ul>
                           </div>
                        </div>
                     </div>
                     :''}
   </div>
</div>
<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row prof-dat-sec">
   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3"> 
      <img src={Userprofilepicture} className="profile-pic" />
   </div>
   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 ">
      <p className="profile-prop-sec">{postscount}</p>
      <p className="profile-value-sec">Posts</p>
   </div>
   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 c-point" onClick={showFollowers}>
      <p className="profile-prop-sec">{followerscount}</p>
      <p className="profile-value-sec">Followers</p>
   </div>
   <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 col-3 c-point" onClick={showFollowings}>
      <p className="profile-prop-sec">{followingscount}</p>
      <p className="profile-value-sec">Following</p>
   </div>
</div>


{(userblockedcurrentuser == 0) ?  
<div>
<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row prof-dat-sec">
   <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-5  ">
      <button type="submit" className=" me-1 mb-1 profile-main-btn" data-update-followed={currentuserfollowed} onClick={(e)=>updateFollower(e)}  >{followtext}</button>
   </div>
   <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4  c-point"  >
      <button type="submit" className=" me-1 mb-1 profile-main-btn" onClick={showShareProfile}  >Share</button>
   </div>
   <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-3  c-point"  >
      <Link className=" me-1 mb-1 profile-main-btn"    to={frontUrl+'m/'+username}>Message</Link>  
   </div>
</div>


<div>

 {(Followerslistview == 1) ? 
<div   className="newmodal" id="followerlistmodal">
   <div className="newmodal-dialog">
      <span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Followers </h3>
         <div className="update-profile-pic-section follower-list-section"  >

      

         
        <div className="modal-follow-wrapper">
            {Followerslist.map((followersingle) => (
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row follower-single-sec " data-attr-name={followersingle.username}>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 ">
                  <div className="follow-profile-pic-sec"> 
                     <img alt="Profile" className="img-responsive" src={followersingle.profilepicture} />
                  </div>
               </div>
               <div className="col-md-5 col-lg-4 col-sm-4 col-xs-4 col-4    follower-info">
                  {followersingle.username}
               </div>
              
            </div>
            ))}
            </div>
         </div>
      </div>
   </div>
</div>

               
               : ''}


               {(Followingslistview == 1) ? 

                
 <div   className="newmodal" id="followinglistmodal">
   <div className="newmodal-dialog">
      <span id="modalClose" className="close no-select"><i className="fa fa-times" aria-hidden="true"></i></span>
      <div className="newmodal-content" onClick={(e)=>
         stoppropagation(e)}>
         <h3 >Following </h3>
         <div className="update-profile-pic-section following-list-section"  >

           
            <div className="modal-follow-wrapper">
            {Followingslist.map((followingsingle) => (
            <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row following-single-sec "  data-attr-name={followingsingle.username}>
               <div className="col-md-4 col-lg-4 col-sm-4 col-xs-4 col-4 ">
                  <div className="follow-profile-pic-sec"> 
                     <img alt="Profile" className="img-responsive" src={followingsingle.profilepicture} />
                  </div>
               </div>
               <div className="col-md-5 col-lg-4 col-sm-4 col-xs-4 col-4    follower-info">
                  {followingsingle.username}
               </div>
               
            </div>
            ))}
            </div>
         </div>
      </div>
   </div>
</div>


                
               : ''}

</div>


</div>


: ''}


{(userblockedcurrentuser == 0) ?  

<div>
<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">
   <h2 className="page-container-sub-title cur-poi"  > <span className="goto-section" >    </span>Posts</h2>
</div>
<div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row my-uploads-sec">
   <div className="my-uploads-tab">
                        { (postsList.length==0) ? <div className="no-records"> {checkrecordsLength} </div>  :

      <div className="my-post-list-section col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12 row">


          {postsList.map((post) => (
         <div className="col-md-4 col-lg-4 col-sm-4 col-xs-6 col-6  my-post-single">
            <div className=" my-post-single-img-sec">
               <img src=   {post.thumbnail} />  
               <div className=" my-post-single-topic">
                  {post.topic}  
               </div>
            </div>
            <div className=" my-post-single-action">
            </div>
         </div>
         ))}

 


      </div>}
   </div>
</div></div>: ''}

</div>

             : ''

            }

             

               {/**profilesection**/}

               {/**messagesection**/}

               {(Showmessagesection == 1) ? 

               <div>
                <div className="my-profile-section"  >
                  <div className="page-container-section"  >
                     <h1 className="page-container-title cur-poi show-nav" onClick={Closemessagesection}  > <span className="goto-section" >  <img src={angleLeft} />  </span>   <img src={Userprofilepicture} className="message-head-pic" /> {username}  </h1>
                  </div>
               </div>


               <div className="user-prof-messages"> 


                {messagedata.map((messagesingle) => (

                <p className={Akusername == messagesingle.username ? 'message-p-r' : 'message-p'   } >{messagesingle.message} </p> 


                  ))}




               

                              

               </div>



               </div>





               : ''

            }


 {(Showmessagesection == 1) ?
            <div className=" r">

            
       
 
 <form id="Addmessageform">


   <div className="s-message-layout">
                     <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-12     add-message-section" >
                     
                     <input onChange={(e) => setmessage(e.target.value)}   name="message" className="add-message-input MustEnterField" placeholder="Message" />

                     <span className="add-message-submit" onClick={sendMessage}   >Send</span>
                   
                      </div>
                           </div>
                        </form>


          
      </div>: ''

            }

                {/**messagesection**/}



            </div>
         </div>
      </div>
   </div>
   {(Showsharepopup == 1) ?  
   <div className="share-modal-box">
      <div className="share-modal-bg">
         <div className="share-modal-close">
            <button  onClick={closeShareProfile} > X </button>
         </div>
         <Share shareurl={shareurl} />
      </div>
   </div>
   : '' }






</div>
: '' }
</div>
 
  );
};
export default Userprofile;